import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Layout, Row } from 'antd';
import { FC } from 'react';
import { colorPrimary } from '../../constants/InterfaceThemes';
import { useLogoutUserMutation } from '../../redux/api/authApi';
import { logout } from '../../redux/features/authSlice';
import { useAppDispatch } from '../../redux/hooks';

const { Header: AntHeader } = Layout;

interface HeaderProps {
    name?: string;
}
const Header: FC<HeaderProps> = ({ name }) => {
    const [logoutUser] = useLogoutUserMutation();
    const dispatch = useAppDispatch();

    const handleLogout = () => {
        logoutUser().then(() => dispatch(logout()));
    };

    return (
        <AntHeader>
            <Row justify="end" gutter={8}>
                <Col>
                    <Avatar size="large" style={{ backgroundColor: colorPrimary }}>
                        {name?.[0]?.toUpperCase()}
                    </Avatar>
                </Col>
                <Col>Ласкаво просимо, {name}</Col>
                <Col>
                    <Button onClick={handleLogout} icon={<LogoutOutlined />} />
                </Col>
            </Row>
        </AntHeader>
    );
};

export default Header;
