import { FC, useEffect } from 'react';
import { Form, Input, Row, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { DefaultOptionType } from 'antd/es/select';
import { activeStatusSelectOptions } from '../../../constants/User';
import { Roles } from '../../../constants/enums';
import { useGetRolesQuery } from '../../../redux/api/rolesApi';

interface UserDataFieldsProps {
    disableEmail?: boolean;
    disableTempPass?: boolean;
}

const checkDisabledOptions = (rolesOptions: DefaultOptionType[], value?: string[]) => {
    if (value?.length) {
        if (value.includes(Roles.analyst)) {
            rolesOptions.forEach((role) => {
                if (role.value !== Roles.analyst) role.disabled = true;
            });
        } else {
            rolesOptions.forEach((role) => {
                if (role.value === Roles.analyst) role.disabled = true;
            });
        }
        return;
    }
    rolesOptions.forEach((role) => (role.disabled = false));
};

const UserDataFields: FC<UserDataFieldsProps> = ({ disableEmail, disableTempPass }) => {
    const { data: roles = [], isLoading, isFetching } = useGetRolesQuery();
    const form = Form.useFormInstance();

    const rolesOptions: DefaultOptionType[] = roles.map((role) => ({
        value: role.name,
        label: role.description,
        disabled: false,
    }));

    useEffect(() => checkDisabledOptions(rolesOptions, form.getFieldValue('roles')), [form, rolesOptions]);

    return (
        <>
            <Form.Item label="Логін" name="email" rules={[{ required: true }]}>
                <Input placeholder="Логін" disabled={disableEmail} />
            </Form.Item>
            <Form.Item label="Прізвище" name="lastName" rules={[{ required: true }]}>
                <Input placeholder="Прізвище" />
            </Form.Item>
            <Form.Item label="Імʼя" name="firstName" rules={[{ required: true }]}>
                <Input placeholder="Ім’я" />
            </Form.Item>
            <Form.Item label="По батькові" name="middleName">
                <Input placeholder="По батькові" autoComplete="additional-name" />
            </Form.Item>
            <Form.Item label="Номер телефону" name="phone" rules={[{ len: 17 }]}>
                <MaskedInput
                    mask={[{ mask: '+{38}-\\000{-}000{-}00{-}00', lazy: true }]}
                    placeholder="Номер телефону"
                    autoComplete="phone"
                />
            </Form.Item>
            <Form.Item label="Ролі" name="roles" rules={[{ required: true }]}>
                <Select
                    mode="multiple"
                    placeholder="Оберіть ролі"
                    style={{ width: '100%' }}
                    options={rolesOptions}
                    loading={isLoading || isFetching}
                    onChange={(value) => checkDisabledOptions(rolesOptions, value)}
                />
            </Form.Item>
            <Form.Item label="Статус" name="activeStatus" rules={[{ required: true }]} initialValue={!disableEmail}>
                <Select
                    placeholder="Оберіть статус"
                    style={{ width: '100%' }}
                    options={activeStatusSelectOptions}
                    loading={isLoading || isFetching}
                />
            </Form.Item>
            {!disableTempPass && (
                <Form.Item name="password" label="Тимчасовий пароль" rules={[{ required: true }]}>
                    <Input.Password autoComplete="new-password" />
                </Form.Item>
            )}
            <Row style={{ marginBottom: '1.5rem' }} />
        </>
    );
};

export default UserDataFields;
