import { createApi } from '@reduxjs/toolkit/query/react';
import { UserModel } from '../../models/UserModel';
import { setAuthData, setChangePasswordData } from '../features/authSlice';
import customBaseQuery from './common/customBaseQuery';

export interface LoginRequestPayload {
    email: string;
    password: string;
}

interface ChangePasswordRequestPayload {
    email: string;
    oldPassword: string;
    newPassword: string;
}

export interface LoginResponsePayload {
    user: UserModel;
    access_token: string;
    refresh_token: string;
    token_type: string;
    expires_in: number;
    created_date: number;
    apiVersion: string;
}

interface TemporaryPasswordResponsePayload {
    isTemporaryPassword: true;
}

const isTemporaryPassResponse = (
    response: LoginResponsePayload | TemporaryPasswordResponsePayload,
): response is TemporaryPasswordResponsePayload => {
    return 'isTemporaryPassword' in response;
};

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        loginUser: builder.mutation<LoginResponsePayload | TemporaryPasswordResponsePayload, LoginRequestPayload>({
            query(data) {
                return {
                    url: 'user/token',
                    method: 'POST',
                    body: data,
                    credentials: 'include',
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (isTemporaryPassResponse(data)) {
                        dispatch(setChangePasswordData(args.email));
                    } else {
                        await dispatch(
                            setAuthData({
                                user: data.user,
                                accessToken: data.access_token,
                                refreshToken: data.refresh_token,
                                apiVersion: data.apiVersion,
                            }),
                        );
                    }
                } catch (error) {}
            },
        }),
        logoutUser: builder.mutation<void, void>({
            query() {
                return {
                    url: `user/logout`,
                    method: 'PUT',
                    credentials: 'include',
                };
            },
        }),
        changeUserPassword: builder.mutation<void, ChangePasswordRequestPayload>({
            query(data) {
                return {
                    url: 'user/password',
                    method: 'PUT',
                    credentials: 'include',
                    body: data,
                };
            },
        }),
    }),
});

export const { useLoginUserMutation, useLogoutUserMutation, useChangeUserPasswordMutation } = authApi;
