import React, { FC } from 'react';

import { Button, Form, Input, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { ROLE_ANALYST } from '../../../../constants/Role';
import { DISABLED_WHITESPACE } from '../../../../constants/common';
import { getUserName } from '../../../../helpers/getUserName';
import { EmployeeModel } from '../../../../models/UserModel';
import { useGetEmployeesQuery } from '../../../../redux/api/userApi';

const defaultUserQuery = {
    orderBy: 'createdDate desc',
    filter: {
        roles: {
            name: ROLE_ANALYST,
        },
        lockedDate: null,
    },
};

export interface AmendmentData {
    controllerComments: string;
    analyst?: { id: number };
}

interface AmendmentFormProps {
    onSuccess: (data: AmendmentData) => void;
    analyst?: EmployeeModel;
}

const { TextArea } = Input;
const AmendmentForm: FC<AmendmentFormProps> = ({ onSuccess, analyst }) => {
    const [form] = useForm();
    const { data: analysts, isLoading } = useGetEmployeesQuery(defaultUserQuery);

    const handleSubmit = (values: any) => {
        onSuccess({
            controllerComments: values.comment,
            analyst: analyst?.id !== values.userId ? { id: values.userId } : undefined,
        });
    };

    return (
        <Spin spinning={isLoading}>
            <Form
                form={form}
                onFinish={handleSubmit}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}
                layout="vertical">
                <Form.Item name="userId" initialValue={analyst?.id} rules={[{ required: true }]}>
                    <Select
                        placeholder="Оберіть виконавця"
                        style={{ width: '100%' }}
                        options={analysts?.map((item) => ({
                            value: item.id,
                            label: getUserName(item),
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    name="comment"
                    label="Комментар"
                    rules={[{ required: true }, { whitespace: true, message: DISABLED_WHITESPACE }]}>
                    <TextArea placeholder="Причина відхилення" rows={4} />
                </Form.Item>
                <Row justify="end">
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Row>
            </Form>
        </Spin>
    );
};

export default AmendmentForm;
