import React, { FC, useMemo, useRef } from 'react';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import moment from 'moment';
import ResizableColumn from '../../../../../components/common/ResizebleColumn';
import { DATE_FORMAT, OPERATION_PARAMS_TABLE, TIME_FORMAT } from '../../../../../constants/Alert';
import { ReportTypes } from '../../../../../constants/enums';
import { formatNumberToFinance } from '../../../../../helpers/convertCurrency';
import { transactionsDto } from '../../../../../helpers/dto/transactionsDto';
import { getColumnSearchProps } from '../../../../../helpers/getColumnSearchProps';
import getTableSizeFromStore from '../../../../../helpers/getTableSizeFromStore';
import { localeCompareSort } from '../../../../../helpers/sort';
import { useModal } from '../../../../../hooks';
import { Transaction } from '../../../../../models/Transaction';
import TableWrapper from '../TableWrapper';
import AdditionalRequisites from '../components/AdditionalRequisites';

interface OperationParamsProps {
    loading: boolean;
    data?: Transaction[];
    triggerFetch?: () => void;
}
const defaultSorter: SorterResult<Transaction> = {
    columnKey: 'documentDate',
    order: 'descend',
};

const OperationParams: FC<OperationParamsProps> = ({ loading, data, triggerFetch }) => {
    const size = useMemo(() => getTableSizeFromStore(OPERATION_PARAMS_TABLE), []);
    const inputRef = useRef(null);
    const [selectedRow, setSelectedRow] = React.useState<Transaction | null>(null);
    const [isModalOpen, openModal, closeModal] = useModal();
    const sortedData = useMemo(
        () =>
            [...(data || [])]?.sort((a, b) => {
                if (!a.documentDate || !b.documentDate) return 0;
                return new Date(b.documentDate).getTime() - new Date(a.documentDate).getTime();
            }),
        [data],
    );
    const columns: ColumnsType<Transaction> = useMemo(
        () => [
            {
                key: 'documentId',
                dataIndex: 'documentId',
                title: (
                    <ResizableColumn colIndex={0} storageKey={OPERATION_PARAMS_TABLE}>
                        Номер документа
                    </ResizableColumn>
                ),
                sorter: (a, b) => (a.documentId as number) - (b.documentId as number),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'documentId'),
                ellipsis: true,
                width: size?.[0] || 106,
            },
            {
                key: 'documentDate',
                dataIndex: 'documentDate',
                title: (
                    <ResizableColumn colIndex={1} storageKey={OPERATION_PARAMS_TABLE}>
                        Дата документа
                    </ResizableColumn>
                ),
                render: (value) => (value ? moment(value).format(DATE_FORMAT) : ''),
                sortDirections: ['ascend', 'descend'],
                sorter: (a, b) =>
                    new Date(a.documentDate as string).getTime() - new Date(b.documentDate as string).getTime(),
                ...getColumnSearchProps<Transaction>(inputRef, 'date', 'documentDate'),
                ellipsis: true,
                width: size?.[1] || 82,
            },
            {
                key: 'amount',
                dataIndex: 'amount',
                title: (
                    <ResizableColumn colIndex={2} storageKey={OPERATION_PARAMS_TABLE}>
                        Сума операції
                    </ResizableColumn>
                ),
                render: (value) => formatNumberToFinance(value),
                sorter: (a, b) => (a.amount as number) - (b.amount as number),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'amount'),
                ellipsis: true,
                width: size?.[2] || 140,
            },
            {
                key: 'recipientCurrencyCode',
                dataIndex: 'recipientCurrencyCode',
                title: (
                    <ResizableColumn colIndex={3} storageKey={OPERATION_PARAMS_TABLE}>
                        Валюта операції
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'recipientCurrencyCode'),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'recipientCurrencyCode'),
                ellipsis: true,
                width: size?.[3] || 36,
            },
            {
                key: 'amountEquivalent',
                dataIndex: 'amountEquivalent',
                title: (
                    <ResizableColumn colIndex={4} storageKey={OPERATION_PARAMS_TABLE}>
                        Еквівалент в грн
                    </ResizableColumn>
                ),
                render: (value) => formatNumberToFinance(value),
                sorter: (a, b) => (a.amountEquivalent as number) - (b.amountEquivalent as number),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'amountEquivalent'),
                ellipsis: true,
                width: size?.[4] || 140,
            },
            {
                key: 'senderIban',
                dataIndex: 'senderIban',
                title: (
                    <ResizableColumn colIndex={5} storageKey={OPERATION_PARAMS_TABLE}>
                        Номер рахунку платника
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'senderIban'),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'senderIban'),
                ellipsis: true,
                width: size?.[5] || 150,
            },
            {
                key: 'senderBankCode',
                dataIndex: 'senderBankCode',
                title: (
                    <ResizableColumn colIndex={6} storageKey={OPERATION_PARAMS_TABLE}>
                        Код Банку платника
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'senderBankCode'),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'senderBankCode'),
                ellipsis: true,
                width: size?.[6] || 62,
            },
            {
                key: 'senderBankName',
                dataIndex: 'senderBankName',
                title: (
                    <ResizableColumn colIndex={7} storageKey={OPERATION_PARAMS_TABLE}>
                        Банк платника
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'senderBankName'),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'senderBankName'),
                ellipsis: true,
                width: size?.[7] || 150,
            },
            {
                key: 'senderName',
                dataIndex: 'senderName',
                title: (
                    <ResizableColumn colIndex={8} storageKey={OPERATION_PARAMS_TABLE}>
                        Найменування платника
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'senderName'),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'senderName'),
                ellipsis: true,
                width: size?.[8] || 150,
            },
            {
                key: 'senderTaxCode',
                dataIndex: 'senderTaxCode',
                title: (
                    <ResizableColumn colIndex={9} storageKey={OPERATION_PARAMS_TABLE}>
                        Ідент.номер / Код платника
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'senderTaxCode'),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'senderTaxCode'),
                ellipsis: true,
                width: size?.[9] || 106,
            },
            {
                key: 'recipientIban',
                dataIndex: 'recipientIban',
                title: (
                    <ResizableColumn colIndex={10} storageKey={OPERATION_PARAMS_TABLE}>
                        Номер рахунку отримувача
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'recipientIban'),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'recipientIban'),
                ellipsis: true,
                width: size?.[10] || 150,
            },
            {
                key: 'recipientBankCode',
                dataIndex: 'recipientBankCode',
                title: (
                    <ResizableColumn colIndex={11} storageKey={OPERATION_PARAMS_TABLE}>
                        Код Банку отримувача
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'recipientBankCode'),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'recipientBankCode'),
                ellipsis: true,
                width: size?.[11] || 62,
            },
            {
                key: 'recipientBankName',
                dataIndex: 'recipientBankName',
                title: (
                    <ResizableColumn colIndex={12} storageKey={OPERATION_PARAMS_TABLE}>
                        Банк отримувача
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'recipientBankName'),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'recipientBankName'),
                ellipsis: true,
                width: size?.[12] || 150,
            },
            {
                key: 'recipientName',
                dataIndex: 'recipientName',
                title: (
                    <ResizableColumn colIndex={13} storageKey={OPERATION_PARAMS_TABLE}>
                        Найменування отримувача
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'recipientName'),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'recipientName'),
                ellipsis: true,
                width: size?.[13] || 150,
            },
            {
                key: 'recipientTaxCode',
                dataIndex: 'recipientTaxCode',
                title: (
                    <ResizableColumn colIndex={14} storageKey={OPERATION_PARAMS_TABLE}>
                        Ідент.номер / Код отримувача
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'recipientTaxCode'),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'recipientTaxCode'),
                ellipsis: true,
                width: size?.[14] || 106,
            },
            {
                key: 'purpose',
                dataIndex: 'purpose',
                title: (
                    <ResizableColumn colIndex={15} storageKey={OPERATION_PARAMS_TABLE}>
                        Призначення платежу
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'purpose'),
                ...getColumnSearchProps<Transaction>(inputRef, 'string', 'purpose'),
                ellipsis: true,
                width: size?.[15] || 424,
            },
            {
                key: 'paymentDate',
                dataIndex: 'paymentDate',
                title: (
                    <ResizableColumn colIndex={16} storageKey={OPERATION_PARAMS_TABLE}>
                        Дата проведення операції
                    </ResizableColumn>
                ),
                render: (value) => value && moment(value).format(DATE_FORMAT),
                sorter: (a, b) =>
                    new Date(a.paymentDate as string).getTime() - new Date(b.paymentDate as string).getTime(),
                ...getColumnSearchProps<Transaction>(inputRef, 'date', 'paymentDate'),
                ellipsis: true,
                width: size?.[16] || 106,
            },
            {
                key: 'paymentDate',
                dataIndex: 'paymentDate',
                title: (
                    <ResizableColumn colIndex={17} storageKey={OPERATION_PARAMS_TABLE}>
                        Час здійснення операції
                    </ResizableColumn>
                ),
                render: (value) => value && moment(value).format(TIME_FORMAT),
                sorter: (a, b) =>
                    new Date(a.paymentDate as string).getTime() - new Date(b.paymentDate as string).getTime(),
                ...getColumnSearchProps<Transaction>(inputRef, 'date', 'paymentDate'),
                ellipsis: true,
                width: size?.[17] || 56,
            },
            {
                key: 'additionalRequsites',
                dataIndex: 'additionalRequsites',
                title: (
                    <ResizableColumn colIndex={18} storageKey={OPERATION_PARAMS_TABLE}>
                        Додаткові реквізити
                    </ResizableColumn>
                ),
                render: (_, record) => (
                    <Button
                        size="small"
                        style={{ height: 20 }}
                        type={'link'}
                        onClick={() => {
                            setSelectedRow(record);
                            openModal();
                        }}>
                        ...
                    </Button>
                ),
                ellipsis: true,
                width: size?.[18] || 35,
            },
        ],
        [openModal, size],
    );

    return (
        <>
            <AdditionalRequisites closeModal={closeModal} isModalOpen={isModalOpen} selectedRow={selectedRow} />
            <TableWrapper
                rowKey="documentId"
                dataSource={sortedData}
                columns={columns}
                loading={loading}
                triggerFetch={triggerFetch}
                isActionBar={true}
                isClearFilters={true}
                defaultSorter={defaultSorter}
                reportType={ReportTypes.transactions}
                dto={transactionsDto}
            />
        </>
    );
};

export default OperationParams;
