import { RuleModel, RuleParamModel } from '../../models/RulesModel';

export const rulesParamsDto = (values: RuleModel) => {
    (values.params as RuleParamModel[])?.forEach((param) => {
        if (param.type?.code === 'number' && typeof param.value === 'string') {
            // convert string to number for correct store in db
            param.value = +(param.value as string).replace(',', '.');
        }

        // Handle nested objects and arrays
        if (param.type?.code === 'object' && Array.isArray(param.value)) {
            param.value.forEach((nestedParam, nestedIndex, nestedArray) => {
                if (
                    (nestedParam.nbs && nestedParam.nbs === null) ||
                    (nestedParam.account && nestedParam.account === null)
                ) {
                    nestedArray.splice(nestedIndex, 1);
                }
                if (nestedParam.ob22 && Array.isArray(nestedParam.ob22)) {
                    nestedParam.ob22 = nestedParam.ob22.filter(
                        (item: string) => item && item !== null && item !== undefined,
                    );
                    if (nestedParam.ob22.length === 0) {
                        delete nestedParam.ob22;
                    }
                }
            });
        }
    });
    if (values.score) {
        values.score = +values.score;
    }
    if (values.scoreStateEnterprise) {
        values.scoreStateEnterprise = +values.scoreStateEnterprise;
    }

    return values;
};
