import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, notification, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { CalendarOutlined, CloseCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import History from './History';
import Modal from '../../../components/common/Modal';
import DeleteForm from '../../../components/form/calendar-form/DeleteForm';
import UpdateForm from '../../../components/form/calendar-form/UpdateForm';
import { INTERVAL_CODE_DAY } from '../../../constants/Session';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { SESSION_INTERVAL_TRANSLATE } from '../../../constants/enums';
import { SessionCalendarIntervals } from '../../../models/Session';
import {
    ReqChangeIntervalDate,
    useChangeIntervalDateMutation,
    useUpdateCalendarByIdMutation,
} from '../../../redux/api/calendarApi';

interface EditorProps {
    schedule: SessionCalendarIntervals;
    intervalId: number;
    onClose: () => void;
}
const Editor = ({ schedule, intervalId, onClose }: EditorProps) => {
    const [changeModal, setChangeModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [historyModal, setHistoryModal] = useState(false);
    const disabledEdit = dayjs().subtract(1, 'day').isBefore(dayjs(schedule.date), 'day');
    const interval = useMemo(
        () => schedule.intervals.find((model) => model.interval.id === intervalId)?.interval,
        [schedule, intervalId],
    );
    const [updateSchedule, { isLoading: isLoadingUpdate }] = useUpdateCalendarByIdMutation();
    const [changeDate, { isLoading: isLoadingChange }] = useChangeIntervalDateMutation();
    const deleteInterval = useCallback(
        (comment: string) => {
            schedule.intervals = schedule.intervals.filter((model) => model.interval.id !== intervalId);
            updateSchedule({ ...schedule, comment })
                .unwrap()
                .then(() => {
                    notification.success({ ...BASE_NOTIFICATION_CONFIG, message: 'Сессія успішно видалена' });
                    setDeleteModal(false);
                    onClose();
                })
                .catch((error) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message }));
        },
        [schedule, intervalId, updateSchedule, onClose],
    );
    const handleChangeIntervalDate = useCallback(
        (data: ReqChangeIntervalDate) => {
            changeDate(data)
                .unwrap()
                .then(() => {
                    notification.success({ ...BASE_NOTIFICATION_CONFIG, message: 'Сессія успішно перенесена' });
                    setChangeModal(false);
                    onClose();
                })
                .catch((error) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message }));
        },
        [changeDate, onClose],
    );
    return (
        <Col span={24}>
            <Modal
                title={`Історія ${
                    SESSION_INTERVAL_TRANSLATE[interval?.code as keyof typeof SESSION_INTERVAL_TRANSLATE]
                }`}
                open={historyModal}
                onCancel={() => setHistoryModal(false)}
                destroyOnClose
                width={1200}>
                <History intervalCode={interval?.code} />
            </Modal>
            <Modal
                title={'Відміна сессії'}
                open={deleteModal}
                onCancel={() => setDeleteModal(false)}
                destroyOnClose
                width={340}>
                <DeleteForm loading={isLoadingUpdate} date={schedule.date} onSubmit={deleteInterval} />
            </Modal>
            <Modal
                title={'Редагування сессії'}
                open={changeModal}
                onCancel={() => setChangeModal(false)}
                destroyOnClose
                width={340}>
                <UpdateForm
                    loading={isLoadingChange}
                    intervalId={intervalId}
                    date={schedule.date}
                    intervalCode={interval?.code as string}
                    onSubmit={handleChangeIntervalDate}
                />
            </Modal>
            <Row gutter={[8, 16]} className="edit-session__content">
                <Col span={24} className="edit-session__coll">
                    <Row>
                        <Col span={6} className="edit-session__label">
                            Найменування
                        </Col>
                        <Col>{interval?.name}</Col>
                    </Row>
                </Col>
                <Col span={24} className="edit-session__coll">
                    <Row>
                        <Col span={6} className="edit-session__label">
                            Періодичність
                        </Col>
                        <Col>{interval?.description}</Col>
                    </Row>
                </Col>
                <Col span={24} className="edit-session__coll">
                    <Row>
                        <Col span={6} className="edit-session__label">
                            Дата запуску
                        </Col>
                        <Col>{schedule.date}</Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={8} justify="end">
                <Space>
                    <Button icon={<HistoryOutlined />} onClick={() => setHistoryModal(true)}>
                        Історія
                    </Button>
                    {disabledEdit && (
                        <>
                            <Button icon={<CloseCircleOutlined />} onClick={() => setDeleteModal(true)}>
                                Відмінити сесію
                            </Button>
                            {interval?.code !== INTERVAL_CODE_DAY && (
                                <Button icon={<CalendarOutlined />} onClick={() => setChangeModal(true)}>
                                    Перенести
                                </Button>
                            )}
                        </>
                    )}
                </Space>
            </Row>
        </Col>
    );
};

export default Editor;
