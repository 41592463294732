import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import { AlienAccount, Relation } from '../../models/Alert';

export const customerApi = createApi({
    reducerPath: 'customerApi',
    baseQuery: customBaseQuery,
    tagTypes: ['customer'],
    endpoints: (builder) => ({
        getCustomerRelations: builder.query<Relation[], number>({
            query(id) {
                return {
                    url: `client/customer/${id}/relations`,
                    method: 'GET',
                };
            },
        }),
        getCustomerAlienAccounts: builder.query<AlienAccount[], number>({
            query(id) {
                return {
                    url: `client/customer/${id}/alien-accounts`,
                    method: 'GET',
                };
            },
            providesTags: (_result, _error, id) => [{ type: 'customer', id }],
        }),
        putCustomerAlienAccounts: builder.mutation<void, AlienAccount>({
            query(data) {
                return {
                    url: `client/customer/alien-accounts`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: (_result, _error, arg) => [{ type: 'customer', id: arg.customerId?.customerId }],
        }),
        deleteCustomerAlienAccounts: builder.mutation<void, AlienAccount['id'][]>({
            query(data) {
                return {
                    url: `client/customer/alien-accounts`,
                    method: 'DELETE',
                    body: data,
                };
            },
            invalidatesTags: ['customer'],
        }),
    }),
});

export const {
    useLazyGetCustomerRelationsQuery,
    useLazyGetCustomerAlienAccountsQuery,
    usePutCustomerAlienAccountsMutation,
    useDeleteCustomerAlienAccountsMutation,
} = customerApi;
