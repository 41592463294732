import buildQuery, { QueryOptions } from 'odata-query';
import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import { SessionCalendarIntervals, SessionHistory } from '../../models/Session';

export interface ReqChangeIntervalDate {
    fromDate?: string;
    toDate: string;
    interval: number;
    multiple?: boolean;
    comment?: string;
}
interface SessionHistoryResponse {
    count: number;
    items: SessionHistory[];
}

interface UpdateCalendarByIdRequestPayload extends SessionCalendarIntervals {
    comment?: string;
}

export const calendarApi = createApi({
    reducerPath: 'calendarApi',
    baseQuery: customBaseQuery,
    tagTypes: ['Calendar'],
    endpoints: (builder) => ({
        getCalendar: builder.query<SessionCalendarIntervals[], Partial<QueryOptions<SessionCalendarIntervals>>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `client/calendar${queryString}`,
                    method: 'GET',
                };
            },
            providesTags: ['Calendar'],
        }),
        updateCalendarById: builder.mutation<void, UpdateCalendarByIdRequestPayload>({
            query(data) {
                return {
                    url: `client/calendar/${data.id}`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: ['Calendar'],
        }),
        changeIntervalDate: builder.mutation<void, ReqChangeIntervalDate>({
            query(data) {
                return {
                    url: `client/calendar/change`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: ['Calendar'],
        }),
        getSessionHistory: builder.query<SessionHistoryResponse, Partial<QueryOptions<SessionHistory>>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: 'client/calendar/history' + queryString,
                    method: 'GET',
                };
            },
            providesTags: ['Calendar'],
        }),
    }),
});

export const {
    useGetCalendarQuery,
    useUpdateCalendarByIdMutation,
    useChangeIntervalDateMutation,
    useGetSessionHistoryQuery,
} = calendarApi;
