import React, { FC, useContext, useMemo, useRef } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { ClientContext } from './ClientContext';
import ResizableColumn from '../../../../../components/common/ResizebleColumn';
import { OTHER_ACCOUNTS_TABLE } from '../../../../../constants/Alert';
import { getColumnSearchProps } from '../../../../../helpers/getColumnSearchProps';
import getTableSizeFromStore from '../../../../../helpers/getTableSizeFromStore';
import { localeCompareSort } from '../../../../../helpers/sort';
import { AlienAccount, ExtendedCustomer } from '../../../../../models/Alert';
import { StatusCodes } from '../../../../../models/Catalog';
import { FormItemsWithInputType } from '../../../../../models/Common';
import { QueryType } from '../../../hooks/useQuery';
import TableWrapper from '../TableWrapper';

interface OtherAccountsProps {
    data?: AlienAccount[];
    loading?: boolean;
    triggerFetch?: () => void;
    queryType?: QueryType;
    alertStatus?: StatusCodes;
}

const formItemsProps = (customerId: ExtendedCustomer['customerId']): FormItemsWithInputType[] => [
    {
        name: ['customerId', 'customerId'],
        initialValue: customerId,
        disabled: true,
        hidden: true,
    },
    {
        name: 'id',
        disabled: true,
        hidden: true,
    },
    {
        label: 'МФО',
        name: 'bankCode',
        required: true,
        rules: [
            {
                len: 6,
            },
        ],
        type: 'number',
    },
    {
        label: 'Найменування',
        name: 'bankName',
        required: true,
        rules: [
            {
                max: 255,
            },
        ],
    },
    {
        label: 'Номер рахунку',
        name: 'accountNumber',
        required: true,
        type: 'number',
        rules: [
            {
                max: 250,
            },
        ],
    },
    {
        label: 'Балансовий номер рахунку',
        name: 'accountBalanceNumber',
        type: 'number',
        rules: [
            {
                max: 250,
            },
        ],
    },
    {
        label: 'Валюта рахунку',
        name: 'accountCurrency',
        rules: [
            {
                max: 255,
            },
        ],
    },
    {
        label: 'IBAN',
        name: 'iban',
        rules: [
            {
                max: 255,
            },
        ],
    },
    {
        label: 'Додаткова інформація',
        name: 'comments',
        initialValue: 'Встановлено під час ПЗНП',
        disabled: true,
    },
    {
        label: 'Джерело інформації',
        name: 'source',
        initialValue: 'Встановлено під час ПЗНП',
        disabled: true,
    },
];

const OtherAccounts: FC<OtherAccountsProps> = ({ data, loading, triggerFetch, alertStatus }) => {
    const size = useMemo(() => getTableSizeFromStore(OTHER_ACCOUNTS_TABLE), []);
    const inputRef = useRef(null);
    const clientInfo = useContext(ClientContext);

    const columns: ColumnsType<AlienAccount> = useMemo(
        () => [
            {
                key: 'bankCode',
                dataIndex: 'bankCode',
                title: (
                    <ResizableColumn colIndex={0} storageKey={OTHER_ACCOUNTS_TABLE}>
                        МФО
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'bankCode'),
                ...getColumnSearchProps<AlienAccount>(inputRef, 'string', 'bankCode'),
                ellipsis: true,
                width: size?.[0] || 60,
            },
            {
                key: 'bankName',
                dataIndex: 'bankName',
                title: (
                    <ResizableColumn colIndex={1} storageKey={OTHER_ACCOUNTS_TABLE}>
                        Найменування
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'bankName'),
                ...getColumnSearchProps<AlienAccount>(inputRef, 'string', 'bankName'),
                ellipsis: true,
                width: size?.[1] || 120,
            },
            {
                key: 'accountNumber',
                dataIndex: 'accountNumber',
                title: (
                    <ResizableColumn colIndex={2} storageKey={OTHER_ACCOUNTS_TABLE}>
                        Номер рахунку
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'accountNumber'),
                ...getColumnSearchProps<AlienAccount>(inputRef, 'string', 'accountNumber'),
                ellipsis: true,
                width: size?.[2] || 198,
            },
            {
                key: 'accountBalanceNumber',
                dataIndex: 'accountBalanceNumber',
                title: (
                    <ResizableColumn colIndex={3} storageKey={OTHER_ACCOUNTS_TABLE}>
                        Балансовий номер рахунку
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'accountBalanceNumber'),
                ...getColumnSearchProps<AlienAccount>(inputRef, 'string', 'accountBalanceNumber'),
                ellipsis: true,
                width: size?.[3] || 40,
            },
            {
                key: 'accountCurrency',
                dataIndex: 'accountCurrency',
                title: (
                    <ResizableColumn colIndex={4} storageKey={OTHER_ACCOUNTS_TABLE}>
                        Валюта рахунку
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'accountCurrency'),
                ...getColumnSearchProps<AlienAccount>(inputRef, 'string', 'accountCurrency'),
                ellipsis: true,
                width: size?.[4] || 36,
            },
            {
                key: 'iban',
                dataIndex: 'iban',
                title: (
                    <ResizableColumn colIndex={5} storageKey={OTHER_ACCOUNTS_TABLE}>
                        IBAN
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'iban'),
                ...getColumnSearchProps<AlienAccount>(inputRef, 'string', 'iban'),
                ellipsis: true,
                width: size?.[5] || 150,
            },
            {
                key: 'comments',
                dataIndex: 'comments',
                title: (
                    <ResizableColumn colIndex={6} storageKey={OTHER_ACCOUNTS_TABLE}>
                        Додаткова інформація
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'comments'),
                ...getColumnSearchProps<AlienAccount>(inputRef, 'string', 'comments'),
                ellipsis: true,
                width: size?.[6] || 150,
            },
            {
                key: 'source',
                dataIndex: 'source',
                title: (
                    <ResizableColumn colIndex={7} storageKey={OTHER_ACCOUNTS_TABLE}>
                        Джерело інформації
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'source'),
                ...getColumnSearchProps<AlienAccount>(inputRef, 'string', 'source'),
                ellipsis: true,
                width: size?.[7] || 50,
            },
        ],
        [size],
    );
    return (
        <TableWrapper
            rowKey="id"
            dataSource={data}
            columns={columns}
            loading={loading}
            triggerFetch={triggerFetch}
            isActionBar={true}
            isRowSelection={true}
            isEditable={true}
            isClearFilters={true}
            formItemsProps={formItemsProps(clientInfo?.customerId)}
            queryType={QueryType.alienAccounts}
            alertStatus={alertStatus}
        />
    );
};

export default OtherAccounts;
