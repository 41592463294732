import { ThemeConfig } from 'antd/es/config-provider/context';

export const colorPrimary = '#445271';
export const colorBgPrimary = '#BDE5FB';
export const colorWhite = '#fff';
export const colorBlack = '#000';

export const mainTheme: ThemeConfig = {
    token: {
        colorPrimary,
    },
    components: {
        Layout: {
            colorBgHeader: colorWhite,
            colorBgTrigger: colorPrimary,
        },
        Menu: {
            colorItemBg: colorPrimary,
            colorSubItemBg: colorPrimary,
            colorItemBgSelected: colorBgPrimary,
            colorItemTextSelected: colorBlack,
            itemMarginInline: 0,
            radiusItem: 0,
            radiusSubMenuItem: 0,
        },
    },
};
