import { FC, useCallback } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { DISABLED_WHITESPACE } from '../../../constants';

interface CommentFormProps {
    onSubmit: (comment: string) => void;
    closeModal: () => void;
}

const CommentForm: FC<CommentFormProps> = ({ onSubmit, closeModal }) => {
    const [form] = Form.useForm();

    const handleSubmit = useCallback(
        (values: any) => {
            onSubmit(values.comment);
            closeModal();
        },
        [onSubmit, closeModal],
    );
    return (
        <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Form.Item
                        label="Коментар"
                        name="comment"
                        rules={[{ required: true }, { whitespace: true, message: DISABLED_WHITESPACE }]}
                        className="marginBottom4">
                        <Input placeholder="Коротко опишіть причину переносу" className="wWide" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Row justify="end">
                        <Button type="primary" htmlType="submit">
                            Перенести сесію
                        </Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};

export default CommentForm;
