export const INTERVAL_CODE_DAY = 'one_time_a_day';
export const INTERVAL_CODE_WEEK = 'one_time_a_week';
export const INTERVAL_CODE_MONTH = 'one_time_per_month';
export const INTERVAL_CODE_QUARTER = 'one_time_per_quarter';

export const INTERVAL_DESC_DAY = '1 раз на день';
export const INTERVAL_DESC_WEEK = '1 раз на тиждень';
export const INTERVAL_DESC_MONTH = '1 раз на календарний місяць';
export const INTERVAL_DESC_QUARTER = '1 раз на квартал';

export enum STATUS_CODES {
    success = 'success',
    has_errors = 'has_errors',
    terminated = 'terminated',
    in_progress = 'in_progress',
    waiting = 'waiting',
    interrupted = 'interrupted',
    in_prepare = 'in_prepare',
    result_processing = 'result_processing',
}

export enum STATUS_CODES_DESC {
    success = 'Успішна',
    has_errors = 'Завершена з помилками',
    terminated = 'Системна помилка',
    in_progress = 'В процесі',
    waiting = 'Очікує',
}

export const SESSION_TABLE_SIZE = 'sessionTableSize';
export const intervalFilter = [
    { value: INTERVAL_CODE_DAY, text: INTERVAL_DESC_DAY },
    { value: INTERVAL_CODE_WEEK, text: INTERVAL_DESC_WEEK },
    { value: INTERVAL_CODE_MONTH, text: INTERVAL_DESC_MONTH },
    { value: INTERVAL_CODE_QUARTER, text: INTERVAL_DESC_QUARTER },
];

export const sessionStatusFilter = [
    { text: STATUS_CODES_DESC.success, value: STATUS_CODES.success },
    { text: STATUS_CODES_DESC.has_errors, value: STATUS_CODES.has_errors },
    { text: STATUS_CODES_DESC.terminated, value: STATUS_CODES.terminated },
    { text: STATUS_CODES_DESC.in_progress, value: STATUS_CODES.in_progress },
    { text: STATUS_CODES_DESC.waiting, value: STATUS_CODES.waiting },
];

export const SINGLE_SESSION_KEY = 'single_session';
