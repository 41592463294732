import { ColumnType } from 'antd/es/table';
import ResizableColumn from '../../../components/common/ResizebleColumn';
import { TableSettings } from '../../../helpers/getTableSizeFromStore';

export const getResizableColumn = <T extends unknown>(
    columns: ColumnType<T>[],
    storageKey: string,
    size?: TableSettings,
): ColumnType<T>[] => {
    return columns.map((column, i) => {
        return {
            ...column,
            title: (
                <ResizableColumn colIndex={i} storageKey={storageKey}>
                    <>{column.title}</>
                </ResizableColumn>
            ),
            width: size?.[i] || column.width || 100,
        };
    });
};
