import { Typography } from 'antd';
import { getApiVersion } from '../../redux/features/authSlice';
import { useAppSelector } from '../../redux/hooks';

const { Text } = Typography;

const Version = () => {
    const apiVersion = useAppSelector(getApiVersion);
    return (
        <>
            {apiVersion && (
                <Text type="secondary" code>
                    {apiVersion}
                </Text>
            )}
        </>
    );
};

export default Version;
