import { SorterResult } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { ALERT_PARAMETER } from '../constants/Alert';
import { getContainFilterValue, getSorterQuery } from '../helpers';

const sortByFIO = (sorter: SorterResult<any>) => {
    const order = sorter.order === 'ascend' ? 'asc' : 'desc';
    return `${sorter.field}/lastName ${order},${sorter.field}/firstName ${order},${sorter.field}/middleName ${order}`;
};

const sortByRuleCode = (sorter: any) => {
    const order = sorter.order === 'ascend' ? 'asc' : 'desc';
    const columnKey = 'rule/code';
    return `${columnKey} ${order}`;
};

const getAlertsFilters = (filters: any) => {
    let equalFilters = [];
    let containFilters = [];
    for (let key in filters) {
        if (filters[key] !== null) {
            switch (key) {
                case ALERT_PARAMETER.id:
                case ALERT_PARAMETER.sessionCode.join('/'):
                case ALERT_PARAMETER.customerNationalName.join('/'):
                case ALERT_PARAMETER.customerCode.join('/'):
                case ALERT_PARAMETER.customerId.join('/'):
                case ALERT_PARAMETER.customerBankCode.join('/'):
                case ALERT_PARAMETER.conclusionDescription.join('/'):
                case ALERT_PARAMETER.conclusion:
                case ALERT_PARAMETER.branchNameDescription.join('/'):
                    containFilters.push({
                        or: filters[key].map((item: string) => ({
                            [`tolower(${key})`]: getContainFilterValue(item),
                        })),
                    });
                    break;
                case ALERT_PARAMETER.deadlineDate:
                case ALERT_PARAMETER.conclusionDate:
                case ALERT_PARAMETER.executionDate:
                case ALERT_PARAMETER.createdDate:
                case ALERT_PARAMETER.sessionStartDate.join('/'):
                case ALERT_PARAMETER.requestReceivedDate:
                    equalFilters.push({
                        [key]: {
                            ge: {
                                type: 'raw',
                                value: dayjs(filters[key][0]).startOf('day').toISOString(),
                            },
                            le: {
                                type: 'raw',
                                value: dayjs(filters[key][0]).endOf('day').toISOString(),
                            },
                        },
                    });
                    break;
                case ALERT_PARAMETER.analystId.join('/'):
                case ALERT_PARAMETER.controllerId.join('/'):
                case ALERT_PARAMETER.customerTypeName.join('/'):
                case ALERT_PARAMETER.statusDescription.join('/'):
                case ALERT_PARAMETER.statusCode.join('/'):
                case ALERT_PARAMETER.urgencyDescription.join('/'):
                case ALERT_PARAMETER.urgencyCode.join('/'):
                case ALERT_PARAMETER.rulesRuleCode.join('/'):
                case ALERT_PARAMETER.ruleTypeCode.join('/'):
                case ALERT_PARAMETER.rulesIsAuto.join('/'):
                    equalFilters.push({
                        or: filters[key].map((item: string) => ({
                            [key]: `${item}`,
                        })),
                    });
                    break;
                default:
                    break;
            }
        }
    }
    return [...containFilters, ...equalFilters];
};

const processAlertsFilters = (filters: any) => {
    let filter = filters;

    if (filter[ALERT_PARAMETER.criterionCodes] || filter[ALERT_PARAMETER.indicatorCodes]) {
        const criterionTypeCode = filter[ALERT_PARAMETER.criterionCodes] ? ['criterion'] : [];
        const indicatorTypeCode = filter[ALERT_PARAMETER.indicatorCodes] ? ['indicator'] : [];
        const ruleTypeCode = [...criterionTypeCode, ...indicatorTypeCode];

        const criterionRuleCode = filter[ALERT_PARAMETER.criterionCodes] ?? [];
        const indicatorRuleCode = filter[ALERT_PARAMETER.indicatorCodes] ?? [];
        const ruleCodes = [...criterionRuleCode, ...indicatorRuleCode];

        filter = {
            ...filter,
            'rule/type/code': ruleTypeCode,
            'rule/code': ruleCodes,
            'rules/isAuto': [1],
        };
    }
    return getAlertsFilters(filter);
};

const processAlertsSorter = (sorter: any) => {
    if (!sorter?.order) return '';
    let processedSorter = sorter;
    if (!Array.isArray(sorter) && Object.keys(sorter).length > 0) {
        switch (sorter.field) {
            case ALERT_PARAMETER.analyst:
            case ALERT_PARAMETER.controller:
                processedSorter = sortByFIO(sorter);
                break;
            case ALERT_PARAMETER.indicatorCodes:
            case ALERT_PARAMETER.criterionCodes:
                processedSorter = sortByRuleCode(sorter);
                break;
            default:
                processedSorter = getSorterQuery(sorter);
                break;
        }
    }

    return processedSorter;
};

export const processAlertsQuery = (filters: any, sorter: any) => {
    const processedSorter = processAlertsSorter(sorter);
    const processedFilters = processAlertsFilters(filters);
    return {
        processedFilters,
        processedSorter,
    };
};
