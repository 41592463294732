import React, { FC, useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import DataField from './DataField';
import { RULES_TYPE_CRITERION } from '../../../constants/Rules';
import { OptionsType, RuleModel, ruleVocabulary } from '../../../models/RulesModel';
import {
    useLazyGetRuleIntervalQuery,
    useLazyGetRuleRiskLevelsQuery,
    useLazyGetRuleUrgencyTypesQuery,
} from '../../../redux/api/dictionaryApi';

interface RulesConditionsProps {
    selectedRule?: RuleModel;
    optionType: OptionsType;
}

const getOptions = (vocabulary?: ruleVocabulary[]) => {
    return vocabulary?.map((record) => ({
        value: record.code,
        label: record.description,
    }));
};

const Conditions: FC<RulesConditionsProps> = ({ selectedRule, optionType }) => {
    const [getRiskLevels, { data: riskLevels }] = useLazyGetRuleRiskLevelsQuery();
    const [getIntervals, { data: intervals }] = useLazyGetRuleIntervalQuery();
    const [getUrgencyTypes, { data: urgencyTypes }] = useLazyGetRuleUrgencyTypesQuery();

    useEffect(() => {
        if (optionType === 'edit') {
            riskLevels ?? getRiskLevels();
            intervals ?? getIntervals();
            urgencyTypes ?? getUrgencyTypes();
        }
    }, [getIntervals, getRiskLevels, getUrgencyTypes, intervals, optionType, riskLevels, urgencyTypes]);

    return (
        <div>
            <div className="rules-block__sub_title">Умови</div>
            <div className="rules-block__items">
                {selectedRule?.type.code === RULES_TYPE_CRITERION && (
                    <>
                        <DataField
                            describe="Рівень ризику"
                            value={selectedRule.riskLevel?.description}
                            optionType={optionType}>
                            <Form.Item name={['riskLevel', 'code']}>
                                <Select options={getOptions(riskLevels)} />
                            </Form.Item>
                        </DataField>
                        <DataField describe="Бали" value={`${selectedRule.score ?? '-'}`} optionType={optionType}>
                            <Form.Item name="score">
                                <Input type="number" className="wWide" />
                            </Form.Item>
                        </DataField>
                        <DataField
                            describe="Бали ДП"
                            value={`${selectedRule.scoreStateEnterprise ?? '-'}`}
                            optionType={optionType}>
                            <Form.Item name="scoreStateEnterprise">
                                <Input type="number" className="wWide" />
                            </Form.Item>
                        </DataField>
                    </>
                )}
                {selectedRule?.interval && (
                    <DataField
                        describe="Періодичність виявлення"
                        value={selectedRule?.interval?.description}
                        optionType={optionType}>
                        <Form.Item name={['interval', 'code']}>
                            <Select options={getOptions(intervals)} />
                        </Form.Item>
                    </DataField>
                )}

                {selectedRule?.expireInterval && (
                    <DataField
                        describe="Запобігання повторів (дублів)"
                        value={selectedRule?.expireInterval?.description}
                        optionType={optionType}>
                        <Form.Item name={['expireInterval', 'code']}>
                            <Select options={getOptions(intervals)} />
                        </Form.Item>
                    </DataField>
                )}
                {selectedRule?.urgencyType && (
                    <DataField
                        describe="Терміновість алерта"
                        value={selectedRule?.urgencyType?.description}
                        optionType={optionType}>
                        <Form.Item name={['urgencyType', 'code']}>
                            <Select options={getOptions(urgencyTypes)} />
                        </Form.Item>
                    </DataField>
                )}
            </div>
        </div>
    );
};

export default Conditions;
