import { FC, useState } from 'react';
import { Button, Col, Form, Modal, Row, Space, Spin } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { useForm } from 'antd/es/form/Form';
import cronstrue from 'cronstrue';
import 'cronstrue/locales/uk';
import { baseSuccessNotification } from '../../../helpers/baseSuccessNotification';
import { Schedule } from '../../../models/Schedule';
import { useUpdateIntervalMutation } from '../../../redux/api/schedulesApi';

interface IntervalEditModalProps {
    schedule: Schedule;
    onSuccess: () => void;
}

interface IntervalsForm {
    interval_0: string;
    interval_1: string;
    interval_2: string;
    interval_3: string;
    interval_4: string;
}
const intervalKeys: (keyof IntervalsForm)[] = ['interval_0', 'interval_1', 'interval_2', 'interval_3', 'interval_4'];

const CronInputGroup: FC<{ cron: string | null; disabled?: boolean }> = ({ cron, disabled }) => {
    const cronParts = (cron || '* * * * *').split(' ');
    const cronValidationRegex = /^[\d*\\*][,\\/]?(?:[\d\\,-]*)$/;

    return (
        <Space>
            {cronParts.map((part, index) => {
                const key = 'interval_' + index + (disabled ? '_disabled' : '');
                return (
                    <Form.Item
                        {...(!disabled ? { name: key } : {})}
                        key={key}
                        initialValue={part}
                        rules={[{ required: true }]}>
                        <MaskedInput mask={cronValidationRegex} disabled={disabled} value={part} />
                    </Form.Item>
                );
            })}
        </Space>
    );
};

const IntervalEditModal: FC<IntervalEditModalProps> = ({ schedule, onSuccess }) => {
    const [form] = useForm();
    const [cron, setCron] = useState<string>(schedule.interval || '');
    const [updateInterval, { isLoading }] = useUpdateIntervalMutation();

    const getCron = (cron: string) => {
        if (cron)
            try {
                return cronstrue.toString(cron, { locale: 'uk' });
            } catch (e) {
                return 'Invalid cron';
            }
    };
    const handleFormChange = (_changedValues: Partial<IntervalsForm>, allValues: IntervalsForm) => {
        const cronExpression = intervalKeys.map((part) => allValues[part]).join(' ');
        setCron(cronExpression);
    };

    const handleSubmit = () => {
        if (getCron(cron) === 'Invalid cron' || !cron || cron === schedule.interval) {
            return;
        }
        Modal.confirm({
            title: 'Підтвердження',
            content: `Ви впевнені, що хочете змінити цей інтервал?`,
            onOk: () =>
                updateInterval({ id: schedule.id, interval: cron }).then(() => {
                    onSuccess();
                    baseSuccessNotification('Інтервал змінено!');
                }),
            okText: 'Так',
            cancelText: 'Повернутися',
            style: { top: '20%' },
        });
    };
    return (
        <Spin spinning={isLoading}>
            <Form<IntervalsForm>
                form={form}
                onFinish={handleSubmit}
                onValuesChange={handleFormChange}
                validateMessages={{ required: 'Обов’язкове поле!' }}
                layout="vertical">
                Cron вираз складається з п'яти полів, які представляють:
                <Row>
                    <Col span={10}>
                        <Row>1. Хвилина (0 - 59)</Row>
                        <Row>2. Година (0 - 23)</Row>
                        <Row>3. День місяця (1 - 31)</Row>
                        <Row>4. Місяць (1 - 12)</Row>
                        <Row>5. День тижня (0 - 6, де 0 - неділя)</Row>
                    </Col>
                    <Col span={14}>
                        <Row>Спеціальні символи:</Row>
                        <Row>* (зірочка) - представляє будь-яке значення.</Row>
                        <Row>, (кома) - дозволяє вказати кілька значень.</Row>
                        <Row>- (тире) - визначає діапазон значень.</Row>
                        <Row>/ (слеш) - вказує на інтервал виконання.</Row>
                    </Col>
                </Row>
                <Row justify="center" style={{ marginTop: 10 }}>
                    <CronInputGroup cron={schedule.interval} />
                    {getCron(cron)}
                </Row>
                <Row justify="center" style={{ marginTop: 10 }}>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Row>
            </Form>
        </Spin>
    );
};

export default IntervalEditModal;
