import { useState, useEffect } from 'react';
import { TablePaginationConfig } from 'antd';

interface UsePaginationProps {
    defaultCurrent?: number;
    defaultPageSize?: number;
    total?: number;
}

const usePagination = (defaultValue?: UsePaginationProps) => {
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: defaultValue?.defaultCurrent || 1,
        pageSize: defaultValue?.defaultPageSize || 10,
        total: defaultValue?.total || 0,
        showSizeChanger: true,
    });

    useEffect(() => {
        setPagination((prev) => ({
            ...prev,
            total: defaultValue?.total,
        }));
    }, [defaultValue?.total]);

    const handleTableChange = (newPagination: TablePaginationConfig) => {
        setPagination(newPagination);
    };

    return {
        pagination,
        handleTableChange,
    };
};

export { usePagination };
