import { useMemo } from 'react';
import { RuleModel } from '../../../models/RulesModel';

export const useTotalRiskScore = (
    approvedCriteriaCodes: number[] = [],
    declinedCriteriaCodes: number[] = [],
    additionalCriteriaCodes: number[] = [],
    criteria: RuleModel[],
    isStateEnterprise: number = 0,
) => {
    const totalRiskScore = useMemo(() => {
        let totalScore = 0;
        let codesList = [...approvedCriteriaCodes, ...declinedCriteriaCodes, ...additionalCriteriaCodes];
        if (codesList?.length) {
            criteria
                ?.filter((item) => codesList.includes(item.id))
                .forEach((item) => {
                    const itemScore = +((isStateEnterprise ? item.scoreStateEnterprise : item.score) || 0);
                    if (!declinedCriteriaCodes.includes(item.id)) {
                        totalScore += itemScore;
                    }
                });
        }
        return totalScore;
    }, [approvedCriteriaCodes, declinedCriteriaCodes, additionalCriteriaCodes, criteria, isStateEnterprise]);
    return totalRiskScore;
};
