export enum LocalityTypes {
    city = 'м.',
    urban_village = 'смт',
    village = 'с.',
}

export enum StreetTypes {
    street = 'вул.',
    avenue = 'просп.',
    boulevard = 'б-р',
    alley = 'пров.',
    quay = 'наб.',
    area = 'площа',
    square = 'майдан',
    descent = 'узвіз',
    highway = 'шосе',
}

export enum BuildingTypes {
    building = 'буд.',
    d_v = 'д/в',
    n_p = 'н/п',
    p_n = 'п/н',
}

export enum CorpsTypes {
    corps = 'корп.',
    section = 'секц.',
    block = 'блок',
}

export enum ApartmentTypes {
    apartment = 'кв.',
    office = 'оф.',
    place = 'прим.',
    room = 'кім.',
}

export enum DictionaryColumnsTypes {
    string = 'string',
    number = 'number',
    dictionary = 'dictionary',
    boolean = 'boolean',
    date = 'date',
}
