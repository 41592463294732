import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query';
import { notification } from 'antd';
import { logout, setAuthData } from '../../features/authSlice';
import { prepareAuthHeader, prepareRefreshHeaders } from './helpers';
import { RootState } from '../../store';
import { Mutex } from 'async-mutex';
import { QueryReturnValue } from '@reduxjs/toolkit/src/query/baseQueryTypes';

export const BASE_URL = process.env.REACT_APP_BASE_URL as string;
const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: prepareAuthHeader,
});

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const token = (api.getState() as RootState).auth.refreshToken;
                const refreshQuery = fetchBaseQuery({
                    baseUrl: BASE_URL,
                    prepareHeaders: prepareRefreshHeaders,
                    method: 'POST',
                    body: JSON.stringify({ refreshToken: token }),
                });
                const res: QueryReturnValue<any, FetchBaseQueryError, FetchBaseQueryMeta> = await refreshQuery(
                    '/user/token/refresh',
                    api,
                    extraOptions,
                );

                if (res.data?.refresh_token as string) {
                    api.dispatch(
                        setAuthData({
                            user: res.data?.user,
                            accessToken: res.data?.access_token,
                            refreshToken: res.data?.refresh_token,
                            apiVersion: res.data?.apiVersion,
                        }),
                    );
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    notification.warning({
                        message: 'Час сессії вийшов!',
                        description: 'Будь ласка, авторизуйтесь повторно',
                    });
                    api.dispatch(logout());
                }
            } finally {
                // release must be called once the mutex should be released again.
                release();
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};

export default customBaseQuery;
