import { Dayjs } from 'dayjs';

import {
    INTERVAL_CODE_DAY,
    INTERVAL_CODE_MONTH,
    INTERVAL_CODE_QUARTER,
    INTERVAL_CODE_WEEK,
} from '../constants/Session';
import { COLOR_BLUE, COLOR_DEFAULT, COLOR_GREEN, COLOR_ORANGE, COLOR_PURPLE } from '../constants/common';

export const getSessionColor = (id?: string) => {
    switch (id) {
        case INTERVAL_CODE_WEEK:
            return COLOR_PURPLE;
        case INTERVAL_CODE_MONTH:
            return COLOR_ORANGE;
        case INTERVAL_CODE_QUARTER:
            return COLOR_GREEN;
        case INTERVAL_CODE_DAY:
            return COLOR_BLUE;
        default:
            return COLOR_DEFAULT;
    }
};

export const getQuarterValue = (number: number) => {
    switch (number) {
        case 0:
        case 1:
        case 2:
            return 1;
        case 3:
        case 4:
        case 5:
            return 2;
        case 6:
        case 7:
        case 8:
            return 3;
        case 9:
        case 10:
        case 11:
            return 4;
    }
};

export const getPeriodValue = (date: Dayjs, period: string) => {
    switch (period) {
        case INTERVAL_CODE_WEEK:
            return date.locale('ua').week();
        case INTERVAL_CODE_MONTH:
            return date.locale('ua').month();
        case INTERVAL_CODE_QUARTER:
            return getQuarterValue(date.locale('ua').month());
    }
};
