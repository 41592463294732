import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import { ALLOWED_FUNCTIONS } from '../../constants/Menu';
import { RoleModel } from '../../models/RoleModel';

export const rolesApi = createApi({
    reducerPath: 'rolesApi',
    baseQuery: customBaseQuery,
    tagTypes: ['Roles'],
    endpoints: (builder) => ({
        getRole: builder.query<any, string>({
            query(roleName) {
                return {
                    url: `user/roles/${roleName}`,
                    method: 'GET',
                };
            },
        }),
        getRoles: builder.query<RoleModel[], void>({
            query() {
                return {
                    url: `user/roles`,
                    method: 'GET',
                };
            },
            transformResponse: (roles: RoleModel[]) =>
                roles?.map((role) => {
                    role.menuFunctions = role.menuFunctions?.filter((item) => ALLOWED_FUNCTIONS.includes(item.path));
                    return role;
                }),
            providesTags: ['Roles'],
        }),
        updateRole: builder.mutation<RoleModel, any>({
            query(data) {
                return {
                    url: `user/roles/${data.name}`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: ['Roles'],
        }),
    }),
});

export const { useGetRolesQuery, useGetRoleQuery, useUpdateRoleMutation, useLazyGetRolesQuery } = rolesApi;
