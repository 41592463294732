import { Routes, Route } from 'react-router-dom';
import 'antd/dist/reset.css';
import MainLayout from './components/MainLayout';
import PrivateRoute from './components/PrivateRoute';
import { RouteCode } from './constants/enums';
import './css/App.css';
import ChangePassword from './pages/ChangePassword';
import Login from './pages/Login';

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path={`/${RouteCode.login}`} element={<Login />} />
                <Route path={`/${RouteCode.changePassword}`} element={<ChangePassword />} />
                <Route
                    path="*"
                    element={
                        <PrivateRoute>
                            <MainLayout />
                        </PrivateRoute>
                    }
                />
            </Routes>
        </div>
    );
}

export default App;
