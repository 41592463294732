import { useState } from 'react';
import { TableRowSelection } from 'antd/es/table/interface';

export const useRowSelection = ({ ...rest }: TableRowSelection<any> = {}) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const onChange = (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
    };

    const clearSelection = () => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };

    const rowSelection: TableRowSelection<any> = {
        ...rest,
        selectedRowKeys,
        onChange,
    };

    return { rowSelection, selectedRows, selectedRowKeys, clearSelection } as const;
};
