export const isArrayOfString = <T>(value: T): value is T =>
    Array.isArray(value) && value.every((item) => typeof item === 'string');

export const isObject = <T>(value: T): value is T =>
    typeof value === 'object' && value !== null && !Array.isArray(value);

export const isArray = <T>(value: T): value is T => Array.isArray(value);

export const isSimpleType = <T>(value: T): value is T =>
    typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
