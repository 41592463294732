import { ChangeEvent, FC, useEffect, useState } from 'react';

import { Input, Row } from 'antd';

import { useDebounce } from '../../../hooks';

interface UserFiltersProps {
    setFilters: (filter: string) => void;
}

const UserFilters: FC<UserFiltersProps> = ({ setFilters }) => {
    const [inputValue, setInputValue] = useState('');

    const debouncedValue = useDebounce(inputValue);

    useEffect(() => setFilters(debouncedValue), [debouncedValue, setFilters]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    return (
        <Row gutter={[0, 16]}>
            <Input
                value={inputValue}
                style={{ width: '30rem', marginBottom: '1.5rem' }}
                placeholder="Користувач"
                onChange={handleChange}
            />
        </Row>
    );
};

export default UserFilters;
