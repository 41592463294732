import { Dispatch, FC, Key, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Row, Col, Table, TablePaginationConfig, TableProps, Input, notification } from 'antd';
import { Expand, Filter, OrderBy, QueryOptions } from 'odata-query';
import { GetComponentProps } from 'rc-table/lib/interface';
import { getColumns } from './TableDictionaryGetColumn';
import UpdateDictionaryModal from './UpdateDictionaryModal';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { getSorterQuery, getTopSkip } from '../../../helpers';
import { debounce } from '../../../helpers/debounce';
import { getExpand, makeFilterQuery } from '../../../helpers/filterDictionary';
import { DictionaryModel, RecordModel } from '../../../models/DictionaryModel';
import { useGetDictionaryQuery } from '../../../redux/api/dictionaryApi';
import { useSearch } from '../hooks/useSearch';

interface TableDictionaryProps {
    dictionary: DictionaryModel;
    selectedRows: Key[];
    setSelectedRows: Dispatch<SetStateAction<Key[]>>;
}

const getPlaceholder = (dictionary: DictionaryModel) => {
    let placeholder = [];
    if (dictionary.columns.some((item) => item.colName === 'code' || item.colName === 'iso')) placeholder.push('код');
    if (dictionary.columns.some((item) => item.colName === 'description')) placeholder.push('назва');
    if (dictionary.columns.some((item) => item.colName === 'cyrillic')) placeholder.push('назва країни (кирилиця)');
    if (dictionary.columns.some((item) => item.colName === 'street')) placeholder.push('вулиця');
    return placeholder.join(' або ');
};

const defaultPagination = {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
};

const TableDictionary: FC<TableDictionaryProps> = ({ dictionary, setSelectedRows, selectedRows }) => {
    // block of modal START
    const [isOpenedUpdateModal, setIsOpenedUpdateModal] = useState(false);
    const toggleUpdateRecordModal = useCallback(() => setIsOpenedUpdateModal((prev) => !prev), []);
    // block of modal END

    // block of create query START
    const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);
    const { searchFilter, handleSearchChange } = useSearch(dictionary.columns);
    const [filter, setFilter] = useState<Filter[]>([]);
    const defaultOrderBy = dictionary.columns.some((item) => item.colOrmName === 'code') ? 'code' : '';
    const [orderBy, setOrderBy] = useState<OrderBy<RecordModel>>(defaultOrderBy);
    const defaultExpand = useMemo(() => getExpand(dictionary.columns), [dictionary.columns]);
    const [expand, setExpand] = useState<Expand<RecordModel>>(defaultExpand);
    const topSkip = useRef(useMemo(() => getTopSkip(pagination.pageSize, pagination.current), [pagination]));

    const queryData = useMemo<Partial<QueryOptions<RecordModel>>>(() => {
        if (searchFilter.length !== 0) {
            topSkip.current = getTopSkip(defaultPagination.pageSize, defaultPagination.current);
        }
        return {
            count: true,
            ...topSkip,
            expand,
            filter: [...filter, ...searchFilter],
            orderBy,
        };
    }, [expand, filter, orderBy, searchFilter, topSkip]);

    const { data, isLoading, isFetching } = useGetDictionaryQuery({ dictionaryName: dictionary.name, queryData });
    const count = data?.count;

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: count }));
    }, [count]);

    const handleTableChange: TableProps<RecordModel>['onChange'] = (newPagination, filters, sorter) => {
        setPagination(newPagination);
        setOrderBy(getSorterQuery(sorter));
        setFilter(makeFilterQuery(filters, dictionary.columns));
        setExpand(getExpand(dictionary.columns));
    };
    // block of create query END

    // block of checkbox and row click processing START
    const [clickedRow, setClickedRow] = useState<RecordModel | null>(null);

    const handleCheckboxChange = (selectedRowKeys: Key[]) => setSelectedRows(selectedRowKeys);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleError = useCallback(
        debounce(
            () =>
                notification.error({
                    ...BASE_NOTIFICATION_CONFIG,
                    message: 'Рішення використовується в алерті, редагування неможливе',
                }),
            300,
        ),
        [],
    );

    const toggleCheckboxChange = useCallback(
        (record: RecordModel) => {
            if (selectedRows.includes(record.id)) {
                setSelectedRows((prev) => prev.filter((id) => id !== record.id));
            } else {
                setSelectedRows((prev) => [...prev, record.id]);
            }
        },
        [selectedRows, setSelectedRows],
    );

    const rowCb: GetComponentProps<RecordModel> = useCallback(
        (record) => ({
            onClick: () => {
                if (dictionary.name === 'dict_alert_conclusions' && record.attached > 0) {
                    handleError();
                    return;
                }
                toggleCheckboxChange(record);
            },
            onDoubleClick: () => {
                if (dictionary.name === 'dict_alert_conclusions' && record.attached > 0) {
                    return;
                }
                setClickedRow(record);
                setIsOpenedUpdateModal(true);
            },
        }),
        [toggleCheckboxChange, handleError, dictionary.name],
    );
    // block of checkbox and row click processing END

    const inputRef = useRef(null);

    return (
        <>
            <UpdateDictionaryModal
                onCancel={toggleUpdateRecordModal}
                onSuccess={toggleUpdateRecordModal}
                selected={clickedRow}
                dictionary={dictionary}
                isOpenedUpdateModal={isOpenedUpdateModal}
                setSelected={setClickedRow}
            />
            <Row>
                <Col>
                    <Input
                        style={{ width: '30rem', marginBottom: '1.5rem' }}
                        placeholder={`Пошук: ${getPlaceholder(dictionary)}`}
                        onChange={handleSearchChange}
                    />
                </Col>
                <Col span={24}>
                    <Table<RecordModel>
                        rowSelection={{
                            getCheckboxProps: (record) => ({
                                disabled: dictionary.name === 'dict_alert_conclusions' && record.attached > 0,
                            }),
                            onChange: handleCheckboxChange,
                            selectedRowKeys: selectedRows,
                            renderCell(_checked, _record, _index, node) {
                                return (
                                    <div className="table__checkbox" onClick={(e) => e.stopPropagation()}>
                                        {node}
                                    </div>
                                );
                            },
                        }}
                        scroll={{ x: dictionary.columns.length * 100, y: 'calc(100vh - 475px)' }}
                        className="main-table"
                        rowKey="id"
                        loading={isLoading || isFetching}
                        columns={getColumns(dictionary, inputRef)}
                        dataSource={data?.items}
                        pagination={pagination}
                        onChange={handleTableChange}
                        onRow={dictionary.name === '' ? undefined : rowCb}
                        bordered
                        size="small"
                    />
                </Col>
            </Row>
        </>
    );
};

export default TableDictionary;
