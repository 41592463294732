import React, { useCallback } from 'react';
import { Row, Col, DatePicker, Button, Form, Spin, Checkbox, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/uk';
import { DISABLED_WHITESPACE } from '../../../constants';
import { DATE_FORMAT, DATE_FORMAT_FOR_QUERY } from '../../../constants/Alert';
import { SESSION_INTERVAL_TRANSLATE } from '../../../constants/enums';
import { getPeriodValue } from '../../../helpers/calendar';
import { ReqChangeIntervalDate } from '../../../redux/api/calendarApi';

interface UpdateFormProps {
    date: string;
    onSubmit: (data: ReqChangeIntervalDate) => void;
    intervalId: number;
    loading: boolean;
    intervalCode: string;
}

const UpdateForm = ({ date, intervalId, onSubmit, loading, intervalCode }: UpdateFormProps) => {
    const [form] = Form.useForm();
    const newDate = Form.useWatch('newDate', form);
    const scheduleDate = dayjs(date);
    const periodValue = getPeriodValue(scheduleDate, intervalCode);
    const handleSubmit = useCallback(
        (values: any) => {
            onSubmit({
                fromDate: date,
                toDate: values.newDate.format(DATE_FORMAT_FOR_QUERY),
                interval: intervalId,
                multiple: values.multiple,
                comment: values.comment,
            });
        },
        [date, intervalId, onSubmit],
    );

    return (
        <Spin spinning={loading}>
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{ date: scheduleDate, newDate: scheduleDate }}>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Form.Item label="Дата запуску: план" name="date" rules={[{ required: true }]}>
                            <DatePicker disabled className="wWide" format={DATE_FORMAT} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Дата запуску: перенос" name="newDate" rules={[{ required: true }]}>
                            <DatePicker
                                disabledDate={(date: Dayjs) => getPeriodValue(date, intervalCode) !== periodValue}
                                placeholder="Оберіть дату"
                                className="wWide"
                                format={DATE_FORMAT}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Коментар"
                            name="comment"
                            rules={[{ required: true }, { whitespace: true, message: DISABLED_WHITESPACE }]}
                            className="marginBottom4">
                            <Input placeholder="Коротко опишіть причину переносу" className="wWide" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="multiple" valuePropName="checked">
                            <Checkbox>
                                {`Застосувати до всіх ${
                                    SESSION_INTERVAL_TRANSLATE[intervalCode as keyof typeof SESSION_INTERVAL_TRANSLATE]
                                }`}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={date === newDate.format(DATE_FORMAT_FOR_QUERY)}>
                                Перенести
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default UpdateForm;
