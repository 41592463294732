import { FC, useState } from 'react';
import closeWithoutSaveConfirmModal from '../../../components/common/closeWithoutSaveConfirmModal';
import Modal from '../../../components/common/Modal';
import UpdateRecordForm from '../../../components/form/dictionary-form/UpdateRecordForm';
import { DictionaryModel, RecordModel } from '../../../models/DictionaryModel';

interface UpdateDictionaryModalProps {
    onSuccess: () => void;
    onCancel: () => void;
    selected: RecordModel | null;
    dictionary: DictionaryModel;
    isOpenedUpdateModal: boolean;
    setSelected: React.Dispatch<React.SetStateAction<RecordModel | null>>;
}

const UpdateDictionaryModal: FC<UpdateDictionaryModalProps> = ({
    onCancel,
    selected,
    isOpenedUpdateModal,
    setSelected,
    ...rest
}) => {
    const [isEdited, setIsEdited] = useState(false);

    const confirmModal = () => {
        closeWithoutSaveConfirmModal(() => {
            onCancel();
            setIsEdited(false);
            setSelected(null);
        });
    };

    return (
        <Modal
            destroyOnClose
            title={`Редагування  ${selected?.description || selected?.cyrillic || 'запису'}:`}
            onCancel={
                isEdited
                    ? confirmModal
                    : () => {
                          onCancel();
                          setSelected(null);
                      }
            }
            open={isOpenedUpdateModal}
            footer={null}
            bodyStyle={{ paddingTop: '1rem' }}>
            <UpdateRecordForm
                setIsEdited={setIsEdited}
                isOpenedUpdateModal={isOpenedUpdateModal}
                selected={selected}
                {...rest}
            />
        </Modal>
    );
};

export default UpdateDictionaryModal;
