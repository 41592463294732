import React, { FC } from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import { RouteList } from '../../constants/enums';
import { BaseRouteModel } from '../../models/RouterModel';

const Routers: FC = () => {
    const Default = RouteList[0].component ? RouteList[0].component : () => <div>404</div>;
    const defaultPath = RouteList[0].path;
    return (
        <Routes>
            {RouteList.map(
                (route: BaseRouteModel) =>
                    route.component && (
                        <Route key={route.code} path={route.path || ''} element={<route.component />}></Route>
                    ),
            )}
            <Route path="*" element={defaultPath ? <Navigate to={defaultPath} /> : <Default />} />
        </Routes>
    );
};

export default Routers;
