import React, { useCallback } from 'react';

import { Row, Col, DatePicker, Button, Form, Spin, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/uk';

import { DATE_FORMAT, DATE_FORMAT_FOR_QUERY } from '../../../constants/Alert';
import { RecordModel } from '../../../models/DictionaryModel';
import { ReqChangeIntervalDate } from '../../../redux/api/calendarApi';

interface CreateFormProps {
    dictionary?: RecordModel[];
    onSubmit: (data: ReqChangeIntervalDate) => void;
    loading: boolean;
}

interface FormData {
    date: Dayjs;
    interval: number;
}

const deadLine = dayjs().startOf('day');
const CreateForm = ({ dictionary, onSubmit, loading }: CreateFormProps) => {
    const [form] = Form.useForm();

    const handleSubmit = useCallback(
        (values: FormData) => {
            onSubmit({ toDate: values.date.format(DATE_FORMAT_FOR_QUERY), interval: values.interval });
        },
        [onSubmit],
    );

    return (
        <Spin spinning={loading}>
            <Form<FormData> form={form} onFinish={handleSubmit} layout="vertical">
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Form.Item
                            label="Періодичність"
                            name="interval"
                            rules={[{ required: true }]}
                            className="marginBottom4">
                            <Select
                                options={dictionary?.map((record) => ({ value: record.id, label: record.description }))}
                                placeholder="Виберіть значення"
                                className="wWide"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Дата запуску: план" name="date" rules={[{ required: true }]}>
                            <DatePicker
                                disabledDate={(date: Dayjs) => date.startOf('day').isBefore(deadLine)}
                                className="wWide"
                                format={DATE_FORMAT}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Button type="primary" htmlType="submit">
                                Створити
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default CreateForm;
