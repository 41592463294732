export enum SESSION_HISTORY_FIELDS {
    date = 'Дата проведення',
    comment = 'Коментар',
}

export enum SESSION_INTERVAL_TRANSLATE {
    one_time_a_day = 'денних сесій',
    one_time_a_week = 'тижневих сесій',
    one_time_per_month = 'місячних сесій',
    one_time_per_quarter = 'квартальних сесій',
}

export enum HISTORY_STATUS {
    update = 'update',
    create = 'create',
    remove = 'remove',
};
