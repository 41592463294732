import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Row, Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment/moment';
import { QueryOptions } from 'odata-query';
import { LeftOutlined } from '@ant-design/icons';
import HistoryRow from '../../../components/common/HistoryRow';
import { ACTION_TYPE_UA } from '../../../constants';
import { DATE_FORMAT, TIME_WITH_SECONDS_FORMAT } from '../../../constants/Alert';
import { defaultHistoryQuery, statusFilter } from '../../../constants/History';
import { userHistoryKeyDescription } from '../../../constants/User';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { getHistoryFilter } from '../../../helpers/getHistoryFilters';
import getTopSkip from '../../../helpers/getTopSkip';
import { activeUserCheck } from '../../../helpers/isUserActive';
import { useEscape } from '../../../hooks';
import { RolesHistoryModel, UserHistoryModel, UserModel, ValueHistoryModel } from '../../../models/UserModel';
import { useGetUserHistoryQuery } from '../../../redux/api/userApi';

interface UserHistoryProps {
    user: UserModel;
    closePage: () => void;
}

enum UserRoleTranslation {
    admin = 'Адміністратор',
    analyst = 'Аналітик',
    controller = 'Контролер',
    controller_1st = 'Контролер 1 рангу',
    controller_2nd = 'Контролер 2 рангу',
}

const historyValue = (value: ValueHistoryModel) => {
    if (value) {
        return Object.keys(value).map((key, index) => {
            switch (key) {
                case 'lockedDate':
                case 'locked_date':
                    return <HistoryRow value={activeUserCheck(value.lockedDate || value.locked_date)} key={index} />;
                case 'roles':
                    return (
                        <HistoryRow
                            value={value.roles
                                ?.map((role: RolesHistoryModel, i: number) => {
                                    const description =
                                        UserRoleTranslation[role.name as keyof typeof UserRoleTranslation] ?? role.name;
                                    return i === 0 ? description : ' ' + description.toLowerCase();
                                })
                                .toString()}
                            key={index}
                        />
                    );
                case 'role':
                    return (
                        <HistoryRow
                            value={UserRoleTranslation[value.role as keyof typeof UserRoleTranslation]}
                            key={index}
                        />
                    );
                default:
                    return (
                        <HistoryRow value={typeof value[key] !== 'object' ? value[key] : 'Дані відсутні'} key={index} />
                    );
            }
        });
    }
    return <div>Дані відсутні</div>;
};

const UserHistory: FC<UserHistoryProps> = ({ user, closePage }) => {
    useEscape(closePage);
    const dateInputRef = useRef(null);
    const authorInputRef = useRef(null);
    const defaultFilter = { entryId: user.id };

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });
    const [filter, setFilter] = useState<QueryOptions<UserHistoryModel>['filter']>(defaultFilter);

    const queryData = useMemo<Partial<QueryOptions<UserHistoryModel>>>(
        () => ({
            ...defaultHistoryQuery,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: filter,
        }),
        [filter, pagination],
    );

    const { data: userHistory, isLoading } = useGetUserHistoryQuery({ id: user.id, queryData });

    const count = userHistory?.count;

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: count }));
    }, [count]);

    const handleTableChange: TableProps<UserHistoryModel>['onChange'] = (newPagination, filters) => {
        setFilter([defaultFilter, ...getHistoryFilter(filters)]);
        setPagination(newPagination);
    };

    const parsedHistory = useMemo(
        () =>
            userHistory?.items.map((history) => ({
                ...history,
                newValue: JSON.parse(history.newValue as string),
                prevValue: JSON.parse(history.prevValue as string),
            })),
        [userHistory],
    );

    const column: ColumnsType<UserHistoryModel> = [
        {
            title: 'Тип',
            dataIndex: 'action',
            key: 'action',
            filters: statusFilter,
            render: (value) => ACTION_TYPE_UA[value as keyof typeof ACTION_TYPE_UA],
            width: 70,
            ellipsis: true,
        },
        {
            title: 'Дата',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (value) => moment(value).format(DATE_FORMAT),
            ...getColumnSearchProps<UserHistoryModel>(dateInputRef, 'date'),
            width: 85,
            ellipsis: true,
        },
        {
            title: 'Час',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (value) => moment(value).format(TIME_WITH_SECONDS_FORMAT),
            width: 70,
            ellipsis: true,
        },
        {
            title: 'Автор',
            dataIndex: 'userId',
            key: 'user',
            render: (value) =>
                `${value?.lastName ?? ''} ${value?.firstName ? value.firstName[0].toUpperCase() + '.' : ''}`,
            ...getColumnSearchProps<UserHistoryModel>(authorInputRef, 'fio'),
            width: 125,
            ellipsis: true,
        },
        {
            title: 'Параметри',
            dataIndex: 'newValue',
            key: 'params',
            render: (value) =>
                Object.keys(value).map((key, index) => (
                    <HistoryRow value={userHistoryKeyDescription[key] || key} key={index} />
                )),
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Значення "До"',
            dataIndex: 'prevValue',
            key: 'prevValue',
            render: (value: ValueHistoryModel) => historyValue(value),
            width: 250,
            ellipsis: true,
        },
        {
            title: 'Значення "Після"',
            dataIndex: 'newValue',
            key: 'newValue',
            render: (value: ValueHistoryModel) => historyValue(value),
            width: 250,
            ellipsis: true,
        },
    ];

    return (
        <>
            <Row>
                <div onClick={closePage} className={'user-history__title'}>
                    <LeftOutlined />
                    Історія користувача "{user?.lastName} {user?.firstName}"
                </div>
            </Row>
            <Table<UserHistoryModel>
                scroll={{ y: '61vh' }}
                size="small"
                rowKey="id"
                bordered
                loading={isLoading}
                columns={column}
                dataSource={parsedHistory}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </>
    );
};

export default UserHistory;
