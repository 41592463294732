import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import uk_UA from 'antd/locale/uk_UA';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { mainTheme } from './constants/InterfaceThemes';
import './index.css';
import { persistor, store } from './redux/store';

dayjs.locale('uk');
dayjs.extend(relativeTime);
dayjs.extend(weekOfYear);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <ConfigProvider theme={mainTheme} locale={uk_UA}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </ConfigProvider>
    </React.StrictMode>,
);
