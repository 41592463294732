import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from '../../models/UserModel';
import { RootState } from '../store';

export interface IUserState {
    user: UserModel | null;
    accessToken?: string;
    refreshToken?: string;
    changePasswordData?: {
        isTemporaryPass: true;
        email: string;
    };
    apiVersion?: string;
}

const initialState: IUserState = {
    user: null,
    accessToken: undefined,
    refreshToken: undefined,
};

export const authSlice = createSlice({
    initialState,
    name: 'authSlice',
    reducers: {
        logout: () => initialState,
        setAuthData: (
            _state,
            action: PayloadAction<{ user: UserModel; accessToken?: string; refreshToken?: string, apiVersion?: string }>,
        ) => action.payload,
        setChangePasswordData: (state, action: PayloadAction<string>) => ({
            ...state,
            changePasswordData: { isTemporaryPass: true, email: action.payload },
        }),
    },
});

export default authSlice.reducer;

export const { logout, setAuthData, setChangePasswordData } = authSlice.actions;

export const getUser = (state: RootState) => state.auth.user;
export const getToken = (state: RootState) => state.auth.accessToken;
export const getRefreshToken = (state: RootState) => state.auth.refreshToken;
export const getChangePasswordData = (state: RootState) => state.auth.changePasswordData;
export const getApiVersion = (state: RootState) => state.auth.apiVersion;
