import { HISTORY_STATUS } from './enums';

export const statusCatalog: { [key: string]: string } = {
    update: 'Змінено',
    create: 'Додано',
    remove: 'Видалено',
};

export const calendarHistoryStatusDesc: { [key: string]: string } = {
    [HISTORY_STATUS.update]: 'Перенесено',
    [HISTORY_STATUS.create]: 'Створення',
    [HISTORY_STATUS.remove]: 'Видалення',
};

export const rulesFields: { [key: string]: string } = {
    description: 'Назва',
    shortName: 'Коротка назва',
    expireInterval: 'Запобігання повторів (дублів)',
    interval: 'Періодичність виявлення',
    isActive: 'Прапор активності',
    riskLevel: 'Рівень ризику',
    score: 'Бали',
    type: 'type',
    urgencyType: 'Терміновість алерта',
    scoreStateEnterprise: 'Бали ДП',
};

export const statusFilter = [
    {
        text: statusCatalog.update,
        value: 'update',
    },
    {
        text: statusCatalog.create,
        value: 'create',
    },
    {
        text: statusCatalog.remove,
        value: 'remove',
    },
];

export const defaultHistoryQuery = {
    orderBy: 'createdDate desc',
    count: true,
};
