import { Col, Form, Input, Row, Select } from 'antd';
import { DISABLED_WHITESPACE } from '../../../constants/common';
import { ObjProperties, RuleParamModel } from '../../../models/RulesModel';

interface IProps {
    value?: object;
    paramIndex: number;
    param: RuleParamModel;
}
const ParameterObject = ({ value, paramIndex, param }: IProps) => {
    const fields = value ? Object.keys(value) : [];
    return (
        <Row gutter={[16, 16]}>
            {fields.map((field) => {
                const label = (param.properties as ObjProperties)?.[field]?.label
                    ? (param.properties as ObjProperties)?.[field]?.label + ':'
                    : '';

                return (
                    <Col key={field}>
                        <Row align={'middle'}>
                            <Col>{label}&nbsp;</Col>
                            <Col>
                                <Form.Item
                                    key={field}
                                    name={['params', paramIndex, 'value', field]}
                                    rules={[
                                        { required: true },
                                        { whitespace: true, message: DISABLED_WHITESPACE },
                                        {
                                            min: (param.properties as ObjProperties)?.[field]?.minLength,
                                            max: (param.properties as ObjProperties)?.[field]?.maxLength,
                                            // eslint-disable-next-line no-template-curly-in-string
                                            message: 'довжина має бути від ${min} до ${max} символів',
                                        },
                                    ]}>
                                    {(() => {
                                        if ((param.properties as ObjProperties)?.[field]?.type === 'number')
                                            return <Input type="number" style={{ width: 100 }} />;
                                        if ((param.properties as ObjProperties)?.[field]?.type === 'select')
                                            return (
                                                <Select
                                                    options={(param.properties as ObjProperties)?.[field]?.options}
                                                    style={{ width: 100 }}
                                                />
                                            );
                                        return <Input style={{ width: 100 }} />;
                                    })()}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                );
            })}
        </Row>
    );
};

export default ParameterObject;
