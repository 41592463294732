import React, { FC, ReactNode } from 'react';
import { Modal as ModalAntd, ModalProps as ModalPropsAntd } from 'antd';

interface ModalProps extends ModalPropsAntd {
    width?: number | string;
    children?: ReactNode;
}
const Modal: FC<ModalProps> = ({ children, width, ...rest }) => (
    <ModalAntd maskClosable={false} width={width || 600} footer={null} bodyStyle={{ paddingTop: '1rem' }} {...rest}>
        {children}
    </ModalAntd>
);

export default Modal;
