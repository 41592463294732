import buildQuery from 'odata-query';
import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import { GetHistoryRequest, GetHistoryResponse, GetRequest } from './models/baseType';
import { Schedule, ScheduleHistory } from '../../models/Schedule';

const apiTag = 'scheduler';

export const schedulesApi = createApi({
    reducerPath: apiTag + 'Api',
    baseQuery: customBaseQuery,
    tagTypes: ['Schedules', 'ScheduleHistory'],
    endpoints: (builder) => ({
        getSchedules: builder.query<Schedule[], GetRequest<Schedule>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `${apiTag + queryString}`,
                    method: 'GET',
                };
            },
            providesTags: ['Schedules'],
        }),
        switchSchedule: builder.mutation<void, { type: string; id: number }>({
            query({ type, id }) {
                return {
                    url: `${apiTag}/${id}/${type}`,
                    method: 'PUT',
                };
            },
            invalidatesTags: ['Schedules', 'ScheduleHistory'],
        }),
        runOnce: builder.mutation<void, number>({
            query(id) {
                return {
                    url: `${apiTag}/${id}/run-once`,
                    method: 'PUT',
                };
            },
            invalidatesTags: ['Schedules', 'ScheduleHistory'],
        }),
        updateInterval: builder.mutation<void, { id: number; interval: string }>({
            query({ id, interval }) {
                return {
                    url: `${apiTag}/${id}`,
                    method: 'PUT',
                    body: { interval },
                };
            },
            invalidatesTags: ['Schedules', 'ScheduleHistory'],
        }),
        getScheduleHistory: builder.query<GetHistoryResponse<ScheduleHistory>, GetHistoryRequest<ScheduleHistory>>({
            query({ id, queryData }) {
                const queryString = buildQuery(queryData);
                return {
                    url: `${apiTag}/history/${id + queryString}`,
                    method: 'GET',
                };
            },
            providesTags: ['ScheduleHistory'],
        }),
    }),
});

export const {
    useGetSchedulesQuery,
    useSwitchScheduleMutation,
    useRunOnceMutation,
    useUpdateIntervalMutation,
    useGetScheduleHistoryQuery,
} = schedulesApi;
