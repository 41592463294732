import buildQuery, { QueryOptions } from 'odata-query';
import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import { addIndexToRecord } from '../../helpers/addIndexToRecord';
import { mutateNullToEmptyString } from '../../helpers/mutateObjectParams';
import { PriorityCatalog, StatusCatalog } from '../../models/Catalog';
import {
    DeleteQueryType,
    DictionaryHistory,
    DictionaryModel,
    FormValuesType,
    RecordModel,
} from '../../models/DictionaryModel';
import { RuleHistory, ruleVocabulary } from '../../models/RulesModel';
import { UserHistoryStatus } from '../../models/UserModel';

type GetHistoryResponse = {
    count: number;
    items: RuleHistory[];
};

type getDictionaryResponse = {
    count: number;
    items: RecordModel[];
};

type getDictionaryRequest = { dictionaryName: string; queryData?: Partial<QueryOptions<RecordModel>> };

export const dictionaryApi = createApi({
    reducerPath: 'dictionaryApi',
    baseQuery: customBaseQuery,
    tagTypes: ['Dictionary'],
    endpoints: (builder) => ({
        getDictionary: builder.query<getDictionaryResponse, getDictionaryRequest>({
            query({ dictionaryName, queryData }) {
                return {
                    url: `client/dictionary/${dictionaryName}${buildQuery(queryData)}`,
                    method: 'GET',
                };
            },
            transformResponse: (res: getDictionaryResponse, _meta, arg) => {
                if (Array.isArray(res)) {
                    return res;
                } else {
                    return {
                        ...res,
                        items: res?.items?.map((item, index) =>
                            addIndexToRecord(mutateNullToEmptyString(item), index, arg.queryData?.skip),
                        ),
                    };
                }
            },
            providesTags: ['Dictionary'],
        }),
        getDictionaries: builder.query<DictionaryModel[], void>({
            query() {
                return {
                    url: `client/dictionary`,
                    method: 'GET',
                };
            },
        }),
        updateRecordInDictionary: builder.mutation<DictionaryModel, FormValuesType>({
            query(data) {
                const { dict_name, dict_description, ...record } = data;
                return {
                    url: `client/dictionary/${dict_name}`,
                    method: 'PUT',
                    body: record,
                };
            },
            invalidatesTags: ['Dictionary'],
        }),
        addRecordToDictionary: builder.mutation<RecordModel, FormValuesType>({
            query(data) {
                const name = data.dict_name;
                const record = { ...data };
                delete record.dict_name;
                delete record.dict_description;
                return {
                    url: `client/dictionary/${name}`,
                    method: 'POST',
                    body: record,
                };
            },
            invalidatesTags: ['Dictionary'],
        }),
        deleteByIds: builder.mutation<RecordModel, DeleteQueryType>({
            query(data) {
                return {
                    url: `client/dictionary/${data.dict_name}`,
                    method: 'DELETE',
                    body: { ids: data.ids, cascade: data.cascade },
                };
            },
            invalidatesTags: (result, error) => (error ? [] : ['Dictionary']),
        }),
        getStatuses: builder.query<StatusCatalog[], void>({
            query() {
                return {
                    url: `client/dictionary/dict_alert_statuses`,
                    method: 'GET',
                };
            },
        }),
        getUserHistoryStatuses: builder.query<UserHistoryStatus[], void>({
            query() {
                return {
                    url: `client/dictionary/dict_user_statuses`,
                    method: 'GET',
                };
            },
        }),
        getPriorities: builder.query<PriorityCatalog[], void>({
            query() {
                return {
                    url: `client/dictionary/dict_urgency_types`,
                    method: 'GET',
                };
            },
        }),
        getRulesTypes: builder.query<ruleVocabulary[], void>({
            query() {
                return {
                    url: `client/dictionary/dict_rule_types`,
                    method: 'GET',
                };
            },
        }),
        getRuleRiskLevels: builder.query<ruleVocabulary[], void>({
            query() {
                return {
                    url: `client/dictionary/dict_rule_risk_levels`,
                    method: 'GET',
                };
            },
        }),
        getRuleInterval: builder.query<ruleVocabulary[], void>({
            query() {
                return {
                    url: `client/dictionary/dict_rule_interval`,
                    method: 'GET',
                };
            },
        }),
        getRuleUrgencyTypes: builder.query<ruleVocabulary[], void>({
            query() {
                return {
                    url: `client/dictionary/dict_rule_urgency_types`,
                    method: 'GET',
                };
            },
        }),
        getSystemDictionary: builder.query<RecordModel[], string>({
            query(dictionaryName) {
                return {
                    url: `client/dictionary/${dictionaryName}`,
                    method: 'GET',
                };
            },
            keepUnusedDataFor: 3600,
        }),
        getDictionaryHistory: builder.query<GetHistoryResponse, Partial<QueryOptions<DictionaryHistory>>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `client/dictionary/history${queryString}`,
                    method: 'GET',
                };
            },
            providesTags: ['Dictionary'],
        }),
    }),
});

export const {
    useGetStatusesQuery,
    useLazyGetStatusesQuery,
    useGetPrioritiesQuery,
    useLazyGetPrioritiesQuery,
    useGetDictionaryQuery,
    useGetDictionariesQuery,
    useGetUserHistoryStatusesQuery,
    useDeleteByIdsMutation,
    useUpdateRecordInDictionaryMutation,
    useAddRecordToDictionaryMutation,
    useGetRulesTypesQuery,
    useGetRuleRiskLevelsQuery,
    useGetRuleIntervalQuery,
    useGetRuleUrgencyTypesQuery,
    useGetSystemDictionaryQuery,
    useLazyGetSystemDictionaryQuery,
    useGetDictionaryHistoryQuery,
    useLazyGetRuleRiskLevelsQuery,
    useLazyGetRuleIntervalQuery,
    useLazyGetRuleUrgencyTypesQuery,
} = dictionaryApi;
