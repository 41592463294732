import React from 'react';
import { Col, Row, Tag, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/uk';
import { Draggable, IState as Selected } from './Calendar';
import Modal from '../../../components/common/Modal';
import CommentForm from '../../../components/form/calendar-form/CommentForm';
import { DATE_FORMAT_FOR_QUERY } from '../../../constants/Alert';
import { INTERVAL_CODE_DAY } from '../../../constants/Session';
import { getPeriodValue, getSessionColor } from '../../../helpers/calendar';
import { useModal } from '../../../hooks';
import { IntervalInfo, SessionCalendarIntervals } from '../../../models/Session';
import { ReqChangeIntervalDate } from '../../../redux/api/calendarApi';

interface IProps {
    value: Dayjs;
    days: SessionCalendarIntervals[];
    draggable: Draggable | null;
    setDraggable: (draggable: Draggable | null) => void;
    onSetDays: (days: SessionCalendarIntervals[]) => void;
    onClick: (data: Selected) => void;
    onChangeDate: (data: ReqChangeIntervalDate) => void;
}
const today = dayjs().locale('uk');
const todayString = today.format(DATE_FORMAT_FOR_QUERY);
const Cell = ({ value, days, draggable, setDraggable, onSetDays, onClick, onChangeDate }: IProps) => {
    const catcherDate = value.format(DATE_FORMAT_FOR_QUERY);
    const currentDay = days.find((item) => item.date === catcherDate);
    const disabledDate = value.isBefore(today, 'day');
    const canCatch =
        !disabledDate &&
        draggable &&
        currentDay &&
        (draggable?.interval.code as string) !== INTERVAL_CODE_DAY &&
        getPeriodValue(draggable?.date, draggable.interval.code as string) ===
            getPeriodValue(value, draggable.interval.code as string);

    const [isChangeIntervalDateModalOpen, openChangeIntervalDateModal, closeChangeIntervalDateModal] = useModal();

    const handleSumbit = (comment: string) => {
        if (draggable && currentDay) {
            onChangeDate({
                fromDate: draggable?.date.format(DATE_FORMAT_FOR_QUERY),
                toDate: catcherDate,
                interval: draggable?.interval.id,
                comment,
            });
            onSetDays([
                ...(days?.filter((day) => day.date !== catcherDate) || []),
                {
                    ...currentDay,
                    intervals: [
                        ...currentDay.intervals,
                        {
                            interval: draggable.interval,
                            id: draggable.interval.id,
                        } as IntervalInfo,
                    ],
                },
            ]);
            setDraggable(null);
        }
    };

    return (
        <div
            className="calendar__day"
            key={catcherDate}
            onClick={(e) => e.stopPropagation()}
            onDrop={() => {
                if (catcherDate !== draggable?.date.format(DATE_FORMAT_FOR_QUERY)) {
                    openChangeIntervalDateModal();
                }
            }}
            onDragOver={canCatch ? (e) => e.preventDefault() : undefined}>
            <Modal
                open={isChangeIntervalDateModalOpen}
                title={`Перенос ${String(draggable?.interval.name).toLowerCase()} з ${draggable?.date.format(
                    'DD.MM.YYYY',
                )} на ${value.format('DD.MM.YYYY')}`}
                onCancel={closeChangeIntervalDateModal}
                destroyOnClose>
                <CommentForm onSubmit={handleSumbit} closeModal={closeChangeIntervalDateModal} />
            </Modal>
            <Row justify="end" className="marginBottom1">
                <Col>
                    <div
                        className="calendar__date"
                        style={
                            catcherDate === todayString ? { backgroundColor: '#445271', color: 'white' } : undefined
                        }>
                        {value.format('DD')}
                    </div>
                </Col>
            </Row>
            <Row gutter={8}>
                {currentDay?.intervals.map((item, index, array) => (
                    <Col
                        span={array.length === 1 ? 24 : index === array.length - 1 ? (array.length % 2 ? 24 : 12) : 12}
                        key={item.id}
                        onClick={() => onClick({ id: currentDay.id, intervalId: item.interval.id })}>
                        <Tooltip color="grey" title={item?.interval.name} arrow={false} placement={'bottomLeft'}>
                            <Tag
                                className="calendar__tag"
                                onDragStart={() => setDraggable({ date: value, interval: item.interval })}
                                draggable={!disabledDate}
                                color={getSessionColor(disabledDate ? undefined : (item.interval.code as string))}>
                                {item.interval.name}
                            </Tag>
                        </Tooltip>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Cell;
