import { RecordModel } from '../models/DictionaryModel';

export const mutateEmptyStringToNull = (target: { [key: string]: any }) => {
    Object.keys(target).forEach((k) => (target[k] = target[k] === '' ? null : target[k]));
    return target;
};

export const mutateNullToEmptyString = (target: RecordModel) => {
    Object.keys(target).forEach((k) => (target[k] = target[k] === null ? '' : target[k]));
    return target;
};
