import { useEffect, useState } from 'react';

/**
 * Debounce any value
 * @template T - generic
 * @param {T} value - value to be debounced
 * @param {number} delay - number of milliseconds to delay value (1000 by default)
 * @returns {T} debounced value after delay
 */

export const useDebounce = <T>(value: T, delay: number = 1000): T => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [value, delay]);

    return debouncedValue;
};
