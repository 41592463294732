import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Table, Drawer, Tag, Row, Col, Spin, TablePaginationConfig, TableProps, notification } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import moment from 'moment';
import { QueryOptions } from 'odata-query';
import History from './components/History';
import StatusActionsControls from './components/StatusActionsControls';
import Card from './components/card/Card';
import Modal from '../../components/common/Modal';
import ResizableColumn from '../../components/common/ResizebleColumn';
import AgreeForm from '../../components/form/alert-form/AgreeForm';
import AmendmentForm from '../../components/form/alert-form/Amendment/AmendmentForm';
import AssignControllerForm from '../../components/form/alert-form/AssignControllerForm';
import AssignForm from '../../components/form/alert-form/AssignForm';
import CloseForm from '../../components/form/alert-form/CloseForm';
import DeadlineForm from '../../components/form/alert-form/DeadlineForm';
import PriorityForm from '../../components/form/alert-form/PriorityForm';
import ReportModal from '../../components/modal/ReportModal';
import {
    ALLOWED_CODES_FOR_ASSIGN,
    ALLOWED_CODES_FOR_AGREED,
    ALLOWED_CODES_FOR_PRIORITY,
    ALLOWED_CODES_FOR_PROCESS,
    ALLOWED_CODES_FOR_PROCESSED,
    DATE_FORMAT,
    STATUS_CODE_PROCESSED,
    STATUS_CODE_PROCESS,
    ALLOWED_CODES_FOR_AMENDMENT,
    ALLOWED_CODES_FOR_CLOSED,
    ALLOWED_CODES_FOR_DEADLINE,
    CUSTOMIZED_STATUS_CODES,
    STATUS_CODE_CLOSED,
    SESSION_STORAGE_KEY,
    CUSTOMER_TYPES_FILTER,
    ALERT_PARAMETER,
    ALLOWED_CODES_FOR_ASSIGN_CONTROLLER,
} from '../../constants/Alert';
import { colorPrimary } from '../../constants/InterfaceThemes';
import { RULES_TYPES, simpleRulesQuery } from '../../constants/Rules';
import { BASE_NOTIFICATION_CONFIG } from '../../constants/common';
import { ReportTypes, STATUS_CODES, STATUS_CODES_TRANSLATION } from '../../constants/enums';
import {
    checkUpdateStatusResponse,
    hasAllowedController,
    getAbbr,
    processAlertsQuery,
    getColumnSearchProps,
    getTopSkip,
    getUniqueUserByRole,
    getUserName,
    reportFetch,
    localeCompareSort,
    getPriorityColor,
    getStatusColor,
} from '../../helpers';
import { useModal } from '../../hooks';
import { BaseAlert, AlertConclusion } from '../../models/Alert';
import { PriorityCatalog, StatusCatalog } from '../../models/Catalog';
import { RuleModel } from '../../models/RulesModel';
import { EmployeeModel } from '../../models/UserModel';
import { useGetAlertsQuery, useUpdateStatusMutation } from '../../redux/api/alertsApi';
import { useLazyGetPrioritiesQuery, useLazyGetStatusesQuery } from '../../redux/api/dictionaryApi';
import { useLazyGetRolesQuery } from '../../redux/api/rolesApi';
import { useLazyGetRulesQuery } from '../../redux/api/rulesApi';
import { getToken, getUser } from '../../redux/features/authSlice';
import { useAppSelector } from '../../redux/hooks';

const hasAllowedCode = (alerts: BaseAlert[], codes: string[]) =>
    alerts.length
        ? alerts.every((item) => (item.status?.code ? codes.includes(item.status.code as string) : false))
        : false;

const linkStyle = { cursor: 'pointer', color: '#699ec0' };

const defaultOrder: SorterResult<BaseAlert> = {
    columnKey: 'id',
    order: 'descend',
};
const actualStatuses = [
    STATUS_CODES.new,
    STATUS_CODES.assigned,
    STATUS_CODES.process,
    STATUS_CODES.processed,
    STATUS_CODES.agreed,
    STATUS_CODES.amendment,
];
export const defaultFilter: Record<string, FilterValue | null> = {
    status: actualStatuses,
};

function getRuleFilterOption(rules: RuleModel[] | undefined, ruleType: string) {
    return (
        rules
            ?.filter((rule) => rule.type.code === ruleType)
            .map((rule) => ({
                text: rule.code,
                value: rule.code,
            }))
            .sort((a, b) => localeCompareSort(a, b, 'text')) || []
    );
}

const getRelevantStatusFilter = (isRelevant: boolean, prevStatus: any[]) => {
    if (!prevStatus || !prevStatus.length || prevStatus === actualStatuses) {
        return isRelevant ? actualStatuses : null;
    }
    if (prevStatus.length === 1 && prevStatus[0] === STATUS_CODES.closed) {
        return isRelevant ? actualStatuses : prevStatus;
    }
    return isRelevant
        ? prevStatus.filter((item: any) => item !== STATUS_CODES.closed)
        : [...prevStatus, STATUS_CODES.closed];
};

const Alerts = () => {
    const token = useAppSelector(getToken);
    const user = useAppSelector(getUser);
    const inputRef = useRef(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [selected, setSelected] = useState<BaseAlert['id']>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<BaseAlert['id']>>([]);
    const [showRelevant, setShowRelevant] = useState(true);

    const [assingControllerModal, openAssignControllerModal, closeAssignControllerModal] = useModal();
    const [assignModal, openAssignModal, closeAssignModal] = useModal();
    const [finishModal, openFinishModal, closeFinishModal] = useModal();
    const [approveModal, openApproveModal, closeApproveModal] = useModal();
    const [amendmentModal, openAmendmentModal, closeAmendmentModal] = useModal();
    const [priorityModal, openPriorityModal, closePriorityModal] = useModal();
    const [deadlineModal, openDeadlineModal, closeDeadlineModal] = useModal();
    const [historyModal, openHistoryModal, closeHistoryModal] = useModal();
    const [alertRegisterModal, openAlertRegisterModal, closeAlertRegisterModal] = useModal();
    const [businessRelationshipsModal, openBusinessRelationshipsModal, closeBusinessRelationshipsModal] = useModal();

    const sizes = useMemo(() => {
        const tableSizeJson = window.localStorage.getItem('alertTableSize');
        return tableSizeJson ? JSON.parse(tableSizeJson) : null;
    }, []);
    const resetAlert = () => setSelected(undefined);

    const [getRoles, { data: roles }] = useLazyGetRolesQuery();
    const [getRules, { data: rules }] = useLazyGetRulesQuery();
    const [getStatuses, { data: statuses }] = useLazyGetStatusesQuery();
    const [getPriorities, { data: priorities }] = useLazyGetPrioritiesQuery();
    const columns = function (
        filters: Record<string, FilterValue | null>,
        order: SorterResult<BaseAlert>,
        priorities?: PriorityCatalog[],
        statuses?: StatusCatalog[],
    ): ColumnsType<BaseAlert> {
        return [
            {
                title: (
                    <ResizableColumn colIndex={1} storageKey={SESSION_STORAGE_KEY}>
                        Ознака терміновості
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.urgency,
                render: (value: PriorityCatalog) => (
                    <Tag className="alert-priority" color={getPriorityColor(value?.code)}>
                        {value.description}
                    </Tag>
                ),
                key: ALERT_PARAMETER.urgencyCode.join('/'),
                onFilterDropdownOpenChange: (visible) => visible && !priorities && getPriorities(),
                filters: priorities?.map((item) => ({ text: item.description, value: item.code })) || [],
                onFilter: (value, record) => record.urgency?.code === value,
                width: sizes?.[1] || 120,
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.urgencyCode.join('/')] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.urgencyCode.join('/') ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={2} storageKey={SESSION_STORAGE_KEY}>
                        Статус
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.status,
                render: (value: StatusCatalog) => (
                    <div className="alert-status">
                        <Tag
                            className={`alert-status__tag ${
                                !CUSTOMIZED_STATUS_CODES.includes(value?.code) ? 'alert-status__tag_standard' : ''
                            } ${value?.code === STATUS_CODE_CLOSED ? 'alert-status__tag_closed' : ''}`}
                            color={getStatusColor(value?.code)}>
                            {value.description}
                        </Tag>
                    </div>
                ),
                key: ALERT_PARAMETER.statusCode.join('/'),
                filters: statuses?.map((item) => ({ text: item.description, value: item.code })) || [],
                onFilterDropdownOpenChange: (visible) => visible && !statuses && getStatuses(),
                width: sizes?.[2] || 90,
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.statusCode.join('/')] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.statusCode.join('/') ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={3} storageKey={SESSION_STORAGE_KEY}>
                        Номер alert
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.id,
                render: (value) => (
                    <span style={linkStyle} onClick={() => setSelected(value)}>
                        {value}
                    </span>
                ),
                key: ALERT_PARAMETER.id,
                ...getColumnSearchProps<BaseAlert>(inputRef, 'string'),
                width: sizes?.[3] || 110,
                defaultSortOrder: 'descend',
                sorter: true,
                ellipsis: true,
                filteredValue: filters[ALERT_PARAMETER.id] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.id ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={4} storageKey={SESSION_STORAGE_KEY}>
                        Код критерія ризику
                    </ResizableColumn>
                ),
                onFilterDropdownOpenChange: (visible) => visible && !rules && getRules(simpleRulesQuery),
                filters: getRuleFilterOption(rules, RULES_TYPES.criterion),
                filterSearch: true,
                dataIndex: ALERT_PARAMETER.criterionCodes,
                key: ALERT_PARAMETER.criterionCodes,
                width: sizes?.[4] || 82,
                ellipsis: true,
                // NOTE: сортування по коду правил працює не зовсім коректно, бо і критерії і індикатори мають однакову колонку rules/code
                // sorter: true,
                filteredValue: filters[ALERT_PARAMETER.criterionCodes] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.criterionCodes ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={5} storageKey={SESSION_STORAGE_KEY}>
                        Номер індикатора підозрілості
                    </ResizableColumn>
                ),
                onFilterDropdownOpenChange: (visible) => visible && !rules && getRules(simpleRulesQuery),
                filters: getRuleFilterOption(rules, RULES_TYPES.indicator),
                filterSearch: true,
                dataIndex: ALERT_PARAMETER.indicatorCodes,
                key: ALERT_PARAMETER.indicatorCodes,
                width: sizes?.[5] || 82,
                ellipsis: true,
                // NOTE: сортування по коду правил працює не зовсім коректно, бо і критерії і індикатори мають однакову колонку rules/code
                // sorter: true,
                filteredValue: filters[ALERT_PARAMETER.indicatorCodes] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.indicatorCodes ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={6} storageKey={SESSION_STORAGE_KEY}>
                        Дата alert
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.createdDate,
                render: (value: string) => (value ? moment(value).format(DATE_FORMAT) : ''),
                key: ALERT_PARAMETER.createdDate,
                ...getColumnSearchProps<BaseAlert>(inputRef, 'date'),
                width: sizes?.[6] || 80,
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.createdDate] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.createdDate ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={7} storageKey={SESSION_STORAGE_KEY}>
                        Номер сесії
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.sessionCode,
                key: ALERT_PARAMETER.sessionCode.join('/'),
                ...getColumnSearchProps<BaseAlert>(inputRef, 'string'),
                width: sizes?.[7] || 100,
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.sessionCode.join('/')] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.sessionCode.join('/') ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={8} storageKey={SESSION_STORAGE_KEY}>
                        Дата виконання
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.executionDate,
                render: (value: string) => (value ? moment(value).format(DATE_FORMAT) : ''),
                key: ALERT_PARAMETER.executionDate,
                ...getColumnSearchProps<BaseAlert>(inputRef, 'date'),
                width: sizes?.[8] || 80,
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.executionDate] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.executionDate ? order.order : null,
            },

            {
                title: (
                    <ResizableColumn colIndex={9} storageKey={SESSION_STORAGE_KEY}>
                        Тип клієнта
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.customerTypeName,
                render: (value) => getAbbr(value),
                key: ALERT_PARAMETER.customerTypeName.join('/'),
                filters: CUSTOMER_TYPES_FILTER,
                width: sizes?.[9] || 60,
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.customerTypeName.join('/')] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.customerTypeName.join('/') ? order.order : null,
            },

            {
                title: (
                    <ResizableColumn colIndex={10} storageKey={SESSION_STORAGE_KEY}>
                        Найменування / ПІБ
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.customerNationalName,
                key: ALERT_PARAMETER.customerNationalName.join('/'),
                ...getColumnSearchProps<BaseAlert>(inputRef, 'string'),
                width: sizes?.[10] || 120,
                render: (value, item) => (
                    <span style={linkStyle} onClick={() => setSelected(item.id)}>
                        {value}
                    </span>
                ),
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.customerNationalName.join('/')] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.customerNationalName.join('/') ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={11} storageKey={SESSION_STORAGE_KEY}>
                        ЄДРПОУ / РНОКПП
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.customerCode,
                key: ALERT_PARAMETER.customerCode.join('/'),
                ...getColumnSearchProps<BaseAlert>(inputRef, 'string'),
                width: sizes?.[11] || 92,
                render: (value, item) => (
                    <span style={linkStyle} onClick={() => setSelected(item.id)}>
                        {value}
                    </span>
                ),
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.customerCode.join('/')] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.customerCode.join('/') ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={12} storageKey={SESSION_STORAGE_KEY}>
                        РНК
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.customerId,
                key: ALERT_PARAMETER.customerId.join('/'),
                ...getColumnSearchProps<BaseAlert>(inputRef, 'string'),
                width: sizes?.[12] || 92,
                render: (value, item) => (
                    <span style={linkStyle} onClick={() => setSelected(item.id)}>
                        {value}
                    </span>
                ),
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.customerId.join('/')] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.customerId.join('/') ? order.order : null,
            },
            {
                // NOTE: можливо bankCode треба брати не з customer, а з account`s
                title: (
                    <ResizableColumn colIndex={13} storageKey={SESSION_STORAGE_KEY}>
                        МФО РУ
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.customerBankCode,
                key: ALERT_PARAMETER.customerBankCode.join('/'),
                ...getColumnSearchProps<BaseAlert>(inputRef, 'string'),
                width: sizes?.[13] || 60,
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.customerBankCode.join('/')] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.customerBankCode.join('/') ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={14} storageKey={SESSION_STORAGE_KEY}>
                        Назва РУ
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.customerBranchNameDescription,
                key: ALERT_PARAMETER.branchNameDescription.join('/'),
                ...getColumnSearchProps<BaseAlert>(inputRef, 'string'),
                width: sizes?.[14] || 82,
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.branchNameDescription.join('/')] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.branchNameDescription.join('/') ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={15} storageKey={SESSION_STORAGE_KEY}>
                        Дата отримання клієнтом запиту Банку
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.requestReceivedDate,
                key: ALERT_PARAMETER.requestReceivedDate,
                render: (value: string) => (value ? moment(value).format(DATE_FORMAT) : ''),
                ...getColumnSearchProps(inputRef, 'date'),
                width: sizes?.[15] || 82,
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.requestReceivedDate] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.requestReceivedDate ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={16} storageKey={SESSION_STORAGE_KEY}>
                        ПІБ Аналітика
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.analyst,
                render: (value: EmployeeModel) => (value ? getUserName(value) : null),
                key: ALERT_PARAMETER.analystId.join('/'),
                width: sizes?.[16] || 120,
                onFilterDropdownOpenChange: (visible) => visible && !roles && getRoles(),
                filters: getUniqueUserByRole(roles, 'analyst'),
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.analystId.join('/')] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.analystId.join('/') ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={17} storageKey={SESSION_STORAGE_KEY}>
                        Рішення
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.conclusion,
                key: ALERT_PARAMETER.conclusionDescription.join('/'),
                ...getColumnSearchProps<BaseAlert>(inputRef, 'string'),
                render: (value: AlertConclusion[]) => value?.map((item) => item.description).join(', '),
                width: sizes?.[17] || 120,
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.conclusionDescription.join('/')] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.conclusionDescription.join('/') ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={18} storageKey={SESSION_STORAGE_KEY}>
                        Дата висновку
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.conclusionDate,
                render: (value: string) => (value ? moment(value).format(DATE_FORMAT) : ''),
                key: ALERT_PARAMETER.conclusionDate,
                ...getColumnSearchProps(inputRef, 'date'),
                width: sizes?.[18] || 82,
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.conclusionDate] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.conclusionDate ? order.order : null,
            },
            {
                title: (
                    <ResizableColumn colIndex={19} storageKey={SESSION_STORAGE_KEY}>
                        ПІБ Контролера
                    </ResizableColumn>
                ),
                dataIndex: ALERT_PARAMETER.controller,
                key: ALERT_PARAMETER.controllerId.join('/'),
                render: (value: EmployeeModel) => (value ? getUserName(value) : null),
                width: sizes?.[19] || 120,
                onFilterDropdownOpenChange: (visible) => visible && !roles && getRoles(),
                filters: getUniqueUserByRole(roles, ['controller_1st', 'controller_2nd']),
                ellipsis: true,
                sorter: true,
                filteredValue: filters[ALERT_PARAMETER.controllerId.join('/')] || null,
                sortOrder: order.columnKey === ALERT_PARAMETER.controllerId.join('/') ? order.order : null,
            },
        ];
    };

    const [updateStatus, { isLoading: isUpdatingStatus }] = useUpdateStatusMutation();
    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const onSelectChange: TableRowSelection<BaseAlert>['onChange'] = (newSelectedRowKeys) =>
        setSelectedRowKeys(newSelectedRowKeys as Array<BaseAlert['id']>);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const [order, setOrder] = useState<SorterResult<BaseAlert>>(defaultOrder);
    const [filters, setFilters] = useState<Record<string, FilterValue | null>>(defaultFilter);
    const queryData = useMemo<Partial<QueryOptions<BaseAlert>>>(() => {
        const { processedFilters, processedSorter } = processAlertsQuery(filters, order);
        return {
            orderBy: processedSorter,
            count: true,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: processedFilters,
        };
    }, [filters, order, pagination]);

    const {
        data: alerts,
        isLoading: isLoadingAlerts,
        isFetching: isFetchingAlerts,
        refetch: refetchAlerts,
    } = useGetAlertsQuery(queryData);

    const resetFilters = () => {
        setFilters(defaultFilter);
        setOrder(defaultOrder);
        setShowRelevant(true);
    };

    useEffect(() => {
        setFilters((prev: any) => ({ ...prev, status: getRelevantStatusFilter(showRelevant, prev.status) }));
    }, [showRelevant]);

    const handleAlertConclusionClick = useCallback(
        async (type: string) => {
            setIsLoadingReport(true);
            await reportFetch(
                ReportTypes.alertConclusion,
                [selectedRowKeys?.[0].toString(), type],
                null,
                null,
                null,
                token,
                user?.id,
            );
            setIsLoadingReport(false);
        },
        [selectedRowKeys, token, user?.id],
    );

    const handleProcess = () => {
        updateStatus({ statusCode: STATUS_CODE_PROCESS, alerts: selectedRowKeys })
            .unwrap()
            .then((res) => checkUpdateStatusResponse(res, STATUS_CODES_TRANSLATION[STATUS_CODE_PROCESS]))
            .catch((e) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: e.data.message }));
    };
    const handleProcessed = () => {
        updateStatus({ statusCode: STATUS_CODE_PROCESSED, alerts: selectedRowKeys })
            .unwrap()
            .then((res) => checkUpdateStatusResponse(res, STATUS_CODES_TRANSLATION[STATUS_CODE_PROCESSED]))
            .catch((e) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: e.data.message }));
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        fixed: true,
    };
    const selectedRows = alerts ? alerts.items.filter((model) => selectedRowKeys.includes(model.id)) : [];
    const selectedRowAnalyst = selectedRows.length === 1 ? selectedRows[0].analyst : undefined;
    const currentAlert = alerts?.items?.find((model) => model.id === selected);
    const isController2ndAllowed = !hasAllowedController(selectedRows, user);

    const handleTableChange: TableProps<BaseAlert>['onChange'] = (newPagination, filters, sorter) => {
        setShowRelevant(
            filters.status !== null ? (filters.status?.includes(STATUS_CODES.closed) ? false : true) : false,
        );
        setFilters(filters);
        setOrder(sorter as SorterResult<BaseAlert>);
        setPagination(newPagination);
    };

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: alerts?.count }));
    }, [alerts?.count]);

    const tableHeight = (wrapperRef.current as HTMLDivElement)?.offsetHeight;

    return (
        <>
            <Spin wrapperClassName="alertSpinner" spinning={isLoadingReport}>
                <Modal
                    onCancel={closeAssignControllerModal}
                    destroyOnClose
                    title={`Призначення відповідального контролера`}
                    open={assingControllerModal}>
                    <AssignControllerForm ids={selectedRowKeys} onSuccess={closeAssignControllerModal} />
                </Modal>
                <Modal
                    onCancel={closeAssignModal}
                    destroyOnClose
                    title={`Призначення відповідального аналітика`}
                    open={assignModal}>
                    <AssignForm ids={selectedRowKeys} onSuccess={closeAssignModal} />
                </Modal>
                <Modal onCancel={closeApproveModal} destroyOnClose title={`Підтвердження`} open={approveModal}>
                    <AgreeForm ids={selectedRowKeys} onSuccess={closeApproveModal} onCancel={closeApproveModal} />
                </Modal>
                <Modal
                    destroyOnClose
                    onCancel={closeAmendmentModal}
                    title={`Повернення на доопрацювання`}
                    open={amendmentModal}>
                    <AmendmentForm ids={selectedRowKeys} analyst={selectedRowAnalyst} onSuccess={closeAmendmentModal} />
                </Modal>
                <Modal onCancel={closeFinishModal} title={`Підтвердження`} destroyOnClose open={finishModal}>
                    <CloseForm ids={selectedRowKeys} onSuccess={closeFinishModal} onCancel={closeFinishModal} />
                </Modal>
                <Modal onCancel={closePriorityModal} title={`Зміна ознаки терміновості заявки`} open={priorityModal}>
                    <PriorityForm ids={selectedRowKeys} onSuccess={closePriorityModal} />
                </Modal>
                <Modal
                    onCancel={closeAlertRegisterModal}
                    destroyOnClose
                    title={`Друк реєстру алерт`}
                    open={alertRegisterModal}>
                    <ReportModal
                        onSuccess={closeAlertRegisterModal}
                        reportType={ReportTypes.alertRegister}
                        filters={filters}
                        defaultFilters={defaultFilter}
                        processedFilters={queryData.filter}
                    />
                </Modal>
                <Modal
                    onCancel={closeBusinessRelationshipsModal}
                    destroyOnClose
                    title={`Друк моніторингу ділових відносин`}
                    open={businessRelationshipsModal}>
                    <ReportModal
                        onSuccess={closeBusinessRelationshipsModal}
                        reportType={ReportTypes.businessRelationships}
                        filters={filters}
                        defaultFilters={defaultFilter}
                        processedFilters={queryData.filter}
                    />
                </Modal>
                <Modal
                    destroyOnClose
                    width="328px"
                    onCancel={closeDeadlineModal}
                    title={`Зміна терміну обробки заявки`}
                    open={deadlineModal}>
                    <DeadlineForm ids={selectedRowKeys} onSuccess={closeDeadlineModal} />
                </Modal>
                {selectedRowKeys.length === 1 && (
                    <Modal
                        width={1200}
                        destroyOnClose
                        onCancel={closeHistoryModal}
                        title={`Історія за алерт`}
                        open={historyModal}>
                        <History id={selectedRowKeys[0]} />
                    </Modal>
                )}
                <Drawer
                    className="alert-card"
                    onClose={resetAlert}
                    title={
                        <Row justify="space-between" align="middle">
                            <Col>
                                <span className="textEnd">{currentAlert?.clientName}</span>
                                <div>
                                    {currentAlert?.customer?.nationalName && (
                                        <span>Найменування / ПІБ: {currentAlert.customer.nationalName},&nbsp;</span>
                                    )}
                                    {currentAlert?.customer?.customerCode && (
                                        <span>ЄДРПОУ / РНОКПП: {currentAlert.customer.customerCode},&nbsp;</span>
                                    )}
                                    {currentAlert?.customer?.customerId && (
                                        <span>РНК: {currentAlert?.customer?.customerId}</span>
                                    )}
                                </div>
                            </Col>
                            <Col>Картка alert</Col>
                        </Row>
                    }
                    destroyOnClose
                    open={!!selected}
                    width="100%"
                    headerStyle={{ backgroundColor: colorPrimary }}>
                    {selected && <Card alertId={selected} roleParams={user?.rolesParams} />}
                </Drawer>
                <div className="alert__content">
                    {user?.rolesParams && (
                        <div className="marginBottom2">
                            <StatusActionsControls
                                roleParams={user.rolesParams}
                                assignControllerDisable={
                                    !hasAllowedCode(selectedRows, ALLOWED_CODES_FOR_ASSIGN_CONTROLLER) ||
                                    isController2ndAllowed
                                }
                                onAssignController={openAssignControllerModal}
                                assignDisable={
                                    !hasAllowedCode(selectedRows, ALLOWED_CODES_FOR_ASSIGN) || isController2ndAllowed
                                }
                                onAssign={openAssignModal}
                                approveDisable={
                                    !hasAllowedCode(selectedRows, ALLOWED_CODES_FOR_AGREED) || isController2ndAllowed
                                }
                                onApprove={openApproveModal}
                                amendmentDisabled={
                                    !hasAllowedCode(selectedRows, ALLOWED_CODES_FOR_AMENDMENT) || isController2ndAllowed
                                }
                                onAmendment={openAmendmentModal}
                                priorityDisabled={
                                    !hasAllowedCode(selectedRows, ALLOWED_CODES_FOR_PRIORITY) || isController2ndAllowed
                                }
                                onPriority={openPriorityModal}
                                deadlineDisabled={
                                    !hasAllowedCode(selectedRows, ALLOWED_CODES_FOR_DEADLINE) || isController2ndAllowed
                                }
                                onDeadline={openDeadlineModal}
                                processDisabled={!hasAllowedCode(selectedRows, ALLOWED_CODES_FOR_PROCESS)}
                                onProcess={handleProcess}
                                processedDisabled={!hasAllowedCode(selectedRows, ALLOWED_CODES_FOR_PROCESSED)}
                                onProcessed={handleProcessed}
                                finishDisabled={!hasAllowedCode(selectedRows, ALLOWED_CODES_FOR_CLOSED)}
                                onFinish={openFinishModal}
                                disabledHistory={!selectedRowKeys.length || selectedRowKeys.length > 1}
                                disableMultipleRow={!selectedRowKeys.length || selectedRowKeys.length > 1}
                                onOpenHistory={openHistoryModal}
                                relevant={showRelevant}
                                onChangeRelevant={() => setShowRelevant((prev) => !prev)}
                                handleAlertConclusionClick={handleAlertConclusionClick}
                                handleAlertRegisterClick={openAlertRegisterModal}
                                handleBusinessRelationshipsClick={openBusinessRelationshipsModal}
                                handleResetFiltersClick={resetFilters}
                                isFetchingAlerts={isFetchingAlerts}
                                refetchAlerts={refetchAlerts}
                            />
                        </div>
                    )}

                    <div className="alert__list" ref={wrapperRef}>
                        <Table<BaseAlert>
                            size="small"
                            scroll={{
                                x: 600,
                                y: tableHeight ? (wrapperRef.current as HTMLDivElement)?.offsetHeight - 106 : undefined,
                            }}
                            rowKey="id"
                            rowSelection={rowSelection}
                            bordered
                            loading={isLoadingAlerts || isFetchingAlerts || isUpdatingStatus}
                            columns={columns(filters, order, priorities, statuses)}
                            dataSource={alerts?.items}
                            pagination={pagination}
                            onChange={handleTableChange}
                            // onRow={rowCb}
                        />
                    </div>
                </div>
            </Spin>
        </>
    );
};

export default Alerts;
