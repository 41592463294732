import { FilterValue } from 'antd/es/table/interface';
import getContainFilterValue from './getContainFilterValue';
import dayjs from 'dayjs';
import {
    HISTORY_PARAMETER_ACTION,
    HISTORY_PARAMETER_CREATED_DATE,
    HISTORY_PARAMETER_USER_FIO,
} from '../models/History';

export const getHistoryFilter = (filters: Record<string, FilterValue | null>): any => {
    let fioFilter = {};
    let createdDateFilter = {};
    let actionFilter = {};
    for (const key in filters) {
        if (filters[key] !== null) {
            switch (key) {
                case HISTORY_PARAMETER_USER_FIO:
                    fioFilter = {
                        or: [
                            { 'tolower(user/firstName)': getContainFilterValue(filters.user?.[0] as string) },
                            { 'tolower(user/middleName)': getContainFilterValue(filters.user?.[0] as string) },
                            { 'tolower(user/lastName)': getContainFilterValue(filters.user?.[0] as string) },
                        ],
                    };
                    break;
                case HISTORY_PARAMETER_CREATED_DATE:
                    createdDateFilter = {
                        [key]: {
                            ge: {
                                type: 'raw',
                                value: dayjs(filters.createdDate?.[0] as string)
                                    .startOf('day')
                                    .toISOString(),
                            },
                            le: {
                                type: 'raw',
                                value: dayjs(filters.createdDate?.[0] as string)
                                    .endOf('day')
                                    .toISOString(),
                            },
                        },
                    };
                    break;
                case HISTORY_PARAMETER_ACTION:
                    actionFilter = { or: filters.action?.map((item) => ({ [key]: item })) };
                    break;
                default:
                    break;
            }
        }
    }
    return [actionFilter, fioFilter, createdDateFilter];
};
