import { useCallback, useMemo, useState } from 'react';
import { Spin, Table, TableProps } from 'antd';
import { Modal as ModalAntd } from 'antd';
import IntervalEditModal from './components/IntervalEditModal';
import SchedulersHistory from './components/SchedulersHistory';
import { getColumns } from './components/getColumns';
import { HandleClick } from './models/HandleClick';
import Modal from '../../components/common/Modal';
import { SCHEDULERS_TABLE } from '../../constants';
import { baseSuccessNotification } from '../../helpers/baseSuccessNotification';
import { useModal } from '../../hooks';
import { useHandleErrors } from '../../hooks/useHandleError';
import { Schedule } from '../../models/Schedule';
import { useGetSchedulesQuery, useSwitchScheduleMutation, useRunOnceMutation } from '../../redux/api/schedulesApi';

const Schedulers = () => {
    const [schedule, setSchedule] = useState<Schedule | null>(null);
    const [intervalEditModal, openIntervalEditModal, closeIntervalEditModal] = useModal();
    const [historyPage, openHistoryPage, closeHistoryPage] = useModal();

    const { data, error, isLoading } = useGetSchedulesQuery({ orderBy: 'id' });
    const [switchSchedule, { isLoading: isSwitching, error: isSwitchingError }] = useSwitchScheduleMutation();
    const [runOnce, { isLoading: isRunningOnce, error: isRunningOnceError }] = useRunOnceMutation();

    useHandleErrors(error, isSwitchingError, isRunningOnceError);

    const sizes = useMemo(() => {
        const tableSizeJson = window.localStorage.getItem(SCHEDULERS_TABLE);
        return tableSizeJson ? JSON.parse(tableSizeJson) : null;
    }, []);

    const handleHistoryClick: HandleClick = (id, e) => {
        e.stopPropagation();
        if (!data) return;
        const schedule = data.find((s) => s.id === id);
        if (!schedule) return;

        setSchedule(schedule);
        openHistoryPage();
    };

    const rowCb: TableProps<Schedule>['onRow'] = useCallback(
        (record: Schedule) => ({
            onClick: () => {
                setSchedule(record);
                openIntervalEditModal();
            },
        }),
        [openIntervalEditModal],
    );

    const handleRunOnceClick: HandleClick = useCallback(
        (id, e) => {
            e.stopPropagation();
            if (id) {
                ModalAntd.confirm({
                    title: 'Підтвердження',
                    content: `Ви впевнені, що хочете запустити цей шедулер?`,
                    onOk: () => runOnce(id).then(() => baseSuccessNotification('Запуск виконано успішно!')),
                    okText: 'Так',
                    cancelText: 'Повернутися',
                    style: { top: '20%' },
                });
            }
        },
        [runOnce],
    );

    const handleSwitchClick: HandleClick = useCallback(
        (id, e, type) => {
            e.stopPropagation();
            if (id && type) {
                ModalAntd.confirm({
                    title: 'Підтвердження',
                    content: `Ви впевнені, що хочете ${type === 'activate' ? 'ввімкнути' : 'вимкнути'} цей шедулер?`,
                    onOk: () => switchSchedule({ id, type }).then(() => baseSuccessNotification('Успішно змінено!')),
                    okText: 'Так',
                    cancelText: 'Повернутися',
                    style: { top: '20%' },
                });
            }
        },
        [switchSchedule],
    );

    return (
        <Spin spinning={isLoading || isSwitching || isRunningOnce}>
            {historyPage && schedule && (
                <SchedulersHistory schedule={schedule as Schedule} closePage={closeHistoryPage} />
            )}
            {!historyPage && (
                <>
                    <Modal
                        onCancel={closeIntervalEditModal}
                        destroyOnClose
                        title={`Редагування інтервалу "${schedule?.description}"`}
                        open={intervalEditModal}>
                        <IntervalEditModal schedule={schedule as Schedule} onSuccess={closeIntervalEditModal} />
                    </Modal>
                    <Table<Schedule>
                        rowKey="id"
                        size="small"
                        scroll={{ x: 600 }}
                        columns={getColumns(sizes, handleHistoryClick, handleRunOnceClick, handleSwitchClick)}
                        dataSource={data}
                        bordered
                        pagination={false}
                        onRow={rowCb}
                    />
                </>
            )}
        </Spin>
    );
};

export default Schedulers;
