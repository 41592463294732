import React, { FC } from 'react';

import { Button, Col, notification, Row, Spin } from 'antd';

import { STATUS_CODE_CLOSED } from '../../../constants/Alert';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { BaseAlert } from '../../../models/Alert';
import { useUpdateStatusMutation } from '../../../redux/api/alertsApi';

interface AgreeAlertProps {
    ids: Array<BaseAlert['id']>;
    onSuccess: () => void;
    onCancel: () => void;
}
const CloseForm: FC<AgreeAlertProps> = ({ ids, onSuccess, onCancel }) => {
    const [updateStatus, { isLoading }] = useUpdateStatusMutation();

    const submit = () => {
        updateStatus({ alerts: ids, statusCode: STATUS_CODE_CLOSED })
            .unwrap()
            .then(() => {
                notification.success({ ...BASE_NOTIFICATION_CONFIG, message: 'Статус успішно змінено на "Закрито"' });
                onSuccess();
            })
            .catch((error) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message }));
    };
    return (
        <Spin spinning={isLoading}>
            <div>Ви бажаєте виконати закриття?</div>
            <Row justify="end" gutter={16}>
                <Col>
                    <Button onClick={onCancel}>Ні</Button>
                </Col>
                <Col>
                    <Button type="primary" onClick={submit}>
                        Так
                    </Button>
                </Col>
            </Row>
        </Spin>
    );
};

export default CloseForm;
