import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { alertsApi } from '../redux/api/alertsApi';
import { authApi } from '../redux/api/authApi';
import { customerApi } from '../redux/api/customerApi';
import { dictionaryApi } from '../redux/api/dictionaryApi';
import { rolesApi } from '../redux/api/rolesApi';
import { rulesApi } from '../redux/api/rulesApi';
import { schedulesApi } from '../redux/api/schedulesApi';
import { systemApi } from '../redux/api/systemApi';
import { useGetProfileMutation, userApi } from '../redux/api/userApi';
import { getToken } from '../redux/features/authSlice';
import { useAppSelector } from '../redux/hooks';
import { store } from '../redux/store';

const PrivateRoute: FC<PropsWithChildren<{}>> = ({ children }) => {
    const token = useAppSelector(getToken);
    const [getProfile] = useGetProfileMutation();
    const location = useLocation();

    useEffect(() => {
        if (token) {
            getProfile();
        }

        return () => {
            store.dispatch(dictionaryApi.util.resetApiState());
            store.dispatch(userApi.util.resetApiState());
            store.dispatch(rolesApi.util.resetApiState());
            store.dispatch(authApi.util.resetApiState());
            store.dispatch(alertsApi.util.resetApiState());
            store.dispatch(rulesApi.util.resetApiState());
            store.dispatch(customerApi.util.resetApiState());
            store.dispatch(systemApi.util.resetApiState());
            store.dispatch(schedulesApi.util.resetApiState());
        };
        // need only on mount and unmount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!token) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login" state={{ from: location }} />;
    }

    // authorized so return child components
    return children as React.ReactElement;
};

export default PrivateRoute;
