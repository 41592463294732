import { FilterValue } from 'antd/es/table/interface';

import { UserFiltersModel } from '../../models/UserModel';
import getContainFilterValue from '../getContainFilterValue';

export const getUserFilters = (filters: Record<string, FilterValue | null>) => {
    const filter = [];
    if (filters.roles?.length) {
        filter.push({
            or: filters.roles.map((name) => ({
                roles: {
                    name,
                },
            })),
        });
    }
    if (filters.lockedDate?.length) {
        const status = {
            or: filters.lockedDate.map((item) => ({ lockedDate: item ? { ne: null } : { eq: null } })),
        };
        filter.push(status);
    }
    return filter;
};

export const getUserFiltersFromFormValues = (filters: string): UserFiltersModel => {
    if (!filters) return {};
    return {
        or: [
            { 'tolower(email)': getContainFilterValue(filters) },
            { 'tolower(firstName)': getContainFilterValue(filters) },
            { 'tolower(lastName)': getContainFilterValue(filters) },
            { 'tolower(middleName)': getContainFilterValue(filters) },
        ],
    };
};
