import { FilterValue } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { Filter } from 'odata-query';
import getContainFilterValue from './getContainFilterValue';

interface GetFilters {
    filter: Record<string, FilterValue | null>;
    stringContainsColumns?: string[];
    stringExistColumns?: string[];
    dateColumns?: string[];
    selectColumns?: string[];
    nameColumns?: string[];
}

export const getFilters = ({
    filter,
    stringContainsColumns,
    stringExistColumns,
    dateColumns,
    selectColumns,
    nameColumns,
}: GetFilters): Filter => {
    let stringContainFilter = {};
    let stringExistFilter = {};
    let dateFilter = {};
    let selectFilter = {};
    for (const key in filter) {
        if (filter[key] !== null) {
            if (dateColumns && dateColumns.includes(key)) {
                dateFilter = {
                    [key]: {
                        ge: {
                            type: 'raw',
                            value: dayjs(filter[key]?.[0] as string)
                                .startOf('day')
                                .toISOString(),
                        },
                        le: {
                            type: 'raw',
                            value: dayjs(filter[key]?.[0] as string)
                                .endOf('day')
                                .toISOString(),
                        },
                    },
                };
            }

            if (selectColumns && selectColumns.includes(key)) {
                selectFilter = {
                    or: filter.LOG_LEVEL?.map((item) => ({ LOG_LEVEL: { eq: item } })),
                };
            }

            if (stringContainsColumns && stringContainsColumns.includes(key)) {
                stringContainFilter = { ...stringContainFilter, [key]: { contains: filter[key]?.[0] } };
            }

            if (stringExistColumns && stringExistColumns.includes(key)) {
                stringExistFilter = { ...stringExistFilter, [key]: { eq: filter[key]?.[0] } };
            }

            if (nameColumns && nameColumns.includes(key)) {
                stringContainFilter = {
                    ...stringContainFilter,
                    [key]: {
                        or: [
                            { 'tolower(first_name)': getContainFilterValue(`${filter[key]?.[0]}`) },
                            { 'tolower(last_name)': getContainFilterValue(`${filter[key]?.[0]}`) },
                            { 'tolower(middle_name)': getContainFilterValue(`${filter[key]?.[0]}`) },
                        ],
                    },
                };
            }
        }
    }

    const stringFilter = { ...stringContainFilter, ...stringExistFilter };
    return [stringFilter, selectFilter, dateFilter];
};
