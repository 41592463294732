import { useState } from 'react';

type UseBoolean = (
    initialValue?: boolean,
) => [
    value: boolean,
    setTrue: () => void,
    setFalse: () => void,
    toggle: () => void,
    setValue: React.Dispatch<React.SetStateAction<boolean>>,
];

export const useBoolean: UseBoolean = (initialValue: boolean = false) => {
    const [value, setValue] = useState(initialValue);
    const setTrue = () => setValue(true);
    const setFalse = () => setValue(false);
    const toggle = () => setValue(!value);
    return [value, setTrue, setFalse, toggle, setValue];
};
