import { useEffect } from 'react';
import { notification } from 'antd';
import { BASE_NOTIFICATION_CONFIG } from '../constants/common';

export const baseErrorNotification = (error: any) => {
    if (error) {
        notification.error({
            ...BASE_NOTIFICATION_CONFIG,
            message: (error as any)?.data?.message || error.data || error.message || error.error || error,
        });
    }
};

export const useHandleError = (error: any) => useEffect(() => baseErrorNotification(error), [error]);

export const useHandleErrors = (...args: any[]) =>
    useEffect(() => args.forEach((error) => baseErrorNotification(error)), [args]);
