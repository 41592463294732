import dayjs from 'dayjs';
import { DATE_FORMAT, TIME_FORMAT } from '../../constants';
import { Transaction } from '../../models/Transaction';

export const transactionsDto = (transactions: Transaction[]): Record<string, any> => {
    const lineBreak = '\n';
    return transactions.map((transaction) => ({
        documentId: transaction.documentId,
        documentDate: transaction.documentDate ? dayjs(transaction.documentDate).format(DATE_FORMAT) : '',
        amount: transaction.amount,
        recipientCurrencyCode: transaction.recipientCurrencyCode,
        amountEquivalent: transaction.amountEquivalent,
        senderIban: transaction.senderIban,
        senderBankCode: transaction.senderBankCode,
        senderBankName: transaction.senderBankName,
        senderName: transaction.senderName,
        senderTaxCode: transaction.senderTaxCode,
        recipientIban: transaction.recipientIban,
        recipientBankCode: transaction.recipientBankCode,
        recipientBankName: transaction.recipientBankName,
        recipientName: transaction.recipientName,
        recipientTaxCode: transaction.recipientTaxCode,
        purpose: transaction.purpose,
        paymentDate: transaction.paymentDate ? dayjs(transaction.paymentDate).format(DATE_FORMAT) : '',
        paymentTime: transaction.paymentDate ? dayjs(transaction.paymentDate).format(TIME_FORMAT) : '',
        additionalRequsites: Object.entries(transaction.additionalRequsites || {})
            .map(([key, value]) => `${key}: ${value}`)
            .join(lineBreak),
    }));
};
