import { useCallback, useEffect } from 'react';

/**
 * Execute callback on escape key
 * @param {() => void} callback - callback to be executed
 */

export const useEscape = (callback: () => void) => {
    const escFunction = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                callback();
            }
        },
        [callback],
    );

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [escFunction]);
};
