import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../constants';
import { Account } from '../../models/Account';

export const accountsDto = (accounts: Account[]): Record<string, any> => {
    return accounts.map((account) => ({
        branch: account.branch,
        bankCode: account.bankCode,
        bankName: account.bankName,
        accountCode: account.accountIban,
        accountType: account.accountType,
        accountCurrencyId: account.accountCurrencyId,
        customerId: account.customerId,
        accountName: account.accountName,
        accountIban: account.accountIban,
        openDate: account.openDate ? dayjs(account.openDate).format(DATE_FORMAT) : '',
        accountBalance: account.accountBalance,
        turnDebit: account.turnDebit,
        turnCredit: account.turnCredit,
        actualBalanceFact: account.actualBalance,
        actualBalancePlan: account.actualBalance,
        closeDate: account.closeDate ? dayjs(account.closeDate).format(DATE_FORMAT) : '',
        lastTurnDate: account.lastTurnDate ? dayjs(account.lastTurnDate).format(DATE_FORMAT) : '',
        lockDebitCode: account.lockDebitCode,
        lockCreditCode: account.lockCreditCode,
        closeDateFact: account.closeDate ? 'Так' : 'Ні',
    }));
};
