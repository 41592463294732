import React, { FC } from 'react';
import { Button, Form, Input, Row, Space, Spin } from 'antd';
import { FormItemsWithInputType } from '../../../../../models/Common';
import { QueryType, usePutQuery } from '../../../hooks/useQuery';

interface EditableTableModalProps {
    formItemsProps?: FormItemsWithInputType[];
    isUpdate?: boolean;
    selectedRows?: any[];
    closeModal?: () => void;
    queryType?: QueryType;
    clearSelection?: () => void;
}

const formItems = (formItemsData?: FormItemsWithInputType[]) => {
    return formItemsData?.map((item) => (
        <Form.Item
            name={item.name}
            label={item.label}
            hidden={item.hidden}
            initialValue={item.initialValue}
            rules={[{ ...item.rules?.[0], required: item.required }]}>
            <Input disabled={item.disabled} type={item.type} />
        </Form.Item>
    ));
};

const EditableTableModal: FC<EditableTableModalProps> = ({
    formItemsProps,
    isUpdate,
    selectedRows,
    closeModal,
    queryType,
    clearSelection,
}) => {
    const [form] = Form.useForm();
    const { triggerPut, loading } = usePutQuery();

    if (isUpdate && selectedRows?.[0]) form.setFieldsValue(selectedRows?.[0]);

    const handleSubmit = (value: any) =>
        triggerPut(queryType, value, () => {
            closeModal && closeModal();
            clearSelection && clearSelection();
        });

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Spin spinning={loading}>
                {formItems(formItemsProps)}
                <Row justify="end">
                    <Space wrap>
                        <Button type="primary" onClick={closeModal}>
                            Відміна
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Зберегти
                        </Button>
                    </Space>
                </Row>
            </Spin>
        </Form>
    );
};

export default EditableTableModal;
