import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { RootState } from '../../store';

const DEFAULT_LANGUAGE = 'uk';

export const prepareAuthHeader: FetchBaseQueryArgs['prepareHeaders'] = (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;

    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('accept-language', DEFAULT_LANGUAGE);
    }

    return headers;
};

export const prepareRefreshHeaders: FetchBaseQueryArgs['prepareHeaders'] = (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;

    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('accept-language', DEFAULT_LANGUAGE);
        headers.set('content-type', 'application/json');
    }

    return headers;
};
