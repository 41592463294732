import React, { FC } from 'react';
import { Form, Input, Select } from 'antd';
import { useGetMenuFunctionsQuery } from '../../../redux/api/userApi';
const RoleDataFields: FC = () => {
    const { data: menuFunctions, isLoading: menuIsLoading, isFetching: menuIsFetching } = useGetMenuFunctionsQuery();

    return (
        <>
            <Form.Item name="name" rules={[{ required: true }]}>
                <Input placeholder="Назва" disabled />
            </Form.Item>
            <Form.Item name="description">
                <Input placeholder="Опис" />
            </Form.Item>
            <Form.Item name="menuFunctions">
                <Select
                    mode="multiple"
                    placeholder="Оберіть функції"
                    style={{ width: '100%' }}
                    fieldNames={{ value: 'id', label: 'name' }}
                    options={menuFunctions as any}
                    loading={menuIsLoading || menuIsFetching}
                />
            </Form.Item>
        </>
    );
};

export default RoleDataFields;
