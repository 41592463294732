import { FC } from 'react';

import { Button, Form, Row, Spin, DatePicker, notification } from 'antd';
import locale from 'antd/es/date-picker/locale/uk_UA';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import moment from 'moment';

import { DATE_FORMAT, DATE_FORMAT_FOR_QUERY } from '../../../constants/Alert';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { BaseAlert } from '../../../models/Alert';
import { useUpdateDeadlineMutation } from '../../../redux/api/alertsApi';

interface DeadlineFormProps {
    ids: Array<BaseAlert['id']>;
    onSuccess: () => void;
}

const disabledDate: RangePickerProps['disabledDate'] = (current) => current && current < dayjs().startOf('day');

const DeadlineForm: FC<DeadlineFormProps> = ({ ids, onSuccess }) => {
    const [form] = useForm();
    const [updatePriority, { isLoading }] = useUpdateDeadlineMutation();

    const handleSubmit = () => {
        const date = moment(form.getFieldValue('deadline').toString());
        updatePriority({
            ids,
            date: date.format(DATE_FORMAT_FOR_QUERY),
        })
            .unwrap()
            .then(() => {
                notification.success({
                    ...BASE_NOTIFICATION_CONFIG,
                    message: `Термін обробки успішно змінено на ${date.format(DATE_FORMAT)}`,
                });
                onSuccess();
                form.resetFields();
            })
            .catch((error) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message }));
    };

    return (
        <Spin spinning={isLoading}>
            <Form
                form={form}
                onFinish={handleSubmit}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}
                layout="vertical">
                <Form.Item name="deadline" label="Дата виконання" rules={[{ required: true }]}>
                    <DatePicker
                        style={{ width: '100%' }}
                        placeholder="Оберіть дату"
                        format={DATE_FORMAT}
                        locale={locale}
                        disabledDate={disabledDate}
                    />
                </Form.Item>
                <Row justify="end">
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Row>
            </Form>
        </Spin>
    );
};

export default DeadlineForm;
