import { ReactNode } from 'react';
import { FormInstance, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { getAbbr } from './getAbbr';
import { getDateView } from './getDateView';
import Skeleton from '../components/common/Skeleton';
import UserModal from '../components/modal/UserModal';
import {
    ALERT_PARAMETER,
    CUSTOMER_TYPES_INDIVIDUAL,
    DATE_FORMAT,
    INDIVIDUAL_TYPES,
    LEGAL_TYPES,
    RULES_TYPE_CRITERION,
    RULES_TYPE_INDICATOR,
} from '../constants';
import { AlertAdditionalInfo, BaseAlert, CustomerAddress, ExtendedAlert } from '../models/Alert';
import { RecordModel } from '../models/DictionaryModel';
import { UserModel } from '../models/UserModel';
import { Option } from '../pages/alerts/components/card/Card';

const approveOption = [
    { value: 1, label: 'Так' },
    { value: 0, label: 'Ні' },
];

const isDocumentsProvidedOptions = [
    { value: 1, label: 'Так' },
    { value: 0, label: 'Ні' },
    { value: 2, label: 'Частково' },
    { value: 3, label: 'Не запитувались' },
];

const numberOfIncomingLetterStyle = { width: '280px' };

const getLegalAddress = (addresses?: CustomerAddress[]) => {
    const data = addresses?.find((addr) => addr.addressType === 'Юридична');
    const result = [];
    if (data?.country) result.push(data?.country);
    if (data?.postCode) result.push(data?.postCode);
    if (data?.region) result.push(data?.region);
    if (data?.area) result.push(data?.area);
    if (data?.locality) result.push(`м. ${data?.locality}`);
    if (data?.addressText) result.push(`${data?.addressText}`);

    return result.length ? result.join(', ') : '';
};

const isExist = (value?: string | number | boolean | null) => (!!value ? 'Так' : 'Ні');
const isLegalType = (type?: string) => LEGAL_TYPES.includes(type || '');
const isIndividualType = (type?: string) => INDIVIDUAL_TYPES.includes(type || '');
export interface AlertTabRowData {
    key: string;
    fieldName?: string | string[];
    name: string;
    value?: string | number | boolean | null | Dayjs | number[] | string[] | ReactNode;
    editable?: boolean;
    required?: boolean;
    multiply?: boolean;
    type?: string;
    options?: any[];
    rows?: number; // for textArea
    style?: { [index: string]: string };
    hide?: boolean;
    disabledPastPeriod?: boolean;
    placeholder?: string;
    disabled?: boolean;
}

const getAdditionalInfo = (key: string, additionalInfo?: AlertAdditionalInfo, isAdditionalInfoLoading?: boolean) => {
    if (!additionalInfo) {
        return <Skeleton loading={isAdditionalInfoLoading} />;
    }

    switch (key) {
        case ALERT_PARAMETER.analysisPeriod:
            return `${getDateView(additionalInfo.analysisFromDate, true)} - ${getDateView(
                additionalInfo.analysisToDate,
                true,
            )}`;
        case 'firstCurrentAccountDate':
            return getDateView(additionalInfo.firstCurrentAccountDate, true);
        default:
            return '';
    }
};

export const getGeneralData = (
    alert: ExtendedAlert,
    additionalInfo?: AlertAdditionalInfo,
    isAdditionalInfoLoading?: boolean,
): AlertTabRowData[] => [
    {
        key: ALERT_PARAMETER.status,
        name: 'Статус',
        value: alert.status?.description,
        editable: false,
    },
    { key: ALERT_PARAMETER.id, name: 'Номер алерту', value: alert.id, editable: false },
    {
        key: ALERT_PARAMETER.createdDate,
        name: 'Дата генерації алерту',
        value: alert.createdDate && dayjs(alert.createdDate).format(DATE_FORMAT),
        editable: false,
    },
    {
        key: ALERT_PARAMETER.urgency,
        name: 'Терміновість опрацювання',
        value: alert.urgency?.description,
        editable: false,
    },
    {
        key: ALERT_PARAMETER.executionDate,
        name: 'Планова дата виконання',
        value: alert.executionDate && dayjs(alert.executionDate).format(DATE_FORMAT),
        editable: false,
    },
    {
        key: ALERT_PARAMETER.analysisPeriod,
        name: 'Аналізований період',
        value: getAdditionalInfo(ALERT_PARAMETER.analysisPeriod, additionalInfo, isAdditionalInfoLoading),
        editable: false,
    },
    {
        key: ALERT_PARAMETER.analyst,
        name: 'Аналітик',
        value: <UserModal user={alert.analyst} />,
        editable: false,
    },
    {
        key: ALERT_PARAMETER.analystEmail.join('/'),
        name: 'Корпоративна електронна скринька',
        value: alert.analyst?.email,
        editable: false,
    },
    {
        key: ALERT_PARAMETER.controller,
        name: 'Контролер',
        value: <UserModal user={alert.controller} />,
        editable: false,
    },
    {
        key: ALERT_PARAMETER.controllerEmail.join('/'),
        name: 'Корпоративна електронна скринька',
        value: alert.controller?.email,
        editable: false,
    },
];

export const getRequisitesData = (
    alert: ExtendedAlert,
    additionalInfo?: AlertAdditionalInfo,
    isAdditionalInfoLoading?: boolean,
): AlertTabRowData[] => [
    {
        key: 'customerTypeName',
        name: 'Тип клієнта',
        value: getAbbr(alert.customer?.customerTypeName),
        editable: false,
    },
    {
        key: 'hasAnotherType',
        name: 'Чи є клієнтом Банку ФО/ФОП (Так/Ні)?',
        value: isExist(alert.customer?.hasAnotherType),
        editable: false,
        hide: isLegalType(alert.customer?.customerTypeName),
    },
    {
        key: 'businessSegment',
        name: 'Сегмент бізнесу',
        value: alert.customer?.businessSegment,
        editable: false,
    },
    {
        key: 'dpaRegistrationDate',
        name: 'Дата державної реєстрації',
        value: getDateView(alert.customer?.dpaRegistrationDate, true, true),
        editable: false,
        hide: isIndividualType(alert.customer?.customerTypeName),
    },
    {
        key: 'birthday',
        name: 'Дата народження',
        value: getDateView(alert.customer?.birthday, true, true),
        editable: false,
        hide: isLegalType(alert.customer?.customerTypeName),
    },
    {
        key: 'Citizenship',
        name: 'Громадянство',
        value:
            ((alert.customer?.citizenshipCode && alert.customer?.citizenshipCode + ' - ') || '') +
            (alert.customer?.citizenshipName || ''),
        editable: false,
        hide: isLegalType(alert.customer?.customerTypeName),
    },
    {
        key: 'Residency',
        name: 'Резидентність',
        value:
            ((alert.customer?.countryCode && alert.customer?.countryCode + ' - ') || '') +
            (alert.customer?.countryName || ''),
        editable: false,
    },
    {
        key: 'addresses',
        name: 'Місце проживання або перебування',
        value: getLegalAddress(alert.customer.addresses),
        editable: false,
        hide: isLegalType(alert.customer?.customerTypeName),
    },
    {
        key: 'TemporaryAddress',
        name: 'Місце проживання або тимчасового перебування в Україні',
        value: getLegalAddress(alert.customer.addresses),
        editable: false,
        hide:
            alert.customer?.customerTypeName !== CUSTOMER_TYPES_INDIVIDUAL ||
            (!alert.customer?.countryCode && !alert.customer?.countryName),
    },
    {
        key: 'registrationAddress',
        name: 'Місцезнаходження',
        value: getLegalAddress(alert.customer.addresses),
        editable: false,
        hide: isIndividualType(alert.customer?.customerTypeName),
    },
    {
        key: 'anotherAddressInfo',
        name: 'Інші відомості щодо адреси (за наявності)',
        value: alert.anotherAddressInfo,
        editable: true,
        fieldName: ['anotherAddressInfo'],
    },
    {
        key: 'massRegistrationAddress',
        name: 'Чи належить адреса клієнта до адрес масової реєстрації?',
        value: alert.massRegistrationAddress,
        editable: true,
        fieldName: ['massRegistrationAddress'],
        type: 'select',
        options: approveOption,
    },
    {
        key: 'numberOfRegistredSGD',
        name: 'Якщо "Так", то кількість зареєстрованих СГД складає:',
        value: alert.numberOfRegistredSGD,
        editable: false,
    },
    {
        key: 'socialStatus',
        name: 'Соціальний статус',
        value: alert.socialStatus,
        editable: true,
        fieldName: ['socialStatus'],
        hide: alert.customer?.customerTypeName !== CUSTOMER_TYPES_INDIVIDUAL,
    },
    {
        key: 'typeOfEmployment',
        name: 'Статус зайнятості особи',
        value: alert.typeOfEmployment,
        editable: true,
        fieldName: ['typeOfEmployment'],
        hide: alert.customer?.customerTypeName !== CUSTOMER_TYPES_INDIVIDUAL,
    },
    {
        key: 'work',
        name: 'Місце роботи',
        value: alert.work,
        editable: true,
        fieldName: ['work'],
        hide: alert.customer?.customerTypeName !== CUSTOMER_TYPES_INDIVIDUAL,
    },
    {
        key: 'Посада',
        name: 'Посада',
        value: '-',
        //   editable: true,
        fieldName: 'Посада',
        hide: alert.customer?.customerTypeName !== CUSTOMER_TYPES_INDIVIDUAL,
    },
    {
        key: 'isCustomerPEP',
        name: 'Належність до категорії РЕР',
        value: alert.customer?.isCustomerPEP,
        editable: false,
    },
    {
        key: 'customerPEPId',
        name: 'Категорія посади РЕР (ФО/ФОП) / Категорія PEP ЮО',
        value: isIndividualType(alert.customer?.customerTypeName)
            ? alert.customer?.physicalCustomerPEPId
            : alert.customer?.juridicalCustomerPEPId,
        editable: false,
    },
    {
        key: 'ved',
        name: 'Основний КВЕД',
        value: alert.customer?.ved + ' - ' + alert.customer?.vedDescription,
        editable: false,
        hide: alert.customer?.customerTypeName === CUSTOMER_TYPES_INDIVIDUAL,
    },
    {
        key: 'kvedQuantity',
        name: 'Загальна кількість зареєстрованих КВЕД',
        value: alert.kvedQuantity,
        editable: true,
        fieldName: ['kvedQuantity'],
        type: 'numberInput',
        hide: alert.customer?.customerTypeName === CUSTOMER_TYPES_INDIVIDUAL,
    },
    {
        key: 'licensePermitsInfo',
        name: 'Інформація щодо наявності ліцензій/дозволів',
        value: alert.licensePermitsInfo,
        editable: false,
        fieldName: ['licensePermitsInfo'],
        hide: alert.customer?.customerTypeName === CUSTOMER_TYPES_INDIVIDUAL,
    },
    {
        key: 'authorizedCapital',
        name: 'Розмір статутного капіталу у грн.',
        value: alert.customer?.authorizedCapital,
        editable: false,
        hide: isIndividualType(alert.customer?.customerTypeName),
    },
    {
        key: 'numberOfEmployees',
        name: 'Кількість працівників',
        value: alert.customer?.numberOfEmployees,
        editable: false,
        hide: alert.customer?.customerTypeName === CUSTOMER_TYPES_INDIVIDUAL,
    },
    {
        key: 'businessRelationshipDate',
        name: 'Дата встановлення ділових відносин',
        value: getDateView(alert.customer?.businessRelationshipDate, true, true),
        editable: false,
    },
    {
        key: 'firstCurrentAccountDate',
        name: 'Дата відкриття першого поточного рахунку',
        value: getAdditionalInfo('firstCurrentAccountDate', additionalInfo, isAdditionalInfoLoading),
        editable: false,
    },
];

export const getProfileData = (alert: ExtendedAlert): AlertTabRowData[] => [
    {
        key: 'riskLevelOnCreate',
        name: 'Первинний рівень ризику ',
        value: alert.riskLevelOnCreate,
        editable: false,
    },
    {
        key: 'riskLevelDateOnCreate',
        name: 'Дата встановлення/зміни первинного рівня ризику',
        value: getDateView(alert.riskLevelDateOnCreate, true),
        editable: false,
    },
    {
        key: 'risksCriteria',
        name: 'Первинні критерії ризику (за наявності)',
        value: (
            <div>
                {alert.rules
                    ?.filter((model) => model.addedManually === 0 && model.rule.type.code === RULES_TYPE_CRITERION)
                    .map((model) => (
                        <Tooltip title={model.rule.description} key={model.id}>
                            <div className="field__rule-description">
                                {model.rule.code}&nbsp;{model.rule.description}
                            </div>
                        </Tooltip>
                    ))}
            </div>
        ),
        editable: false,
    },
    {
        key: 'riskScore',
        name: 'Загальна сума балів по критеріям ризику',
        value: alert.riskSumScore,
        editable: false,
    },
    {
        key: 'suspicionIndicator',
        name: 'Первинні індикатори підозрілості (за наявності)',
        value: (
            <div>
                {alert.rules
                    ?.filter((model) => model.addedManually === 0 && model.rule.type.code === RULES_TYPE_INDICATOR)
                    .map((model) => (
                        <Tooltip title={model.rule.description} key={model.id}>
                            <div className="field__rule-description">
                                {model.rule.code}&nbsp;{model.rule.description}
                            </div>
                        </Tooltip>
                    ))}
            </div>
        ),
        editable: false,
    },
];

export const getAdditionalData = (alert: ExtendedAlert, form?: FormInstance<any>): AlertTabRowData[] => [
    {
        key: 'bankCode',
        name: 'Філія, в якій відкриті рахунки',
        value: alert.customer?.bankCode,
        editable: false,
    },
    {
        key: 'Корпоративна електронна скринька Філія',
        name: 'Корпоративна електронна скринька',
        value: '-',
        editable: false,
    },
    {
        key: 'branchName',
        name: 'Відділення, в якому відкриті рахунки',
        value: alert.customer.branchName?.description,
        editable: false,
    },
    {
        key: 'Корпоративна електронна скринька Відділення',
        name: 'Корпоративна електронна скринька',
        value: '-',
        editable: false,
    },
    { key: 'manager', name: 'Менеджер клієнта', value: alert.customer?.manager, editable: false },
    {
        key: 'Корпоративна електронна скринька Менеджер',
        name: 'Корпоративна електронна скринька',
        value: '-',
        editable: false,
    },
    {
        key: 'anotherContactInfo',
        name: 'Інша контактна інформація (за наявності)',
        value: alert.anotherContactInfo,
        editable: true,
        fieldName: ['anotherContactInfo'],
        type: 'textArea',
        rows: 2,
    },
    {
        key: 'listOfRequireDocuments',
        name: 'Перелік документів, які потрібно отримати від клієнта з метою спростування/підтвердження підозр та/або з метою вжиття інших ПЗНП, за результатами яких буде прийнято рішення про ризики подальшого обслуговування клієнта в Банку',
        value: alert.listOfRequireDocuments,
        editable: true,
        fieldName: 'listOfRequireDocuments',
        type: 'textArea',
        rows: 10,
    },
    {
        key: 'sendingRequestToCustomerDate',
        name: 'Дата направлення запиту клієнту',
        value: getDateView(alert.sendingRequestToCustomerDate),
        editable: true,
        fieldName: 'sendingRequestToCustomerDate',
        type: 'date',
    },
    {
        key: 'numberOfIncomingLetter',
        name: 'Номер вихідного листа',
        value: alert.numberOfIncomingLetter,
        editable: true,
        fieldName: 'numberOfIncomingLetter',
        style: numberOfIncomingLetterStyle,
    },
    {
        key: 'isRequestReceived',
        name: 'Чи отримав клієнт запит Банку?',
        value: alert.isRequestReceived,
        editable: true,
        fieldName: 'isRequestReceived',
        type: 'select',
        options: approveOption,
        required: true,
    },
    {
        key: 'requestReceivedDate',
        name: 'Дата отримання клієнтом запиту Банку',
        value: getDateView(alert.requestReceivedDate),
        editable: true,
        fieldName: 'requestReceivedDate',
        type: 'date',
        required: form?.getFieldValue('isRequestReceived') ? true : false,
    },
    {
        key: 'isDocumentsProvided',
        name: 'Чи надав клієнт на запит Банку документи?',
        value: alert.isDocumentsProvided,
        editable: true,
        fieldName: 'isDocumentsProvided',
        type: 'select',
        options: isDocumentsProvidedOptions,
        required: true,
    },
    {
        key: 'providingDocumentsDate',
        name: 'Дата отримання Банком документів від клієнта',
        value: getDateView(alert.providingDocumentsDate),
        editable: true,
        fieldName: 'providingDocumentsDate',
        type: 'date',
        required: form?.getFieldValue('isDocumentsProvided') ? true : false,
    },
    {
        key: 'commentsAboutRequest',
        name: 'Додаткова інформація щодо запиту документів (за наявності)',
        value: alert.commentsAboutRequest,
        editable: true,
        fieldName: 'commentsAboutRequest',
        type: 'textArea',
        rows: 2,
    },
];

export const getCommentsData = (
    alert: ExtendedAlert,
    indicators: Option[],
    criteria: Option[],
    minRisksMeasures?: RecordModel[],
    form?: FormInstance<any>,
): AlertTabRowData[] => [
    {
        key: 'commentsAboutProvidingDocuments',
        fieldName: 'commentsAboutProvidingDocuments',
        name: 'Коментарі щодо наданих на запит документів та їх аналізу',
        value: alert.commentsAboutProvidingDocuments,
        editable: true,
        required: true,
        type: 'textArea',
        rows: 10,
    },
    {
        key: 'anotherCounterpartiesInfo',
        name: 'Коментарі щодо контрагентів (за наявності)',
        value: alert.anotherCounterpartiesInfo,
        editable: true,
        fieldName: 'anotherCounterpartiesInfo',
        type: 'textArea',
        rows: 10,
    },
    {
        key: 'otherMeasuresOfPZNP',
        fieldName: 'otherMeasuresOfPZNP',
        name: 'Інші заходи ПЗНП',
        value: alert.otherMeasuresOfPZNP,
        editable: true,
        required: true,
        type: 'textArea',
        rows: 3,
    },
    {
        key: 'negativeInformation',
        fieldName: 'negativeInformation',
        name: 'Наявність негативної інформації у відкритих джерелах та/або отриманої від підрозділу безпеки Банку',
        value: alert.negativeInformation,
        editable: true,
        required: true,
        type: 'textArea',
        rows: 3,
    },
    {
        key: 'manuallyCriteria',
        fieldName: 'manuallyCriteria',
        name: 'Критерії ризику, додатково виявлені під час вжиття ПЗНП (за наявності)',
        value: alert.rules
            ?.filter(
                (rule) =>
                    rule.rule.type.code === RULES_TYPE_CRITERION && rule.addedManually === 1 && rule.additional === 0,
            )
            .map((rule) => rule.rule.id),
        editable: true,
        multiply: true,
        type: 'select',
        options: criteria,
    },

    {
        key: 'manuallyIndicator',
        fieldName: 'manuallyIndicator',
        name: 'Індикатори підозрілості, додатково виявлені під час вжиття ПЗНП (за наявності)',
        value: alert.rules
            ?.filter((rule) => rule.rule.type.code === RULES_TYPE_INDICATOR && rule.addedManually === 1)
            .map((rule) => rule.rule.id),
        editable: true,
        multiply: true,
        type: 'select',
        options: indicators,
    },
    {
        key: 'bankImplementationOfOtherNPC',
        fieldName: 'bankImplementationOfOtherNPC',
        name: 'Інформація щодо вжиття банком інших заходів НПК (зокрема актуалізації даних про клієнта, ПЗНП, результатів моніторингу фінансових операцій)',
        value: alert.bankImplementationOfOtherNPC,
        editable: true,
        required: true,
        type: 'textArea',
        rows: 2,
        placeholder: 'Введіть значення, яке буде експортовано до електронної анкети клієнта та його ризик-профілю',
    },
    {
        key: 'minRisksMeasures',
        fieldName: 'minRisksMeasures',
        name: 'Заходи, що були вжиті стосовно клієнта з метою мінімізації ризиків ВК/ФТ',
        value: alert.minRisksMeasures?.map((item) => item.id),
        editable: true,
        required: true,
        type: 'select',
        options: minRisksMeasures?.map((item) => ({ value: item.id, label: item.description })),
        multiply: true,
        placeholder: 'Оберіть значення, яке буде експортовано до результатів звіту «Моніторинг ділових відносин»',
    },
    {
        key: 'measuresToMinimazeRisks',
        name: 'Інші заходи з метою мінімізації ризиків',
        value: alert.measuresToMinimazeRisks,
        editable: true,
        fieldName: 'measuresToMinimazeRisks',
        required: (() => {
            const otherId = minRisksMeasures?.find((item) => item.description === 'Інше')?.id;
            return form?.getFieldValue('minRisksMeasures')?.some((item: RecordModel['id']) => item === otherId);
        })(),
        type: 'textArea',
        rows: 2,
    },
    {
        key: 'additionalComments',
        fieldName: 'additionalComments',
        name: 'Додаткові коментарі',
        value: alert.additionalComments,
        editable: true,
        type: 'textArea',
        rows: 2,
    },
];

export const getConclusionData = (
    alert: ExtendedAlert,
    availableManual: Option[] = [],
    availableAdditional: Option[] = [],
    solution?: RecordModel[],
    totalRiskScore?: number,
    form?: FormInstance<any>,
): AlertTabRowData[] => [
    {
        key: 'conclusionAboutSuspicions',
        fieldName: 'conclusionAboutSuspicions',
        name: 'Висновок щодо наявності/відсутності підозр щодо клієнта та його операцій',
        value: alert.conclusionAboutSuspicions,
        editable: true,
        required: true,
        type: 'textArea',
        rows: 5,
    },
    {
        key: 'conclusion',
        fieldName: 'conclusion',
        name: 'Пропозиції',
        value: alert?.conclusion?.map((item) => item.id),
        options: solution?.map((item) => ({ value: item.id, label: item.description })),
        type: 'select',
        editable: true,
        required: true,
        multiply: true,
    },
    {
        key: 'other',
        name: 'Інше',
        value: alert.other,
        editable: true,
        fieldName: 'other',
        required: (() => {
            const otherId = solution?.find((item) => item.description === 'Інше')?.id;
            return form?.getFieldValue('conclusion')?.some((item: RecordModel['id']) => item === otherId);
        })(),
        type: 'textArea',
        rows: 2,
    },
    {
        key: 'approvedCriteria',
        name: 'Підтверджую доцільність встановлених/виявлених критеріїв ризику',
        value:
            alert.rules
                ?.filter(
                    (model) =>
                        model.rule.type.code === RULES_TYPE_CRITERION && model.approved === 1 && model.additional === 0,
                )
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'approvedCriteria',
        type: 'select',
        options: availableManual,
        multiply: true,
    },
    {
        key: 'declinedCriteria',
        name: 'Не підтверджую доцільність встановлених/виявлених критеріїв ризику',
        value:
            alert.rules
                ?.filter(
                    (model) =>
                        model.rule.type.code === RULES_TYPE_CRITERION && model.approved === 0 && model.additional === 0,
                )
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'declinedCriteria',
        type: 'select',
        options: availableManual,
        multiply: true,
        disabled: true,
    },
    {
        key: 'additionalCriteria',
        name: 'Вважаю за доцільне додатково встановити наступні критеріїв ризику',
        value:
            alert.rules
                ?.filter((model) => model.rule.type.code === RULES_TYPE_CRITERION && model.additional === 1)
                .map((model) => model.rule.id) || [],
        editable: true,
        fieldName: 'additionalCriteria',
        type: 'select',
        options: availableAdditional,
        multiply: true,
    },
    {
        key: 'totalRiskSumScore',
        name: 'Залишкові бали по підтвердженим критеріям ризику',
        value: totalRiskScore,
        editable: false,
    },
];

export const getApprovalData = (alert: BaseAlert, rolesParams?: UserModel['rolesParams']): AlertTabRowData[] => [
    {
        key: 'controllerComments',
        name: 'Коментарі/зауваження Контролера',
        value: alert.controllerComments,
        editable: false,
    },
    {
        key: 'conclusionDate',
        fieldName: 'conclusionDate',
        name: 'Дата погодження висновку',
        value: getDateView(alert.conclusionDate, !rolesParams?.IS_CONTROLLER),
        editable: rolesParams?.IS_CONTROLLER,
        required: rolesParams?.IS_CONTROLLER ?? false,
        disabledPastPeriod: true,
        type: 'date',
    },
    {
        key: 'closeDate',
        fieldName: 'closeDate',
        name: 'Дата закриття алерту',
        value: alert.closeDate && moment(alert.closeDate).format(DATE_FORMAT),
        editable: false,
    },
];
