export const defaultFunctions = [
    // {
    //     id: 9,
    //     path: 'administration',
    //     name: 'Адміністрування',
    //     icon: 'administration',
    //     children: [
    //         {
    //             id: 10,
    //             path: 'roles',
    //             name: 'Ролі',
    //             icon: undefined,
    //             children: [],
    //             ord: 1,
    //             isActive: true,
    //         },
    //         {
    //             id: 11,
    //             path: 'users',
    //             name: 'Користувачі',
    //             icon: undefined,
    //             children: [],
    //             ord: 2,
    //             isActive: true,
    //         },
    //         {
    //             id: 12,
    //             path: 'dictionary',
    //             name: 'Довідники',
    //             icon: undefined,
    //             children: [],
    //             ord: 3,
    //             isActive: true,
    //         },
    //         {
    //             id: 17,
    //             path: 'vocabulary',
    //             name: 'Словники',
    //             icon: undefined,
    //             children: [],
    //             ord: 4,
    //             isActive: true,
    //         },
    //         {
    //             id: 13,
    //             path: 'risk-criteria',
    //             name: 'Критерії ризику',
    //             icon: undefined,
    //             children: [],
    //             ord: 5,
    //             isActive: true,
    //         },
    //         {
    //             id: 14,
    //             path: 'suspicion-indicators',
    //             name: 'Індикатори підозрілості',
    //             icon: undefined,
    //             children: [],
    //             ord: 6,
    //             isActive: true,
    //         },
    //         {
    //             id: 15,
    //             path: 'sessions-administration',
    //             name: 'Адміністрування сесіями',
    //             icon: undefined,
    //             children: [],
    //             ord: 7,
    //             isActive: true,
    //         },
    //         {
    //             id: 16,
    //             path: 'sessions-register',
    //             name: 'Реєстр сесій',
    //             icon: undefined,
    //             children: [],
    //             ord: 8,
    //             isActive: true,
    //         },
    //     ],
    //     ord: 4,
    //     isActive: true,
    // },
];
