import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { getResizableColumn } from './getResizableColumn';
import ResizableColumn from '../../../components/common/ResizebleColumn';
import {
    RULE_TRANSACTION_SIZE,
    DATE_FORMAT,
    TIME_FORMAT,
    RULE_ACCOUNT_SIZE,
    RULE_RELATION_SIZE,
} from '../../../constants';
import { formatNumberToFinance, convertToCurrency } from '../../../helpers/convertCurrency';
import { TableSettings } from '../../../helpers/getTableSizeFromStore';
import { Accounts, RelationParams, Transaction } from '../../../models/Alert';

export const riskColumns = [
    { key: 'code', title: 'Код критерію ризику', dataIndex: ['rule', 'code'], width: 220 },
    { key: 'name', title: 'Найменування критерію ризику', dataIndex: ['rule', 'description'] },
    { key: 'score', title: 'Бали', dataIndex: 'ruleScore' },
];

export const indicatorsColumns = [
    { key: 'code', title: 'Код індикатору підозрілості', dataIndex: ['rule', 'code'], width: 220 },
    { key: 'name', title: 'Назва індикатору підозрілості', dataIndex: ['rule', 'description'] },
];

export const nestedRiskColumns = [
    { key: 'param', title: 'Параметр', dataIndex: 'name' },
    { key: 'value', title: 'Значення', dataIndex: 'value' },
];

const toUnderscoreCase = (str: string) => {
    return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};

export const getTransactionColumns = (size?: TableSettings, isOldParams: boolean = true): ColumnsType<Transaction> => {
    const result = [
        {
            key: 'documentId',
            title: (
                <ResizableColumn colIndex={0} storageKey={RULE_TRANSACTION_SIZE}>
                    Номер документа
                </ResizableColumn>
            ),
            dataIndex: 'documentId',
            ellipsis: true,
            width: size?.[0] || 106,
        },
        {
            key: 'documentDate',
            title: (
                <ResizableColumn colIndex={1} storageKey={RULE_TRANSACTION_SIZE}>
                    Дата документу
                </ResizableColumn>
            ),
            render: (value: any) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'documentDate',
            ellipsis: true,
            width: size?.[1] || 80,
        },
        {
            key: 'amount',
            title: (
                <ResizableColumn colIndex={2} storageKey={RULE_TRANSACTION_SIZE}>
                    Сума операції
                </ResizableColumn>
            ),
            render: (value: any) => formatNumberToFinance(value),
            dataIndex: 'amount',
            ellipsis: true,
            width: size?.[2] || 106,
        },
        {
            key: 'currencyCode',
            title: (
                <ResizableColumn colIndex={3} storageKey={RULE_TRANSACTION_SIZE}>
                    Валюта операції
                </ResizableColumn>
            ),
            dataIndex: 'currencyCode',
            ellipsis: true,
            width: size?.[3] || 40,
        },
        {
            key: 'amountEquivalent',
            title: (
                <ResizableColumn colIndex={4} storageKey={RULE_TRANSACTION_SIZE}>
                    Еквівалент в грн
                </ResizableColumn>
            ),
            render: (value: any) => formatNumberToFinance(value),
            dataIndex: 'amountEquivalent',
            ellipsis: true,
            width: size?.[4] || 106,
        },
        {
            key: 'senderIban',
            title: (
                <ResizableColumn colIndex={5} storageKey={RULE_TRANSACTION_SIZE}>
                    Номер рахунку платника
                </ResizableColumn>
            ),
            dataIndex: 'senderIban',
            ellipsis: true,
            width: size?.[5] || 140,
        },
        {
            key: 'senderBankCode',
            title: (
                <ResizableColumn colIndex={6} storageKey={RULE_TRANSACTION_SIZE}>
                    Код Банку платника
                </ResizableColumn>
            ),
            dataIndex: 'senderBankCode',
            ellipsis: true,
            width: size?.[6] || 56,
        },
        {
            key: 'senderBankName',
            title: (
                <ResizableColumn colIndex={7} storageKey={RULE_TRANSACTION_SIZE}>
                    Банк платника
                </ResizableColumn>
            ),
            dataIndex: 'senderBankName',
            ellipsis: true,
            width: size?.[7] || 106,
        },
        {
            key: 'senderName',
            title: (
                <ResizableColumn colIndex={8} storageKey={RULE_TRANSACTION_SIZE}>
                    Найменування платника
                </ResizableColumn>
            ),
            dataIndex: 'senderName',
            ellipsis: true,
            width: size?.[8] || 106,
        },
        {
            key: 'senderTaxCode',
            title: (
                <ResizableColumn colIndex={9} storageKey={RULE_TRANSACTION_SIZE}>
                    Ідент.номер / Код платника
                </ResizableColumn>
            ),
            dataIndex: 'senderTaxCode',
            ellipsis: true,
            width: size?.[9] || 80,
        },
        {
            key: 'recipientIban',
            title: (
                <ResizableColumn colIndex={10} storageKey={RULE_TRANSACTION_SIZE}>
                    Номер рахунку отримувача
                </ResizableColumn>
            ),
            dataIndex: 'recipientIban',
            ellipsis: true,
            width: size?.[10] || 146,
        },
        {
            key: 'recipientBankCode',
            title: (
                <ResizableColumn colIndex={11} storageKey={RULE_TRANSACTION_SIZE}>
                    Код Банку отримувача
                </ResizableColumn>
            ),
            dataIndex: 'recipientBankCode',
            ellipsis: true,
            width: size?.[11] || 60,
        },
        {
            key: 'recipientBankName',
            title: (
                <ResizableColumn colIndex={12} storageKey={RULE_TRANSACTION_SIZE}>
                    Банк отримувача
                </ResizableColumn>
            ),
            dataIndex: 'recipientBankName',
            ellipsis: true,
            width: size?.[12] || 106,
        },
        {
            key: 'recipientName',
            title: (
                <ResizableColumn colIndex={13} storageKey={RULE_TRANSACTION_SIZE}>
                    Найменування отримувача
                </ResizableColumn>
            ),
            dataIndex: 'recipientName',
            ellipsis: true,
            width: size?.[13] || 106,
        },
        {
            key: 'recipientTaxCode',
            title: (
                <ResizableColumn colIndex={14} storageKey={RULE_TRANSACTION_SIZE}>
                    Ідент.номер / Код отримувача
                </ResizableColumn>
            ),
            dataIndex: 'recipientTaxCode',
            ellipsis: true,
            width: size?.[14] || 80,
        },
        {
            key: 'purpose',
            title: (
                <ResizableColumn colIndex={15} storageKey={RULE_TRANSACTION_SIZE}>
                    Призначення платежу
                </ResizableColumn>
            ),
            dataIndex: 'purpose',
            ellipsis: true,
            width: size?.[15] || 106,
        },
        {
            key: 'paymentDate',
            title: (
                <ResizableColumn colIndex={16} storageKey={RULE_TRANSACTION_SIZE}>
                    Дата проведення операції
                </ResizableColumn>
            ),
            render: (value: any) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'paymentDate',
            ellipsis: true,
            width: size?.[16] || 80,
        },
        {
            key: 'paymentTime',
            title: (
                <ResizableColumn colIndex={17} storageKey={RULE_TRANSACTION_SIZE}>
                    Час здійснення операції
                </ResizableColumn>
            ),
            render: (value: any) => (value ? moment(value).format(TIME_FORMAT) : ''),
            dataIndex: 'paymentTime',
            ellipsis: true,
            width: size?.[17] || 46,
        },
    ];

    if (isOldParams) {
        result.forEach((column) => {
            column.key = toUnderscoreCase(column.key);
            column.dataIndex = toUnderscoreCase(column.dataIndex);
        });
    }

    return result;
};

export const getAccountsColumns = (size?: TableSettings, isOldParams: boolean = true): ColumnsType<Accounts> => {
    const result = [
        {
            key: 'branchName',
            title: (
                <ResizableColumn colIndex={0} storageKey={RULE_ACCOUNT_SIZE}>
                    ТВБВ
                </ResizableColumn>
            ),
            dataIndex: 'branchName',
            ellipsis: true,
            width: size?.[0] || 106,
        },
        {
            key: 'bankCode',
            title: (
                <ResizableColumn colIndex={1} storageKey={RULE_ACCOUNT_SIZE}>
                    МФО РУ
                </ResizableColumn>
            ),
            dataIndex: 'bankCode',
            ellipsis: true,
            width: size?.[1] || 56,
        },
        {
            key: 'bankName',
            title: (
                <ResizableColumn colIndex={2} storageKey={RULE_ACCOUNT_SIZE}>
                    Назва РУ
                </ResizableColumn>
            ),
            dataIndex: 'bankName',
            ellipsis: true,
            width: size?.[2] || 106,
        },
        {
            key: 'accountNumber',
            title: (
                <ResizableColumn colIndex={3} storageKey={RULE_ACCOUNT_SIZE}>
                    Номер рахунку
                </ResizableColumn>
            ),
            dataIndex: 'accountNumber',
            ellipsis: true,
            width: size?.[3] || 106,
        },
        {
            key: 'accountType',
            title: (
                <ResizableColumn colIndex={4} storageKey={RULE_ACCOUNT_SIZE}>
                    Баланс рахунку
                </ResizableColumn>
            ),
            dataIndex: 'accountType',
            ellipsis: true,
            width: size?.[4] || 40,
        },
        {
            key: 'currency',
            title: (
                <ResizableColumn colIndex={5} storageKey={RULE_ACCOUNT_SIZE}>
                    Валюта рахунку
                </ResizableColumn>
            ),
            dataIndex: 'currency',
            ellipsis: true,
            width: size?.[5] || 36,
        },
        {
            key: 'customerId',
            title: (
                <ResizableColumn colIndex={6} storageKey={RULE_ACCOUNT_SIZE}>
                    РНК
                </ResizableColumn>
            ),
            dataIndex: 'customerId',
            ellipsis: true,
            width: size?.[6] || 106,
        },
        {
            key: 'accountName',
            title: (
                <ResizableColumn colIndex={7} storageKey={RULE_ACCOUNT_SIZE}>
                    Найменування рахунку
                </ResizableColumn>
            ),
            dataIndex: 'accountName',
            ellipsis: true,
            width: size?.[7] || 106,
        },
        {
            key: 'iban',
            title: (
                <ResizableColumn colIndex={8} storageKey={RULE_ACCOUNT_SIZE}>
                    IBAN
                </ResizableColumn>
            ),
            dataIndex: 'iban',
            ellipsis: true,
            width: size?.[8] || 140,
        },
        {
            key: 'openDate',
            title: (
                <ResizableColumn colIndex={9} storageKey={RULE_ACCOUNT_SIZE}>
                    Дата відкриття
                </ResizableColumn>
            ),
            render: (value: any) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'openDate',
            ellipsis: true,
            width: size?.[9] || 82,
        },
        {
            key: 'accountBalance',
            title: (
                <ResizableColumn colIndex={10} storageKey={RULE_ACCOUNT_SIZE}>
                    Вхідний залишок
                </ResizableColumn>
            ),
            render: (value: any) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'accountBalance',
            ellipsis: true,
            width: size?.[10] || 106,
        },
        {
            key: 'debetTurns',
            title: (
                <ResizableColumn colIndex={11} storageKey={RULE_ACCOUNT_SIZE}>
                    Обороти ДТ
                </ResizableColumn>
            ),
            render: (value: any) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'debetTurns',
            ellipsis: true,
            width: size?.[11] || 106,
        },
        {
            key: 'creditTurns',
            title: (
                <ResizableColumn colIndex={12} storageKey={RULE_ACCOUNT_SIZE}>
                    Обороти КТ
                </ResizableColumn>
            ),
            render: (value: any) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'creditTurns',
            ellipsis: true,
            width: size?.[12] || 106,
        },
        {
            key: 'residual',
            title: (
                <ResizableColumn colIndex={13} storageKey={RULE_ACCOUNT_SIZE}>
                    Фактичний залишок
                </ResizableColumn>
            ),
            render: (value: any) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'residual',
            ellipsis: true,
            width: size?.[13] || 106,
        },
        {
            key: 'planResidual',
            title: (
                <ResizableColumn colIndex={14} storageKey={RULE_ACCOUNT_SIZE}>
                    Плановий залишок
                </ResizableColumn>
            ),
            render: (value: any) => formatNumberToFinance(convertToCurrency(value)),
            dataIndex: 'planResidual',
            ellipsis: true,
            width: size?.[14] || 106,
        },
        {
            key: 'closeDate',
            title: (
                <ResizableColumn colIndex={15} storageKey={RULE_ACCOUNT_SIZE}>
                    Дата закриття
                </ResizableColumn>
            ),
            render: (value: any) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'closeDate',
            ellipsis: true,
            width: size?.[15] || 82,
        },
        {
            key: 'balanceDate',
            title: (
                <ResizableColumn colIndex={16} storageKey={RULE_ACCOUNT_SIZE}>
                    Дата останнього руху
                </ResizableColumn>
            ),
            render: (value: any) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'balanceDate',
            ellipsis: true,
            width: size?.[16] || 82,
        },
    ];

    if (isOldParams) {
        result.forEach((column) => {
            column.key = toUnderscoreCase(column.key);
            column.dataIndex = toUnderscoreCase(column.dataIndex);
        });
    }

    return result;
};

export const getRelationsColumns = (size?: TableSettings, rowData?: RelationParams): ColumnsType<RelationParams> => {
    const rowDataKeys = rowData
        ? Object.keys(rowData).map((key) => rowData[key as keyof RelationParams] !== 'empty' && key)
        : [];
    const possibleColumns: ColumnsType<RelationParams> = [
        {
            key: 'reft_number',
            title: 'Номер в обмежувальному списку Банку',
            dataIndex: 'reft_number',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'client_name',
            title: 'Найменування/ПІБ',
            dataIndex: 'client_name',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'client_code',
            title: 'ЄДРПОУ/РНОКПП',
            dataIndex: 'client_code',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'birth_day',
            title: 'Дата реєстрації/Дата народження',
            render: (value: any) => (value ? moment(value).format(DATE_FORMAT) : ''),
            dataIndex: 'birth_day',
            ellipsis: true,
            width: 82,
        },
        {
            key: 'relation_name',
            title: "Тип зв'язку",
            dataIndex: 'relation_name',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'vaga1',
            title: 'Частка володіння',
            dataIndex: 'vaga1',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'vaga2',
            title: 'Тип володіння',
            dataIndex: 'vaga2',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'is_bank_client',
            title: 'Клієнт банку',
            dataIndex: 'is_bank_client',
            render: (value: any) => (value === 1 ? 'Так' : 'Ні'),
            ellipsis: true,
            width: 106,
        },
        {
            key: 'country',
            title: 'Країна',
            dataIndex: 'country',
            ellipsis: true,
            width: 106,
        },
        {
            key: 'citizenship_name',
            title: 'Громодянство',
            dataIndex: 'citizenship_name',
            ellipsis: true,
            width: 106,
        },
    ];

    const columns: ColumnsType<RelationParams> = [];
    possibleColumns.forEach((column) => rowDataKeys.includes(`${column.key}`) && columns.push(column));

    return getResizableColumn(columns, RULE_RELATION_SIZE, size);
};
