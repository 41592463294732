import { useState, useCallback } from 'react';
import { RoleModel } from '../../models/RoleModel';
import { ColumnsType } from 'antd/lib/table';
import { Tag, Row, Col, Table, TableProps } from 'antd';
import { MenuFunctionModel } from '../../models/MenuFunctionModel';
import UpdateRoleForm from '../../components/form/role-form/UpdateRoleForm';
import { useGetRolesQuery } from '../../redux/api/rolesApi';
import Modal from '../../components/common/Modal';

const getColumns = (): ColumnsType<RoleModel> => [
    {
        title: 'Назва',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Опис',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Функції',
        dataIndex: 'menuFunctions',
        key: 'menuFunctions',
        render: (functions: MenuFunctionModel[]) => (
            <div>
                {functions?.map((item) => (
                    <Tag
                        key={item.id}
                        style={{
                            backgroundColor: 'rgba(56, 83, 92, 0.8)',
                            color: 'rgb(255, 255, 255)',
                            borderColor: 'rgba(56, 83, 92, 0.85)',
                            margin: '2px 8px 2px 0',
                        }}>
                        {item.name}
                    </Tag>
                ))}
            </div>
        ),
        width: '70%',
    },
];
const Roles = () => {
    const [selected, setSelected] = useState<RoleModel | null>(null);
    const { data: roles, isLoading, isFetching } = useGetRolesQuery();
    const closeRoleEditor = useCallback(() => setSelected(null), []);
    const rowCb: TableProps<RoleModel>['onRow'] = useCallback(
        (record: RoleModel) => ({ onClick: () => setSelected(record) }),
        [],
    );

    return (
        <>
            <Modal
                width={600}
                title={`Редагування ролі ${selected?.name}`}
                onCancel={closeRoleEditor}
                open={!!selected}
                footer={null}
                bodyStyle={{ paddingTop: '1rem' }}>
                {selected && <UpdateRoleForm role={selected} onSuccess={closeRoleEditor} />}
            </Modal>
            <Row>
                <Col span={24}>
                    <Table<RoleModel>
                        scroll={{ x: 600 }}
                        className="main-table"
                        rowKey="name"
                        loading={isLoading || isFetching}
                        columns={getColumns()}
                        dataSource={roles}
                        onRow={rowCb}
                    />
                </Col>
            </Row>
        </>
    );
};

export default Roles;
