import buildQuery, { QueryOptions } from 'odata-query';
import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import { ALLOWED_FUNCTIONS } from '../../constants/Menu';
import { MenuFunctionModel } from '../../models/MenuFunctionModel';
import { CreateUserModel, EmployeeModel, GetUsersResponse, UserHistoryModel, UserModel } from '../../models/UserModel';
import { setAuthData } from '../features/authSlice';
import { RootState } from '../store';

type GetHistoryResponse = {
    count: number;
    items: UserHistoryModel[];
};

type GetHistoryRequest = {
    id: number;
    queryData: Partial<QueryOptions<UserHistoryModel>>;
};
export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: customBaseQuery,
    tagTypes: ['Users', 'UserHistory'],
    endpoints: (builder) => ({
        getMenuFunctions: builder.query<MenuFunctionModel[], void>({
            query() {
                return {
                    url: `user/access/functions`,
                    method: 'GET',
                };
            },
            transformResponse: (menuFunctions: MenuFunctionModel[]) =>
                menuFunctions?.filter((item) => ALLOWED_FUNCTIONS.includes(item.path)),
        }),
        getProfile: builder.mutation<UserModel, void>({
            query() {
                return {
                    url: 'user/profile',
                    method: 'get',
                    credentials: 'include',
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
                try {
                    const { data: user } = await queryFulfilled;
                    const state = getState() as RootState;
                    await dispatch(
                        setAuthData({
                            user,
                            accessToken: state.auth.accessToken,
                            refreshToken: state.auth.refreshToken,
                            apiVersion: state.auth.apiVersion,
                        }),
                    );
                } catch (error) {}
            },
        }),
        getUsers: builder.query<GetUsersResponse, Partial<QueryOptions<UserModel>>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `user${queryString}`,
                    method: 'GET',
                };
            },
            providesTags: ['Users'],
        }),
        getEmployees: builder.query<EmployeeModel[], Partial<QueryOptions<UserModel>>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `user${queryString}`,
                    method: 'GET',
                };
            },
            transformResponse: (employers: EmployeeModel[]) =>
                employers.sort((a, b) => {
                    const strA = `${a?.lastName}${a?.firstName}`?.toLowerCase();
                    const strB = `${b?.lastName}${b?.firstName}`?.toLowerCase();
                    return strA < strB ? -1 : strA > strB ? 1 : 0;
                }),
            providesTags: ['Users'],
        }),
        updateUser: builder.mutation<UserModel, any>({
            query(data) {
                return {
                    url: `user/${data.id}`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: ['Users', 'UserHistory'],
        }),
        createUser: builder.mutation<UserModel, CreateUserModel>({
            query(data) {
                return {
                    url: `user`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['Users'],
        }),
        deactivateUser: builder.mutation<UserModel, string | number>({
            query(id) {
                return {
                    url: `user/${id}/deactivate`,
                    method: 'PUT',
                };
            },
            invalidatesTags: ['Users'],
        }),
        activateUser: builder.mutation<UserModel, string | number>({
            query(id) {
                return {
                    url: `user/${id}/activate`,
                    method: 'PUT',
                };
            },
            invalidatesTags: ['Users'],
        }),
        getUserHistory: builder.query<GetHistoryResponse, GetHistoryRequest>({
            query({ id, queryData }) {
                const queryString = buildQuery(queryData);
                return {
                    url: `user/history/${id}${queryString}`,
                    method: 'GET',
                };
            },
            providesTags: ['UserHistory'],
        }),
    }),
});
export const {
    useGetMenuFunctionsQuery,
    useGetProfileMutation,
    useGetUsersQuery,
    useGetEmployeesQuery,
    useUpdateUserMutation,
    useCreateUserMutation,
    useDeactivateUserMutation,
    useActivateUserMutation,
    useGetUserHistoryQuery,
    useLazyGetUsersQuery,
} = userApi;
