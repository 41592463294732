import React, { FC, useCallback } from 'react';

import { Spin, Form, Button, Row, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import UserDataFields from './UserDataFields';

import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { CreateUserModel } from '../../../models/UserModel';
import { useCreateUserMutation } from '../../../redux/api/userApi';

interface CreateUserFormProps {
    onSuccess: () => void;
    setIsEditedTrue: () => void;
}
const CreateUserForm: FC<CreateUserFormProps> = ({ onSuccess, setIsEditedTrue }) => {
    const [form] = useForm();
    const [createUser, { isLoading }] = useCreateUserMutation();
    const handleSubmit = useCallback(
        (values: CreateUserModel) => {
            values.roles = (values.roles as string[]).map((item: string) => ({ name: item })) || [];
            createUser(values)
                .unwrap()
                .then(() => onSuccess())
                .catch((error) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message }));
        },
        [createUser, onSuccess],
    );

    return (
        <Spin spinning={isLoading}>
            <Form<CreateUserModel>
                form={form}
                onChange={setIsEditedTrue}
                onFinish={handleSubmit}
                layout="vertical"
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}>
                <UserDataFields />
                <Row justify="end">
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Row>
            </Form>
        </Spin>
    );
};

export default CreateUserForm;
