import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import { alertsApi } from './api/alertsApi';
// todo test, remove after create next reducer
import { authApi } from './api/authApi';
import { calendarApi } from './api/calendarApi';
import { customerApi } from './api/customerApi';
import { dictionaryApi } from './api/dictionaryApi';
import { rolesApi } from './api/rolesApi';
import { rulesApi } from './api/rulesApi';
import { schedulesApi } from './api/schedulesApi';
import { systemApi } from './api/systemApi';
import { userApi } from './api/userApi';
import rootReducer from './reducers';

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                ignoredPaths: ['userApi'],
            },
        }).concat([
            authApi.middleware,
            rolesApi.middleware,
            userApi.middleware,
            alertsApi.middleware,
            dictionaryApi.middleware,
            rulesApi.middleware,
            calendarApi.middleware,
            customerApi.middleware,
            systemApi.middleware,
            schedulesApi.middleware,
        ]),
});
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
