import { FC } from 'react';
import { Button, DatePicker } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { DATE_FORMAT, DATE_FORMAT_FOR_QUERY } from '../../constants/Alert';

const FilterDateRange: FC<FilterDropdownProps> = ({ setSelectedKeys, clearFilters, confirm }) => (
    <div className="filter" onClick={(e) => e.stopPropagation()}>
        <div className="filter__wrapper">
            <DatePicker.RangePicker
                className="filter__field"
                placeholder={['Дата від', 'Дата до']}
                format={DATE_FORMAT}
                onChange={(e) => setSelectedKeys(e ? e?.map((d: any) => d.format(DATE_FORMAT_FOR_QUERY)) : [])}
            />
        </div>
        <div className="filter__buttons">
            <Button
                onClick={() => {
                    clearFilters && clearFilters();
                    confirm();
                }}
                type="link"
                size="small">
                Скинути
            </Button>
            <Button type="primary" onClick={() => confirm()} size="small">
                Ок
            </Button>
        </div>
    </div>
);

export default FilterDateRange;
