import buildQuery from 'odata-query';
import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import { GetRequest, GetResponse } from './models/baseType';
import { IntgrStat } from '../../models/IntgrStat';
import { SystemLog } from '../../models/SystemLog';
import { UsersActions } from '../../models/UsersActions';
import { DataProcessingStat } from '../../models/dataProcessingStat';

const apiTag = 'client';

export const systemApi = createApi({
    reducerPath: apiTag + 'Api',
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getSystemLog: builder.query<GetResponse<SystemLog>, GetRequest<SystemLog>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `${apiTag}/system-log${queryString}`,
                    method: 'GET',
                };
            },
        }),
        getIntgrStats: builder.query<GetResponse<IntgrStat>, GetRequest<IntgrStat> & { date: string }>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `${apiTag}/intgr-stats/${queryData.date}${queryString}`,
                    method: 'GET',
                };
            },
        }),
        getDataProcessingStat: builder.query<DataProcessingStat[], string>({
            query(date) {
                return {
                    url: `${apiTag}/data-processing-stat/${date}`,
                    method: 'GET',
                };
            },
        }),
        getUsersActions: builder.query<GetResponse<UsersActions>, GetRequest<UsersActions>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `${apiTag}/actions-log${queryString}`,
                    method: 'GET',
                };
            },
        }),
        putUserActionLog: builder.mutation<void, { userId: number; action: string }>({
            query(data) {
                return {
                    url: `${apiTag}/log`,
                    method: 'PUT',
                    body: data,
                };
            },
        }),
    }),
});

export const {
    useGetSystemLogQuery,
    useGetIntgrStatsQuery,
    useGetDataProcessingStatQuery,
    useGetUsersActionsQuery,
    usePutUserActionLogMutation,
} = systemApi;
