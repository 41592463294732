import { Modal } from 'antd';

const closeWithoutSaveConfirmModal = (onOk: () => void) => {
    Modal.confirm({
        title: 'Підтвердження',
        content: 'Ви дійсно бажаєте вийти без збереження змін?',
        onOk: onOk,
        okText: 'Так, вийти',
        cancelText: 'Повернутися',
        style: { top: '20%' },
    });
};

export default closeWithoutSaveConfirmModal;
