import { Tabs } from 'antd';
import SystemLog from './SystemLog';
import UsersActions from './UsersActions';

const SystemLogs = () => {
    const tabItems = [
        {
            key: 'server-logs',
            label: 'Системні логи',
            children: <SystemLog />,
        },
        {
            key: 'users-actions',
            label: 'Дії користувачів',
            children: <UsersActions />,
        },
    ];
    return <Tabs type="card" defaultActiveKey="1" items={tabItems} />;
};

export default SystemLogs;
