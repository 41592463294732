import { useMemo } from 'react';
import { Rule } from '../../../models/Alert';
import { RuleModel } from '../../../models/RulesModel';

export const useGetRulesLists = (
    indicators: RuleModel[],
    criteria: RuleModel[],
    autoAddedIndicatorCodes: Rule['id'][],
    autoAddedCriteriaCodes: Rule['id'][],
    alertCriteriaCodes: Rule['id'][],
    approvedCriteriaCodes: Rule['id'][],
    declinedCriteriaCodes: Rule['id'][],
    additionalCriteriaCodes: Rule['id'][],
) => {
    const indicatorsCatalog = useMemo(
        () =>
            indicators.map((rule) => ({
                value: rule.id,
                label: rule.code + '  ' + rule.description,
            })),
        [indicators],
    );

    const criteriaCatalog = useMemo(
        () =>
            criteria.map((rule) => ({
                value: rule.id,
                label: rule.code + '  ' + rule.description,
            })),
        [criteria],
    );

    const availableToSelectCriteria = useMemo(
        () =>
            alertCriteriaCodes?.filter(
                (code) => !approvedCriteriaCodes?.includes(code) && !declinedCriteriaCodes?.includes(code),
            ),
        [alertCriteriaCodes, approvedCriteriaCodes, declinedCriteriaCodes],
    );

    const listForManualSelectIndicators = useMemo(
        () => indicatorsCatalog.filter((indicator) => !autoAddedIndicatorCodes.includes(indicator.value)),
        [indicatorsCatalog, autoAddedIndicatorCodes],
    );

    const listForManualSelectCriteria = useMemo(
        () => criteriaCatalog.filter((criteria) => !autoAddedCriteriaCodes.includes(criteria.value)),
        [criteriaCatalog, autoAddedCriteriaCodes],
    );

    let availableManual;
    if (availableToSelectCriteria) {
        availableManual = criteriaCatalog.filter((rule) => alertCriteriaCodes?.includes(rule.value));
    }

    let availableAdditional;
    if (approvedCriteriaCodes && declinedCriteriaCodes) {
        availableAdditional = criteriaCatalog.filter(
            (rule) =>
                ![...approvedCriteriaCodes, ...declinedCriteriaCodes]?.includes(rule.value) ||
                additionalCriteriaCodes?.includes(rule.value),
        );
    }

    return {
        listForManualSelectIndicators,
        listForManualSelectCriteria,
        availableManual,
        availableAdditional,
    };
};
