import { FC, Key, useCallback, useState } from 'react';
import { Button, Col, Modal, notification, Row, Space } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import CreateDictionaryModal from './CreateDictionaryModal';
import TabsSelect from '../../../components/common/TabsSelect';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { DictionaryModel } from '../../../models/DictionaryModel';
import { useDeleteByIdsMutation } from '../../../redux/api/dictionaryApi';

interface HeaderDictionaryProps {
    dictionary?: DictionaryModel;
    dictionaries?: DictionaryModel[];
    activeTab?: string;
    onChangeTab: (activeKey: string) => void;
    selectedRows: Key[] | null;
    clearSelectedRows: () => void;
    showControls: boolean;
    openHistory: () => void;
}

const dictionaryWithoutHistory: Array<string> = ['dict_registry_adress'];

const HeaderDictionary: FC<HeaderDictionaryProps> = ({
    dictionary,
    dictionaries,
    activeTab,
    onChangeTab,
    selectedRows,
    clearSelectedRows,
    showControls,
    openHistory,
}) => {
    const [deleteRecord] = useDeleteByIdsMutation();
    const [createRecordModal, setCreateRecordModal] = useState(false);
    const toggleCreateUserModal = useCallback(() => {
        setCreateRecordModal((prev) => !prev);
    }, []);

    const showDeleteModal = () => {
        Modal.confirm({
            title: 'Підтвердження',
            content: 'Ви дійсно бажаєте видалити запис?',
            onOk: handleDeleteRecord,
            okText: 'Видалити',
            cancelText: 'Відміна',
            style: { top: '20%' },
        });
    };

    const handleDeleteRecord = useCallback(
        (cascade: boolean = false) => {
            if (dictionary && selectedRows) {
                deleteRecord({
                    dict_name: dictionary.name,
                    ids: selectedRows,
                    cascade,
                })
                    .unwrap()
                    .then(() => {
                        notification.success({
                            ...BASE_NOTIFICATION_CONFIG,
                            message: `Запис${selectedRows.length !== 1 ? 'и' : ''} успішно видалено`,
                        });
                        clearSelectedRows();
                    })
                    .catch((error) => {
                        if (error.status === 404) {
                            Modal.confirm({
                                title: 'Підтвердження',
                                content: `${error.data.message}. Ви дійсно бажаєте видалити запис та всі його включення?`,
                                onOk: () => handleDeleteRecord(true),
                                okText: 'Видалити',
                                cancelText: 'Відміна',
                                style: { top: '20%' },
                            });
                        } else {
                            notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message });
                        }
                    });
            }
        },
        [clearSelectedRows, deleteRecord, dictionary, selectedRows],
    );

    return (
        <>
            <CreateDictionaryModal
                onCancel={toggleCreateUserModal}
                onSuccess={toggleCreateUserModal}
                dictionary={dictionary}
                createRecordModal={createRecordModal}
            />
            <Row>
                <Col span={12}>
                    <Row justify="start">
                        <TabsSelect
                            options={dictionaries?.map((item) => ({ label: item.description, value: `${item.id}` }))}
                            activeTab={activeTab}
                            onChangeSelect={onChangeTab}
                        />
                    </Row>
                </Col>
                <Col span={12}>
                    <Row justify="end">
                        <Space>
                            <Button
                                icon={<HistoryOutlined />}
                                onClick={openHistory}
                                disabled={!dictionary || dictionaryWithoutHistory.includes(dictionary?.name)}
                                hidden={dictionary && dictionaryWithoutHistory.includes(dictionary?.name)}>
                                Історія
                            </Button>
                            <Button
                                type="primary"
                                danger
                                onClick={showDeleteModal}
                                disabled={selectedRows?.length === 0 || !showControls}>
                                Видалити запис
                            </Button>
                            <Button
                                type="primary"
                                onClick={toggleCreateUserModal}
                                disabled={!dictionary || !showControls}>
                                Додати запис
                            </Button>
                        </Space>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default HeaderDictionary;
