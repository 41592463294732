import { notification } from 'antd';
import { BASE_NOTIFICATION_CONFIG } from '../constants/common';

export const checkUpdateStatusResponse = (response: any, status: string) => {
    if (response.some((item: any) => item.error)) {
        let alertsWithError: number[] = [];
        let alertsWithoutError: number[] = [];
        response.forEach((item: any) => {
            if (item.error) alertsWithError.push(item.id);
            else alertsWithoutError.push(item.id);
        });
        if (alertsWithError.length)
            notification.error({
                ...BASE_NOTIFICATION_CONFIG,
                message: `Не заповнені всі обов'язкові поля алертів: ` + alertsWithError.join(', '),
            });
        if (alertsWithoutError.length)
            notification.success({
                ...BASE_NOTIFICATION_CONFIG,
                message: `Статус успішно змінено на "${status}" для алертів: ` + alertsWithoutError.join(', '),
            });
    } else notification.success({ ...BASE_NOTIFICATION_CONFIG, message: `Статус успішно змінено на "${status}"` });
};
