import {
    PRIORITY_CODE_ARCHIVE,
    PRIORITY_CODE_IMMEDIATELY,
    PRIORITY_CODE_PRIORITY,
    PRIORITY_CODE_USUAL,
    STATUS_CODE_AGREED,
    STATUS_CODE_AMENDMENT,
    STATUS_CODE_ASSIGNED,
    STATUS_CODE_CLOSED,
    STATUS_CODE_NEW,
    STATUS_CODE_PROCESS,
    STATUS_CODE_PROCESSED,
} from '../constants/Alert';
import { COLOR_BLUE, COLOR_DEFAULT, COLOR_GREEN, COLOR_ORANGE, COLOR_PURPLE, COLOR_RED } from '../constants/common';
import { PriorityCodes, StatusCodes } from '../models/Catalog';

export const getPriorityColor = (code: PriorityCodes) => {
    switch (code) {
        case PRIORITY_CODE_IMMEDIATELY:
            return COLOR_RED;
        case PRIORITY_CODE_PRIORITY:
            return COLOR_ORANGE;
        case PRIORITY_CODE_USUAL:
        case PRIORITY_CODE_ARCHIVE:
            return COLOR_DEFAULT;
    }
};

export const getStatusColor = (code: StatusCodes) => {
    switch (code) {
        case STATUS_CODE_PROCESS:
            return COLOR_BLUE;
        case STATUS_CODE_PROCESSED:
            return COLOR_ORANGE;
        case STATUS_CODE_AGREED:
            return COLOR_GREEN;
        case STATUS_CODE_AMENDMENT:
            return COLOR_PURPLE;
        case STATUS_CODE_ASSIGNED:
        case STATUS_CODE_NEW:
        case STATUS_CODE_CLOSED:
        default:
            return COLOR_DEFAULT;
    }
};
