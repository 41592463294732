import { Select, Spin } from 'antd';
import { FC, ChangeEvent } from 'react';
import { selectOptions } from '../../../../helpers/selectOptionsSystemDict';
import { ColumnModel } from '../../../../models/DictionaryModel';
import { useGetSystemDictionaryQuery } from '../../../../redux/api/dictionaryApi';

interface IProps {
    column: ColumnModel;
    value: ChangeEvent<HTMLSelectElement>;
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const DictionarySelect: FC<IProps> = ({ column, value, onChange }) => {
    const { data, isLoading, isFetching } = useGetSystemDictionaryQuery(column.refDictName, {
        skip: !column.refDictName,
    });

    return (
        <Spin spinning={isLoading || isFetching}>
            <Select placeholder={column.description} options={selectOptions(data)} value={value} onChange={onChange} />
        </Spin>
    );
};

export default DictionarySelect;
