import React, { FC, useMemo, useRef } from 'react';
import { ColumnsType } from 'antd/es/table';
import ResizableColumn from '../../../../../components/common/ResizebleColumn';
import { ACCOUNT_TURNOVER_TABLE } from '../../../../../constants/Alert';
import { ReportTypes } from '../../../../../constants/enums';
import { formatNumberToFinance } from '../../../../../helpers/convertCurrency';
import { accountsTurnoverDto } from '../../../../../helpers/dto/accountsTurnoverDto';
import { getColumnSearchProps } from '../../../../../helpers/getColumnSearchProps';
import getTableSizeFromStore from '../../../../../helpers/getTableSizeFromStore';
import { localeCompareSort } from '../../../../../helpers/sort';
import { Account } from '../../../../../models/Account';
import TableWrapper from '../TableWrapper';

interface AccountTurnoverProps {
    loading: boolean;
    data?: Account[];
    triggerFetch?: () => void;
}
const AccountTurnover: FC<AccountTurnoverProps> = ({ loading, data, triggerFetch }) => {
    const size = useMemo(() => getTableSizeFromStore(ACCOUNT_TURNOVER_TABLE), []);
    const inputRef = useRef(null);

    const columns: ColumnsType<Account> = useMemo(
        () => [
            {
                key: 'branch',
                dataIndex: 'branch',
                title: (
                    <ResizableColumn colIndex={0} storageKey={ACCOUNT_TURNOVER_TABLE}>
                        ТВБВ
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'branch'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'branch'),
                ellipsis: true,
                width: size?.[0] || 82,
            },
            {
                key: 'bankCode',
                dataIndex: 'bankCode',
                title: (
                    <ResizableColumn colIndex={1} storageKey={ACCOUNT_TURNOVER_TABLE}>
                        МФО РУ
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'bankCode'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'bankCode'),
                ellipsis: true,
                width: size?.[1] || 60,
            },
            {
                key: 'bankName',
                dataIndex: 'bankName',
                title: (
                    <ResizableColumn colIndex={2} storageKey={ACCOUNT_TURNOVER_TABLE}>
                        Назва РУ
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'bankName'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'bankName'),
                ellipsis: true,
                width: size?.[2] || 82,
            },
            {
                key: 'accountIban',
                dataIndex: 'accountIban',
                title: (
                    <ResizableColumn colIndex={3} storageKey={ACCOUNT_TURNOVER_TABLE}>
                        Номер рахунку
                    </ResizableColumn>
                ),
                sorter: (a, b) => localeCompareSort(a, b, 'accountIban'),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'accountIban'),
                ellipsis: true,
                width: size?.[3] || 198,
            },
            {
                key: 'turnDebit',
                dataIndex: 'turnDebit',
                render: (value) => formatNumberToFinance(value),
                title: (
                    <ResizableColumn colIndex={4} storageKey={ACCOUNT_TURNOVER_TABLE}>
                        Оборот ДТ
                    </ResizableColumn>
                ),
                sorter: (a, b) => (a.turnDebit as number) - (b.turnDebit as number),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'turnDebit'),
                ellipsis: true,
                width: size?.[4] || 150,
            },
            {
                key: 'turnCredit',
                dataIndex: 'turnCredit',
                render: (value) => formatNumberToFinance(value),
                title: (
                    <ResizableColumn colIndex={5} storageKey={ACCOUNT_TURNOVER_TABLE}>
                        Оборот КТ
                    </ResizableColumn>
                ),
                sorter: (a, b) => (a.turnCredit as number) - (b.turnCredit as number),
                ...getColumnSearchProps<Account>(inputRef, 'string', 'turnCredit'),
                ellipsis: true,
                width: size?.[5] || 150,
            },
        ],
        [size],
    );

    return (
        <TableWrapper
            rowKey="accountId"
            dataSource={data}
            columns={columns}
            loading={loading}
            triggerFetch={triggerFetch}
            isActionBar={true}
            isClearFilters={true}
            reportType={ReportTypes.accountsTurnover}
            dto={accountsTurnoverDto}
        />
    );
};

export default AccountTurnover;
