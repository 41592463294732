import { BaseAlert, ExtendedAlert } from '../models/Alert';
import { UserModel } from '../models/UserModel';

export const hasAllowedController = (
    alerts?: (BaseAlert | ExtendedAlert) | (BaseAlert | ExtendedAlert)[],
    user?: UserModel | null,
) => {
    if (!user || !alerts) return false;
    if (user.rolesParams?.IS_CONTROLLER_1ST) return true;

    if (user.rolesParams?.IS_CONTROLLER_2ND)
        return Array.isArray(alerts)
            ? alerts.every((item) => item.controller?.id === user.id)
            : alerts?.controller?.id === user.id;
};
