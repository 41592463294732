export enum STATUS_CODES {
    new = 'new',
    assigned = 'assigned',
    process = 'process',
    processed = 'processed',
    agreed = 'agreed',
    amendment = 'amendment',
    closed = 'closed',
}

export enum STATUS_CODES_TRANSLATION {
    new = 'Новий',
    assigned = 'Призначено',
    process = 'В роботі',
    processed = 'Опрацьовано',
    agreed = 'Погоджено',
    amendment = 'Доопрацювання',
    closed = 'Закрито',
}
