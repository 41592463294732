import { Select } from 'antd';
import { FC } from 'react';

interface Option {
    label: string;
    value: string;
}
interface TabsSelectProps {
    activeTab?: string;
    onChangeSelect: (value: string) => void;
    options?: Array<Option>;
    disabled?: boolean;
    id?: string;
}

const TabsSelect: FC<TabsSelectProps> = ({ activeTab, onChangeSelect, options, disabled, id }) => (
    <Select
        value={activeTab}
        style={{ width: '240px', marginBottom: '1.5rem' }}
        showSearch
        placeholder="Search"
        optionFilterProp="children"
        onChange={onChangeSelect}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        options={options}
        id={id}
        disabled={disabled}
    />
);

export default TabsSelect;
