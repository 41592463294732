import { FC } from 'react';
import { Table, TablePaginationConfig } from 'antd';
import { RULE_TRANSACTION_SIZE } from '../../../../constants';
import getTableSizeFromStore from '../../../../helpers/getTableSizeFromStore';
import { Accounts, Rule } from '../../../../models/Alert';
import { RecordModel } from '../../../../models/DictionaryModel';
import { Transaction } from '../../../../models/Transaction';
import { customerParamsProcessing } from '../../helpers/customerParamsProcessing';
import {
    getRelationsColumns,
    nestedRiskColumns,
    getTransactionColumns,
    getAccountsColumns,
} from '../../helpers/getColumns';

interface ExpandedRowProps {
    record: Rule;
    dict?: RecordModel[];
    transactionsData?: Transaction[];
    accountsData?: Accounts[];
    transactionsPagination: TablePaginationConfig;
    handleTransactionsTableChange: (newPagination: TablePaginationConfig) => void;
    accountsPagination: TablePaginationConfig;
    handleAccountsTableChange: (newPagination: TablePaginationConfig) => void;
    getTransactionsParams: (params: { ruleId: number; pagination: TablePaginationConfig }, isAuto: boolean) => void;
    getAccountsParams: (params: { ruleId: number; pagination: TablePaginationConfig }, isAuto: boolean) => void;
}

const ExpandedRow: FC<ExpandedRowProps> = ({
    record,
    dict,
    transactionsData,
    accountsData,
    transactionsPagination,
    handleTransactionsTableChange,
    accountsPagination,
    handleAccountsTableChange,
    getTransactionsParams,
    getAccountsParams,
}) => {
    const size = getTableSizeFromStore(RULE_TRANSACTION_SIZE);

    const ruleRelationsColumns = getRelationsColumns(size, record?.relationsParams?.[0]);
    const { simpleCustomerParamsData, arrayCustomerParamsData, arrayCustomerParamsColumns } = customerParamsProcessing(
        record.customerParams,
        dict,
    );

    const handleTransactionTableChange = (newPagination: TablePaginationConfig) => {
        const { current, pageSize } = transactionsPagination;
        handleTransactionsTableChange(newPagination);

        if (newPagination.current !== current || newPagination.pageSize !== pageSize) {
            getTransactionsParams({ ruleId: record.id, pagination: newPagination }, true);
        }
    };

    const handleAccountTableChange = (newPagination: TablePaginationConfig) => {
        const { current, pageSize } = accountsPagination;
        handleAccountsTableChange(newPagination);

        if (newPagination.current !== current || newPagination.pageSize !== pageSize) {
            getAccountsParams({ ruleId: record.id, pagination: newPagination }, true);
        }
    };

    return (
        <div>
            {!!simpleCustomerParamsData.length && (
                <Table
                    rowKey="id"
                    className="nestedTable"
                    columns={nestedRiskColumns}
                    dataSource={simpleCustomerParamsData}
                    pagination={false}
                    size="small"
                />
            )}

            {!!arrayCustomerParamsData.length && (
                <Table
                    rowKey="id"
                    className="nestedTable"
                    columns={arrayCustomerParamsColumns}
                    dataSource={arrayCustomerParamsData}
                    pagination={false}
                    size="small"
                />
            )}

            {!!transactionsData?.length && (
                <div className="rule-scrolable-table">
                    <Table
                        rowKey="id"
                        className="nestedTable"
                        columns={getTransactionColumns(size, false)}
                        dataSource={transactionsData}
                        pagination={transactionsPagination}
                        onChange={handleTransactionTableChange}
                        size="small"
                    />
                </div>
            )}

            {!!accountsData?.length && (
                <div className="rule-scrolable-table">
                    <Table
                        rowKey="id"
                        className="nestedTable"
                        columns={getAccountsColumns(size, false)}
                        dataSource={accountsData}
                        pagination={accountsPagination}
                        onChange={handleAccountTableChange}
                        size="small"
                    />
                </div>
            )}

            {!!record.transactionsParams?.length && (
                <div className="rule-scrolable-table">
                    <Table
                        rowKey="id"
                        className="nestedTable"
                        columns={getTransactionColumns(size)}
                        dataSource={record.transactionsParams}
                        pagination={false}
                        size="small"
                    />
                </div>
            )}
            {!!record.accountsParams?.length && (
                <div className="rule-scrolable-table">
                    <Table
                        rowKey="id"
                        className="nestedTable"
                        columns={getAccountsColumns(size)}
                        dataSource={record.accountsParams}
                        pagination={false}
                        size="small"
                    />
                </div>
            )}

            {!!record.relationsParams?.length && (
                <div className="rule-scrolable-table">
                    <Table
                        rowKey="id"
                        className="nestedTable"
                        columns={ruleRelationsColumns}
                        dataSource={record.relationsParams}
                        pagination={false}
                        size="small"
                    />
                </div>
            )}
        </div>
    );
};

export default ExpandedRow;
