import { useMemo } from 'react';
import {
    useLazyGetAlertAccountsQuery,
    useLazyGetAlertBlackListQuery,
    useLazyGetAlertOtherRnkQuery,
    useLazyGetAlertTransactionsQuery,
    useLazyGetClientAlertQuery,
} from '../../../redux/api/alertsApi';
import {
    useDeleteCustomerAlienAccountsMutation,
    useLazyGetCustomerAlienAccountsQuery,
    useLazyGetCustomerRelationsQuery,
    usePutCustomerAlienAccountsMutation,
} from '../../../redux/api/customerApi';

export enum QueryType {
    alerts = 'alerts',
    accounts = 'accounts',
    payments = 'payments',
    otherRnk = 'otherRnk',
    blackList = 'blackList',
    relations = 'relations',
    alienAccounts = 'alien-accounts',
}

export const useGetLazyQuery = () => {
    const [getAlerts, { data: alerts, isLoading: isLoadingAlerts, isError: isErrorAlerts }] =
        useLazyGetClientAlertQuery();
    const [getAccounts, { data: accounts, isLoading: isLoadingAccounts, isError: isErrorAccounts }] =
        useLazyGetAlertAccountsQuery();
    const [getPayments, { data: payments, isLoading: isLoadingTransactions, isError: isErrorPayments }] =
        useLazyGetAlertTransactionsQuery();
    const [getOtherRNK, { data: otherRnk, isLoading: isLoadingOtherRnk, isError: isErrorOtherRnk }] =
        useLazyGetAlertOtherRnkQuery();
    const [getBlackList, { data: blackList, isLoading: isLoadingBlackList, isError: isErrorBlacklist }] =
        useLazyGetAlertBlackListQuery();
    const [getRelations, { data: relations, isLoading: isLoadingRelations, isError: isErrorRelations }] =
        useLazyGetCustomerRelationsQuery();
    const [
        getAlienAccounts,
        { data: alienAccounts, isLoading: isLoadingAlienAccounts, isError: isErrorAlienAccounts },
    ] = useLazyGetCustomerAlienAccountsQuery();

    const triggerFetch = (type: QueryType, id?: number) => {
        if (!id) return;
        if (type === QueryType.alerts && !alerts && !isErrorAlerts) getAlerts(id);
        if (type === QueryType.accounts && !accounts && !isErrorAccounts) getAccounts(id);
        if (type === QueryType.payments && !payments && !isErrorPayments) getPayments(id);
        if (type === QueryType.otherRnk && !otherRnk && !isErrorOtherRnk) getOtherRNK(id);
        if (type === QueryType.blackList && !blackList && !isErrorBlacklist) getBlackList(id);
        if (type === QueryType.relations && !relations && !isErrorRelations) getRelations(id);
        if (type === QueryType.alienAccounts && !alienAccounts && !isErrorAlienAccounts) getAlienAccounts(id);
    };

    const data = useMemo(() => {
        return {
            alerts,
            accounts,
            payments,
            otherRnk,
            blackList,
            relations,
            alienAccounts,
        };
    }, [alerts, accounts, payments, otherRnk, blackList, relations, alienAccounts]);

    const loading = useMemo(() => {
        return {
            isLoadingAlerts,
            isLoadingAccounts,
            isLoadingTransactions,
            isLoadingOtherRnk,
            isLoadingBlackList,
            isLoadingRelations,
            isLoadingAlienAccounts,
        };
    }, [
        isLoadingAlerts,
        isLoadingAccounts,
        isLoadingTransactions,
        isLoadingOtherRnk,
        isLoadingBlackList,
        isLoadingRelations,
        isLoadingAlienAccounts,
    ]);

    return { triggerFetch, data, loading };
};

export const usePutQuery = () => {
    const [putAlienAccounts, { isLoading: isLoadingAlienAccounts }] = usePutCustomerAlienAccountsMutation();

    const triggerPut = (type?: QueryType, value?: any, cb?: () => void) => {
        if (type === QueryType.alienAccounts) putAlienAccounts(value).then(() => cb && cb());
    };

    const loading = useMemo(() => isLoadingAlienAccounts, [isLoadingAlienAccounts]);

    return { triggerPut, loading };
};

export const useDeleteQuery = () => {
    const [deleteAlienAccounts, { isLoading: isLoadingAlienAccounts }] = useDeleteCustomerAlienAccountsMutation();

    const triggerDelete = (type?: QueryType, value?: any, cb?: () => void) => {
        if (type === QueryType.alienAccounts) deleteAlienAccounts(value).then(() => cb && cb());
    };

    const loading = useMemo(() => isLoadingAlienAccounts, [isLoadingAlienAccounts]);

    return { triggerDelete, loading };
};
