import { SorterResult } from 'antd/es/table/interface';

export type CustomSorter = (sorter: SorterResult<any>) => string;
const getSingleSorter = (sorter: SorterResult<any>, customSorter?: CustomSorter) => {
    const { order, columnKey } = sorter;
    if (columnKey && order) {
        const orderQuery = order === 'ascend' ? 'asc' : 'desc';
        if (customSorter) {
            return customSorter(sorter);
        }
        return `${columnKey} ${orderQuery}`;
    }
    return '';
};
export const getSorterQuery = (sorter: SorterResult<any> | SorterResult<any>[], customSorter?: CustomSorter) => {
    if (Array.isArray(sorter)) {
        return sorter
            .map((sorterItem) => getSingleSorter(sorterItem, customSorter))
            .filter((item) => item)
            .join(', ');
    }
    return getSingleSorter(sorter, customSorter);
};
