import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment/moment';
import { QueryOptions } from 'odata-query';

import HistoryRow from '../../../components/common/HistoryRow';
import { DATE_FORMAT, TIME_WITH_SECONDS_FORMAT } from '../../../constants/Alert';
import { statusCatalog, defaultHistoryQuery, statusFilter } from '../../../constants/History';
import { HISTORY_NO_DATA } from '../../../constants/common';
import { SESSION_HISTORY_FIELDS } from '../../../constants/enums';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { getHistoryFilter } from '../../../helpers/getHistoryFilters';
import getTopSkip from '../../../helpers/getTopSkip';
import { isObject } from '../../../helpers/valueTypeCheck';
import { SessionHistory } from '../../../models/Session';
import { useGetSessionHistoryQuery } from '../../../redux/api/calendarApi';

interface HistoryProps {
    intervalCode: string | undefined;
}

interface ISession {
    date: Date;
    comment: string;
}

const historyValue = (value: Partial<ISession>) => {
    if (value) {
        return Object.keys(value).map((key, index) => (
            <HistoryRow
                value={!isObject((value as any)[key]) && (value as any)[key] ? (value as any)[key] : HISTORY_NO_DATA}
                key={index}
            />
        ));
    }
    return <div>Дані відсутні</div>;
};

const History: FC<HistoryProps> = ({ intervalCode }) => {
    const dateInputRef = useRef(null);
    const authorInputRef = useRef(null);
    const defaultFilter = { entryCode: intervalCode };

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });
    const [filter, setFilter] = useState<QueryOptions<SessionHistory>['filter']>(defaultFilter);

    const queryData = useMemo<Partial<QueryOptions<SessionHistory>>>(
        () => ({
            ...defaultHistoryQuery,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: filter,
        }),
        [pagination, filter],
    );

    const { data: sessionHistory, isLoading, isFetching } = useGetSessionHistoryQuery(queryData);

    const count = sessionHistory?.count;

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: count }));
    }, [count]);

    const handleTableChange: TableProps<SessionHistory>['onChange'] = (newPagination, filters) => {
        setFilter([defaultFilter, ...getHistoryFilter(filters)]);
        setPagination(newPagination);
    };

    const column: ColumnsType<SessionHistory> = [
        {
            title: 'Тип',
            dataIndex: 'action',
            key: 'action',
            render: (value) => statusCatalog[value],
            filters: statusFilter,
            width: 120,
            ellipsis: true,
        },
        {
            title: 'Дата',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (value) => moment(value).format(DATE_FORMAT),
            ...getColumnSearchProps<SessionHistory>(dateInputRef, 'date'),
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Час',
            dataIndex: 'createdDate',
            key: 'time',
            render: (value) => moment(value).format(TIME_WITH_SECONDS_FORMAT),
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Автор',
            dataIndex: 'userId',
            key: 'user',
            render: (value) => `${value.lastName} ${value.firstName}`,
            ...getColumnSearchProps<SessionHistory>(authorInputRef, 'fio'),
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Параметр',
            dataIndex: 'newValue',
            key: 'param',
            render: (value) =>
                Object.keys(value).map((key, index) => (
                    <HistoryRow
                        value={SESSION_HISTORY_FIELDS[key as keyof typeof SESSION_HISTORY_FIELDS] || key}
                        key={index}
                    />
                )),
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Значення "До"',
            dataIndex: 'prevValue',
            key: 'prevValue',
            render: (value) => historyValue(value),
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Значення "Після"',
            dataIndex: 'newValue',
            key: 'newValue',
            render: (value) => historyValue(value),
            width: 150,
            ellipsis: true,
        },
    ];

    return (
        <Table<SessionHistory>
            size="small"
            scroll={{ x: 600, y: 600 }}
            rowKey="id"
            bordered
            loading={isLoading || isFetching}
            columns={column}
            dataSource={sessionHistory ? sessionHistory.items : []}
            pagination={pagination}
            onChange={handleTableChange}
        />
    );
};

export default History;
