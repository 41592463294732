import { RuleModel } from '../models/RulesModel';

export const filterRules = (rules: RuleModel[], type: string) =>
    rules
        .filter((rule) => rule.type.code === type)
        .sort((a, b) => {
            const wholeA = a.code.split('(')[0];
            const wholeB = b.code.split('(')[0];

            const fractionalA = (+a.code.replace(')', '').split('(')[1] || 0) / 100;
            const fractionalB = (+b.code.replace(')', '').split('(')[1] || 0) / 100;

            const codeA = +wholeA + fractionalA;
            const codeB = +wholeB + fractionalB;

            return +codeA < +codeB ? -1 : +codeA > +codeB ? 1 : 0;
        });
