import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../constants';
import { formatNumberToFinance } from '../../../helpers/convertCurrency';
import { CustomerParams, SimpleCustomerParams } from '../../../models/Alert';
import { RecordModel } from '../../../models/DictionaryModel';

const getValue = (value: string | number) => {
    if (value === null || value === undefined) return '';

    if (value === '0') return 'Ні';

    if (value.toString().includes('-')) {
        const parsedDate = dayjs(new Date(value));
        if (parsedDate.isValid()) {
            return parsedDate.format(DATE_FORMAT);
        }
    }
    return value.toString();
};

export const customerParamsProcessing = (customerParams?: CustomerParams, dict?: RecordModel[]) => {
    const simpleCustomerParams: SimpleCustomerParams = {};
    const arrayCustomerParamsData: SimpleCustomerParams[] = [];
    let arrayCustomerParamsColumns: ColumnsType<SimpleCustomerParams> = [];
    for (const key in customerParams) {
        const CPKey = customerParams[key];
        if (Array.isArray(CPKey)) {
            arrayCustomerParamsData.push(...CPKey);
        } else {
            simpleCustomerParams[key] = CPKey;
        }
    }

    if (arrayCustomerParamsData.length) {
        const financialParamsKeys = ['operation_total_sum', 'turnover_dt', 'turnover_kt'];
        arrayCustomerParamsColumns = Object.keys(arrayCustomerParamsData[0])
            .map((key) => ({
                key: key,
                dataIndex: key,
                render: (value: string | number) =>
                    financialParamsKeys.includes(key) ? formatNumberToFinance(+value) : value,
                title: dict?.find((item) => item.code === key)?.description || key,
                ord: dict?.find((item) => item.code === key)?.ord,
            }))
            .sort((a, b) => (a.ord ?? 0) - (b.ord ?? 0));
    }

    const simpleCustomerParamsData = Object.keys(simpleCustomerParams)
        .map((key, index) => {
            const translation = dict?.find((item) => item.code === key);
            return {
                id: translation?.id ?? index,
                value: getValue(simpleCustomerParams[key]),
                name: translation?.description ?? key,
                ord: translation?.ord,
            };
        })
        .sort((a, b) => (a.ord ?? 0) - (b.ord ?? 0));

    return { simpleCustomerParamsData, arrayCustomerParamsData, arrayCustomerParamsColumns };
};
