import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT } from '../constants';

export const getDateView = (date?: string | Date, format: boolean = false, compare: boolean = false) => {
    if (!date) return '';

    let result: Dayjs | string = dayjs(date);

    if (format) result = dayjs(date).format(DATE_FORMAT);
    if (compare) {
        const now = dayjs();
        const diffYear = now.diff(date, 'year') || '';
        const diffMonth = now.diff(date, 'month') % 12 || '';

        if (diffYear || diffMonth)
            result = result + ' (' + (diffYear && diffYear + 'р. ') + (diffMonth && diffMonth + 'міс.') + ')';
    }

    return result;
};
