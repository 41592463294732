import { RefObject } from 'react';
import { InputRef, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import ResizableColumn from '../../../components/common/ResizebleColumn';
import { DATE_FORMAT_WITH_TIME, SYSTEM_LOG_TABLE } from '../../../constants';
import { logLevelMap } from '../../../constants/enums/LogLevel';
import { getUniqueUserByRole } from '../../../helpers';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { RoleModel } from '../../../models/RoleModel';
import { SystemLog } from '../../../models/SystemLog';

const logLevelFilter = Object.entries(logLevelMap).map(([key, value]) => ({ text: value, value: key }));

const defaultColumnsWidth = {
    0: 46,
    1: 46,
    2: 46,
    3: 136,
    4: 76,
    5: 35,
    6: 76,
    7: 76,
    8: 336,
} as const;

export const stringContainsColumns = ['NAME', 'MSG', 'USER_ID', 'DATA', 'ERROR_STACK'];
export const stringExistColumns = ['ID'];
export const dateColumns = ['EVENT_TIME'];
export const selectColumns = ['LOG_LEVEL'];
export const nameColumns = ['user'];

export const getSystemLogColumns = (
    sizes: Record<string, number>,
    ref: RefObject<InputRef>,
    expandedRowKeys: number[],
    handleExpandErrorStack: (recordId: number) => void,
    getRoles: () => void,
    roles?: RoleModel[],
): ColumnsType<SystemLog> => {
    return [
        {
            title: (
                <ResizableColumn colIndex={0} storageKey={SYSTEM_LOG_TABLE}>
                    ID запису
                </ResizableColumn>
            ),
            dataIndex: 'ID',
            key: 'ID',
            ellipsis: true,
            width: sizes?.[0] || defaultColumnsWidth[0],
            ...getColumnSearchProps<SystemLog>(ref, 'string'),
            sorter: true,
            defaultSortOrder: 'descend',
        },
        {
            title: (
                <ResizableColumn colIndex={1} storageKey={SYSTEM_LOG_TABLE}>
                    Назва сервісу логування
                </ResizableColumn>
            ),
            dataIndex: 'NAME',
            key: 'NAME',
            ellipsis: true,
            width: sizes?.[1] || defaultColumnsWidth[1],
            ...getColumnSearchProps<SystemLog>(ref, 'string'),
        },
        {
            title: (
                <ResizableColumn colIndex={2} storageKey={SYSTEM_LOG_TABLE}>
                    Рівень логування
                </ResizableColumn>
            ),
            dataIndex: 'LOG_LEVEL',
            key: 'LOG_LEVEL',
            ellipsis: true,
            width: sizes?.[2] || defaultColumnsWidth[2],
            render: (logLevel: number) =>
                logLevelMap?.[logLevel as keyof typeof logLevelMap] || `Unknown (${logLevel})`,
            filters: logLevelFilter,
        },
        {
            title: (
                <ResizableColumn colIndex={3} storageKey={SYSTEM_LOG_TABLE}>
                    Повідомлення
                </ResizableColumn>
            ),
            dataIndex: 'MSG',
            key: 'MSG',
            ellipsis: true,
            width: sizes?.[3] || defaultColumnsWidth[3],
            render: (value, record) => (
                <Typography.Text
                    copyable={value}
                    onClick={() => handleExpandErrorStack(record.ID)}
                    style={{
                        cursor: 'pointer',
                        whiteSpace: expandedRowKeys.includes(record.ID) ? 'normal' : 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                    {value}
                </Typography.Text>
            ),
        },
        {
            title: (
                <ResizableColumn colIndex={4} storageKey={SYSTEM_LOG_TABLE}>
                    Дата та час події
                </ResizableColumn>
            ),
            dataIndex: 'EVENT_TIME',
            key: 'EVENT_TIME',
            ellipsis: true,
            width: sizes?.[4] || defaultColumnsWidth[4],
            render: (value) => (value ? moment(value).format(DATE_FORMAT_WITH_TIME) : ''),
            ...getColumnSearchProps<SystemLog>(ref, 'date'),
            sorter: true,
        },
        {
            title: (
                <ResizableColumn colIndex={5} storageKey={SYSTEM_LOG_TABLE}>
                    Унікальний ідентифікатор користувача
                </ResizableColumn>
            ),
            dataIndex: 'USER_ID',
            key: 'USER_ID',
            ellipsis: true,
            width: sizes?.[5] || defaultColumnsWidth[5],
        },
        {
            title: (
                <ResizableColumn colIndex={6} storageKey={SYSTEM_LOG_TABLE}>
                    Ім'я користувача
                </ResizableColumn>
            ),
            dataIndex: 'USER_NAME',
            key: 'USER_ID',
            ellipsis: true,
            width: sizes?.[6] || defaultColumnsWidth[6],
            onFilterDropdownOpenChange: (visible) => visible && !roles && getRoles(),
            filters: getUniqueUserByRole(roles),
        },
        {
            title: (
                <ResizableColumn colIndex={7} storageKey={SYSTEM_LOG_TABLE}>
                    Додаткові дані
                </ResizableColumn>
            ),
            dataIndex: 'DATA',
            key: 'DATA',
            ellipsis: true,
            width: sizes?.[7] || defaultColumnsWidth[7],
        },
        {
            title: (
                <ResizableColumn colIndex={8} storageKey={SYSTEM_LOG_TABLE}>
                    Опис помилки
                </ResizableColumn>
            ),
            dataIndex: 'ERROR_STACK',
            key: 'ERROR_STACK',
            ellipsis: true,
            width: sizes?.[8] || defaultColumnsWidth[8],
            ...getColumnSearchProps<SystemLog>(ref, 'string'),
            render: (value, record) => (
                <Typography.Text
                    copyable={value}
                    onClick={() => handleExpandErrorStack(record.ID)}
                    style={{
                        cursor: 'pointer',
                        whiteSpace: expandedRowKeys.includes(record.ID) ? 'normal' : 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                    {value}
                </Typography.Text>
            ),
        },
    ];
};
