export enum Roles {
    admin = 'admin',
    controller_1st = 'controller_1st',
    controller_2nd = 'controller_2nd',
    analyst = 'analyst',
}

export enum RolesUa {
    admin = 'Адміністратор',
    controller_1st = 'Контролер 1 рангу',
    controller_2nd = 'Контролер 2 рангу',
    analyst = 'Аналітик',
}
