import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Calendar, Col, DatePicker, notification, Row, Space, Spin } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import { HistoryOutlined, PrinterOutlined } from '@ant-design/icons';
import Cell from './Cell';
import CreateSession from './CreateSession';
import Editor from './Editor';
import GlobalHistory from './GlobalHistory';
import Modal from '../../../components/common/Modal';
import ReportModal from '../../../components/modal/ReportModal';
import { BASE_NOTIFICATION_CONFIG } from '../../../constants/common';
import { ReportTypes } from '../../../constants/enums';
import { useModal } from '../../../hooks';
import { Interval, SessionCalendarIntervals } from '../../../models/Session';
import {
    ReqChangeIntervalDate,
    useChangeIntervalDateMutation,
    useGetCalendarQuery,
} from '../../../redux/api/calendarApi';

export interface IState {
    id: number;
    intervalId: number;
}

export interface Draggable {
    date: Dayjs;
    interval: Interval;
}

const today = dayjs().locale('uk');
const ScheduleDesk = () => {
    const [draggable, setDraggable] = useState<Draggable | null>(null);
    const [filter, setFilter] = useState({
        date: {
            ge: {
                type: 'raw',
                value: dayjs().startOf('month').toISOString(),
            },
            le: {
                type: 'raw',
                value: dayjs().endOf('month').toISOString(),
            },
        },
    });
    const [selectedMonth, setSelectedMonth] = useState(today);
    const [pickerVisible, setPickerVisible] = useState(false);
    const [days, setDays] = useState<SessionCalendarIntervals[]>([]);

    const [state, setState] = useState<IState | null>(null);
    const closeModal = useCallback(() => setState(null), []);

    const [isCreateSessionModalOpen, openCreateSessionModal, closeCreateSessionModal] = useModal();
    const [isHistoryModalOpen, openHistoryModal, closeHistoryModal] = useModal();
    const [isReportModalOpen, openReportModal, closeReportModal] = useModal();
    const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetCalendarQuery({ filter });

    const [changeDate, { isLoading: isLoadingChange }] = useChangeIntervalDateMutation();
    const schedule = useMemo(() => {
        if (state?.id) {
            return days.find((day) => day.id === state?.id);
        }
    }, [state?.id, days]);
    const handleChangeIntervalDate = useCallback(
        async (data: ReqChangeIntervalDate) => {
            changeDate(data)
                .unwrap()
                .then(() => {
                    notification.success({ ...BASE_NOTIFICATION_CONFIG, message: 'Сессія успішно перенесена' });
                })
                .catch((error) => notification.error({ ...BASE_NOTIFICATION_CONFIG, message: error.data.message }));
        },
        [changeDate],
    );
    const dateCellRender = useCallback(
        (value: Dayjs) => (
            <Cell
                onChangeDate={handleChangeIntervalDate}
                onClick={setState}
                value={value}
                days={days}
                draggable={draggable}
                setDraggable={setDraggable}
                onSetDays={setDays}
            />
        ),
        [days, draggable, handleChangeIntervalDate],
    );

    const onPanelChange = useCallback(
        (value: Dayjs) =>
            setFilter({
                date: {
                    ge: {
                        type: 'raw',
                        value: value.startOf('month').toISOString(),
                    },
                    le: {
                        type: 'raw',
                        value: value.endOf('month').toISOString(),
                    },
                },
            }),
        [],
    );

    useEffect(() => {
        if (data) {
            setDays(data.map((item) => ({ ...item })));
        }
    }, [data]);

    return (
        <Spin spinning={isLoadingData || isFetchingData || isLoadingChange}>
            <Modal destroyOnClose title={'Редагування сессії'} open={!!state} onCancel={closeModal}>
                <Editor
                    schedule={schedule as SessionCalendarIntervals}
                    intervalId={state?.intervalId as number}
                    onClose={closeModal}
                />
            </Modal>
            <Modal
                width={340}
                destroyOnClose
                title="Створення сесії"
                open={isCreateSessionModalOpen}
                onCancel={closeCreateSessionModal}>
                <CreateSession onSuccess={closeCreateSessionModal} />
            </Modal>
            <Modal title={`Історія`} open={isHistoryModalOpen} onCancel={closeHistoryModal} destroyOnClose width={1200}>
                <GlobalHistory intervalCode={'one_time_a_week'} />
            </Modal>
            <Modal
                onCancel={closeReportModal}
                destroyOnClose
                title={`Друк історії змін календаря сесій`}
                open={isReportModalOpen}>
                <ReportModal onSuccess={closeReportModal} reportType={ReportTypes.sessionCalendarHistory} />
            </Modal>
            <div className="session-adm">
                <Calendar
                    dateFullCellRender={dateCellRender}
                    onPanelChange={onPanelChange}
                    headerRender={({ onChange }) => (
                        <Row className="marginBottom4">
                            <Col span={12}>
                                <Row justify="start" gutter={16}>
                                    <Col>
                                        <DatePicker
                                            open={pickerVisible}
                                            value={selectedMonth}
                                            className="calendar__controls"
                                            onChange={(value) => {
                                                if (value) {
                                                    setSelectedMonth(value);
                                                    onChange(value);
                                                }
                                            }}
                                            onOpenChange={(status) => setPickerVisible(status)}
                                            picker="month"
                                            format="MMMM YYYY"
                                            renderExtraFooter={() => (
                                                <Row justify="center">
                                                    <Col>
                                                        <Button
                                                            onClick={() => {
                                                                setSelectedMonth(today);
                                                                onChange(today);
                                                                setPickerVisible(false);
                                                            }}>
                                                            Поточний місяць
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )}
                                        />
                                    </Col>
                                    <Col>
                                        <Button onClick={openCreateSessionModal} type="primary">
                                            Створити сесію
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row justify="end" gutter={16}>
                                    <Space>
                                        <Button icon={<HistoryOutlined />} onClick={openHistoryModal}>
                                            Історія
                                        </Button>
                                        <Button icon={<PrinterOutlined />} onClick={openReportModal}>
                                            Звіт
                                        </Button>
                                    </Space>
                                </Row>
                            </Col>
                        </Row>
                    )}
                />
            </div>
        </Spin>
    );
};

export default ScheduleDesk;
