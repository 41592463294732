import { useState } from 'react';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { TablePaginationConfig } from 'antd/lib/table';

export const useControlledFiltersAndSorter = <T extends Record<string, any>>(
    defaultFilter: Record<string, FilterValue | null> = {},
    defaultSorter: SorterResult<T> = {},
    defaultPagination: TablePaginationConfig = { current: 1, pageSize: 10 },
) => {
    const [filters, setFilters] = useState<Record<string, FilterValue | null>>(defaultFilter);
    const [sorters, setSorters] = useState<SorterResult<T>>(defaultSorter);
    const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);

    const handleTableChange = (newPagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPagination(newPagination);
        setFilters(filters);
        setSorters(sorter);
    };

    const clearFilters = () => setFilters(defaultFilter);
    const clearSorters = () => setSorters(defaultSorter);
    const clearPagination = () => setPagination(defaultPagination);

    const clearAll = () => {
        setFilters(defaultFilter);
        setSorters(defaultSorter);
        setPagination(defaultPagination);
    };

    return {
        filters,
        sorters,
        pagination,
        handleTableChange,
        clearAll,
        setFilters,
        setSorters,
        setPagination,
        clearFilters,
        clearSorters,
        clearPagination,
    } as const;
};
