import { Account } from '../../models/Account';

export const accountsTurnoverDto = (accounts: Account[]): Record<string, any> => {
    return accounts.map((account) => ({
        branch: account.branch,
        bankCode: account.bankCode,
        bankName: account.bankName,
        accountIban: account.accountIban,
        turnDebit: account.turnDebit,
        turnCredit: account.turnCredit,
    }));
};
