// TODO: check runtime error while using import from index file
import { Roles, RolesUa } from './enums/Role';
import { activeStatusUa } from './enums/User';
import { GetUsersResponse } from '../models/UserModel';

export const userHistoryKeyDescription: { [key: string]: string } = {
    email: 'Пошта',
    firstName: 'Ім`я',
    lastName: 'Прізвище',
    middleName: 'По-батькові',
    lockedDate: 'Статус',
    roles: 'Ролі',
    phone: 'Телефон',
    first_name: 'Ім`я',
    last_name: 'Прізвище',
    middle_name: 'По-батькові',
    locked_date: 'Статус',
    role: 'Роль',
};

export const activeStatusSelectOptions = [
    {
        label: activeStatusUa.active,
        value: true,
    },
    {
        label: activeStatusUa.inactive,
        value: false,
    },
];

export const activeStatusFilter = [
    { text: activeStatusUa.active, value: false },
    { text: activeStatusUa.inactive, value: true },
];

export const rolesFilter = [
    { text: RolesUa.admin, value: Roles.admin },
    { text: RolesUa.controller_1st, value: Roles.controller_1st },
    { text: RolesUa.controller_2nd, value: Roles.controller_2nd },
    { text: RolesUa.analyst, value: Roles.analyst },
];

export const emptyData: GetUsersResponse = { count: 0, items: [] };

export const defaultSorter = 'lastName asc';

export const defaultUserQuery = {
    count: true,
};
