import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from '@reduxjs/toolkit';
import { alertsApi } from './api/alertsApi';
import { authApi } from './api/authApi';
import { calendarApi } from './api/calendarApi';
import { customerApi } from './api/customerApi';
import { dictionaryApi } from './api/dictionaryApi';
import { rolesApi } from './api/rolesApi';
import { rulesApi } from './api/rulesApi';
import { schedulesApi } from './api/schedulesApi';
import { systemApi } from './api/systemApi';
import { userApi } from './api/userApi';
import authReducer from './features/authSlice';

const authPersistConfig = {
    key: 'root_aml',
    storage,
};

const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authReducer),
    [authApi.reducerPath]: authApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [alertsApi.reducerPath]: alertsApi.reducer,
    [dictionaryApi.reducerPath]: dictionaryApi.reducer,
    [rulesApi.reducerPath]: rulesApi.reducer,
    [calendarApi.reducerPath]: calendarApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [systemApi.reducerPath]: systemApi.reducer,
    [schedulesApi.reducerPath]: schedulesApi.reducer,
});
export default rootReducer;
