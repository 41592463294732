import { Skeleton as AntdSkeleton, SkeletonProps } from 'antd';

const Skeleton = (props: SkeletonProps) => {
    return (
        <AntdSkeleton
            active={props.active || true}
            loading={props.loading}
            title={props.title || false}
            paragraph={props.paragraph || { rows: 1, style: { height: '100%' }, width: 200 }}
            style={props.style || { height: '100%' }}
        />
    );
};

export default Skeleton;
