export const replaceQuotesWithDoubleQuotesInObj = (obj: any) => {
    const newObj: any = {};
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'string') {
            newObj[key] = obj[key].replace(/'/g, `''`);
        } else {
            newObj[key] = obj[key];
        }
    });
    return newObj;
};
