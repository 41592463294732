import React, { FC } from 'react';
import { Col, Row, Spin } from 'antd';
import Modal from '../../../../../components/common/Modal';
import { Transaction } from '../../../../../models/Transaction';
import { useGetAdditionalRequisitesQuery } from '../../../../../redux/api/alertsApi';

interface AdditionalRequisitesProps {
    closeModal: () => void;
    isModalOpen: boolean;
    selectedRow: Transaction | null;
}

const AdditionalRequisites: FC<AdditionalRequisitesProps> = ({ closeModal, isModalOpen, selectedRow }) => {
    const { data: additionalRequisites, isLoading } = useGetAdditionalRequisitesQuery(
        { reference: selectedRow?.documentId, kf: selectedRow?.branch?.split('/')[1] },
        { skip: !selectedRow },
    );

    return (
        <Modal onCancel={closeModal} title={'Додаткові реквізити'} open={isModalOpen} footer={null}>
            <Spin spinning={isLoading}>
                {additionalRequisites?.map((additionalRequisit) => (
                    <Row>
                        <Col span={12}>{additionalRequisit.TAG_NAME}</Col>
                        <Col span={12}>{additionalRequisit.TAG_VALUE}</Col>
                    </Row>
                ))}
            </Spin>
        </Modal>
    );
};

export default AdditionalRequisites;
