import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { getDateView } from './getDateView';
import getTableSizeFromStore from './getTableSizeFromStore';
import { getUserName } from './getUserName';
import ResizableColumn from '../components/common/ResizebleColumn';
import {
    ALERT_PARAMETER,
    CLIENT_ALERTS_TABLE,
    CONTRACTORS_TABLE,
    DATE_FORMAT,
    OTHER_RNK_TABLE,
} from '../constants/Alert';
import { AlertConclusion, BaseAlert, ExtendedCustomer } from '../models/Alert';
import { BlackList } from '../models/BlackList';
import { StatusCatalog } from '../models/Catalog';
import { EmployeeModel } from '../models/UserModel';

type GetColumn<T> = () => ColumnsType<T>;
// todo в константах ниже подобавлять dataIndex и значение в поле key заменить на значение из dataIndex
export const contractors: GetColumn<any> = () => {
    const size = getTableSizeFromStore(CONTRACTORS_TABLE);

    return [
        {
            key: 'name',
            dataIndex: 'name',
            title: (
                <ResizableColumn colIndex={0} storageKey={CONTRACTORS_TABLE}>
                    Найменування/ПІБ
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[0] || 150,
        },
        {
            key: 'taxCode',
            dataIndex: 'taxCode',
            title: (
                <ResizableColumn colIndex={1} storageKey={CONTRACTORS_TABLE}>
                    ЄДРПОУ/РНОКПП
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[1] || 150,
        },
        {
            key: 'isBankClient',
            dataIndex: 'isBankClient',
            title: (
                <ResizableColumn colIndex={2} storageKey={CONTRACTORS_TABLE}>
                    Клієнт банку
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[2] || 150,
        },
        {
            //todo замапить значения
            render: () => '-',
            key: 'Додаткова інформація',
            dataIndex: 'Додаткова інформація',
            title: (
                <ResizableColumn colIndex={3} storageKey={CONTRACTORS_TABLE}>
                    Додаткова інформація
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[3] || 150,
        },
        {
            //todo замапить значения
            render: () => '-',
            key: 'Вжити заходи щодо контрагентів',
            dataIndex: 'Вжити заходи щодо контрагентів',
            title: (
                <ResizableColumn colIndex={4} storageKey={CONTRACTORS_TABLE}>
                    Вжити заходи щодо контрагентів
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[4] || 150,
        },
    ];
};

export const clientAlerts: GetColumn<BaseAlert> = () => {
    const size = getTableSizeFromStore(CLIENT_ALERTS_TABLE);

    return [
        {
            key: 'id',
            dataIndex: 'id',
            title: (
                <ResizableColumn colIndex={0} storageKey={CLIENT_ALERTS_TABLE}>
                    Номер alert
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[0] || 80,
        },
        {
            key: ALERT_PARAMETER.createdDate,
            dataIndex: ALERT_PARAMETER.createdDate,
            render: (value: string) => value && moment(value).format(DATE_FORMAT),
            title: (
                <ResizableColumn colIndex={1} storageKey={CLIENT_ALERTS_TABLE}>
                    Дата початку
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[1] || 82,
        },
        {
            key: ALERT_PARAMETER.sessionCode.join('/'),
            dataIndex: ALERT_PARAMETER.sessionCode,
            title: (
                <ResizableColumn colIndex={2} storageKey={CLIENT_ALERTS_TABLE}>
                    Номер сессії
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[2] || 150,
        },
        {
            key: ALERT_PARAMETER.executionDate,
            dataIndex: ALERT_PARAMETER.executionDate,
            render: (value: string) => value && moment(value).format(DATE_FORMAT),
            title: (
                <ResizableColumn colIndex={3} storageKey={CLIENT_ALERTS_TABLE}>
                    Дата виконання
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[3] || 150,
        },
        {
            key: ALERT_PARAMETER.analystId.join('/'),
            dataIndex: ALERT_PARAMETER.analyst,
            render: (value: EmployeeModel) => value && `${value.firstName} ${value.lastName}`,
            title: (
                <ResizableColumn colIndex={4} storageKey={CLIENT_ALERTS_TABLE}>
                    ПІБ Аналітика
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[4] || 120,
        },
        {
            key: ALERT_PARAMETER.status,
            dataIndex: ALERT_PARAMETER.status,
            render: (value: StatusCatalog) => value?.description,
            title: (
                <ResizableColumn colIndex={5} storageKey={CLIENT_ALERTS_TABLE}>
                    Статус
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[5] || 150,
        },
        {
            key: ALERT_PARAMETER.conclusionDescription.join('/'),
            dataIndex: ALERT_PARAMETER.conclusion,
            render: (value: AlertConclusion[]) => value?.map((item) => item.description).join(', '),
            title: (
                <ResizableColumn colIndex={6} storageKey={CLIENT_ALERTS_TABLE}>
                    Рішення
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[6] || 150,
        },
        {
            key: ALERT_PARAMETER.conclusionDate,
            dataIndex: ALERT_PARAMETER.conclusionDate,
            render: (value: string) => value && moment(value).format(DATE_FORMAT),
            title: (
                <ResizableColumn colIndex={7} storageKey={CLIENT_ALERTS_TABLE}>
                    Дата рішення
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[7] || 82,
        },
        {
            key: ALERT_PARAMETER.closeDate,
            dataIndex: ALERT_PARAMETER.closeDate,
            render: (value: string) => (value ? 'Так' : ' Ні'),
            title: (
                <ResizableColumn colIndex={8} storageKey={CLIENT_ALERTS_TABLE}>
                    Закрито (Так/Ні)
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[8] || 82,
        },
        {
            key: ALERT_PARAMETER.closeDate,
            dataIndex: ALERT_PARAMETER.closeDate,
            render: (value: string) => value && moment(value).format(DATE_FORMAT),
            title: (
                <ResizableColumn colIndex={9} storageKey={CLIENT_ALERTS_TABLE}>
                    Дата закриття
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[9] || 150,
        },
        {
            key: ALERT_PARAMETER.controllerId.join('/'),
            dataIndex: ALERT_PARAMETER.controller,
            render: (value: EmployeeModel) => value && `${value.firstName} ${value.lastName}`,
            title: (
                <ResizableColumn colIndex={10} storageKey={CLIENT_ALERTS_TABLE}>
                    ПІБ Контролера
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[10] || 120,
        },
    ];
};

export const otherRnkColumns: GetColumn<ExtendedCustomer> = () => {
    const size = getTableSizeFromStore(OTHER_RNK_TABLE);

    return [
        {
            key: 'customerId',
            dataIndex: 'customerId',
            title: (
                <ResizableColumn colIndex={0} storageKey={OTHER_RNK_TABLE}>
                    РНК
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[0] || 94,
        },
        {
            key: 'openDate',
            dataIndex: 'openDate',
            render: (value: string) => value && moment(value).format(DATE_FORMAT),
            title: (
                <ResizableColumn colIndex={1} storageKey={OTHER_RNK_TABLE}>
                    Дата реєстрації
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[1] || 82,
        },
        {
            key: 'closeDate',
            dataIndex: 'closeDate',
            render: (value: string) => value && moment(value).format(DATE_FORMAT),
            title: (
                <ResizableColumn colIndex={2} storageKey={OTHER_RNK_TABLE}>
                    Дата закриття
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[2] || 150,
        },
        {
            key: 'branch',
            dataIndex: 'branch',
            title: (
                <ResizableColumn colIndex={3} storageKey={OTHER_RNK_TABLE}>
                    ТВБВ
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[3] || 82,
        },
        {
            key: 'bankCode',
            dataIndex: 'bankCode',
            title: (
                <ResizableColumn colIndex={4} storageKey={OTHER_RNK_TABLE}>
                    МФО РУ
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[4] || 60,
        },
        {
            key: 'Назва РУ',
            dataIndex: ['branchName', 'description'],
            title: (
                <ResizableColumn colIndex={5} storageKey={OTHER_RNK_TABLE}>
                    Назва РУ
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[5] || 82,
        },
        {
            key: 'shortName',
            dataIndex: 'shortName',
            title: (
                <ResizableColumn colIndex={6} storageKey={OTHER_RNK_TABLE}>
                    Найменування / ПІБ клієнта
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[6] || 150,
        },
        {
            key: 'birthday',
            dataIndex: 'birthday',
            render: (value: string) => value && moment(value).format(DATE_FORMAT),
            title: (
                <ResizableColumn colIndex={7} storageKey={OTHER_RNK_TABLE}>
                    Дата народження/Дата реєстрації
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[7] || 150,
        },
        {
            key: 'customerCode',
            dataIndex: 'customerCode',
            title: (
                <ResizableColumn colIndex={8} storageKey={OTHER_RNK_TABLE}>
                    ЄДРПОУ/РНОКПП
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[8] || 92,
        },
        {
            key: 'Наявність клієнтських рахунків',
            dataIndex: 'hasAccounts',
            render: (value: boolean) => (value ? 'Так' : 'Ні'),
            title: (
                <ResizableColumn colIndex={9} storageKey={OTHER_RNK_TABLE}>
                    Наявність клієнтських рахунків
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[9] || 150,
        },
        {
            //todo замапить значения
            render: () => '-',
            key: 'Дата останнього руху коштів по рахунку клієнта',
            dataIndex: 'Дата останнього руху коштів по рахунку клієнта',
            title: (
                <ResizableColumn colIndex={10} storageKey={OTHER_RNK_TABLE}>
                    Дата останнього руху коштів по рахунку клієнта
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[10] || 150,
        },
        {
            key: 'Оцінка репутації клієнта',
            dataIndex: 'reputationLevel',
            title: (
                <ResizableColumn colIndex={11} storageKey={OTHER_RNK_TABLE}>
                    Оцінка репутації клієнта
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[11] || 150,
        },
        {
            //todo замапить значения
            render: () => '-',
            key: 'Дата встановлення рівня репутації',
            dataIndex: 'Дата встановлення рівня репутації',
            title: (
                <ResizableColumn colIndex={12} storageKey={OTHER_RNK_TABLE}>
                    Дата встановлення рівня репутації
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[12] || 150,
        },
        {
            key: 'Рівень ризику',
            dataIndex: 'riskLevel',
            title: (
                <ResizableColumn colIndex={13} storageKey={OTHER_RNK_TABLE}>
                    Рівень ризику
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[13] || 150,
        },
        {
            key: 'riskLevelDate',
            dataIndex: 'riskLevelDate',
            render: (value: string) => value && moment(value).format(DATE_FORMAT),
            title: (
                <ResizableColumn colIndex={14} storageKey={OTHER_RNK_TABLE}>
                    Дата встановлення рівня ризику
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[14] || 150,
        },
        {
            key: 'Критерії ризику',
            dataIndex: 'risks',
            render: (value) => value?.map((item: any) => item.riskId).join(', '),
            title: (
                <ResizableColumn colIndex={15} storageKey={OTHER_RNK_TABLE}>
                    Критерії ризику
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[15] || 150,
        },
        {
            key: ['risks', 'riskDate'].join('/'),
            dataIndex: 'risks',
            render: (value) =>
                value?.map((item: any) => item.riskDate && moment(item.riskDate).format(DATE_FORMAT)).join(', '),
            title: (
                <ResizableColumn colIndex={16} storageKey={OTHER_RNK_TABLE}>
                    Дата встановлення критеріїв ризику
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[16] || 150,
        },
        {
            key: 'Індикатори підозрідості',
            dataIndex: 'suspicionIndicators',
            render: (value) => value?.map((item: any) => item.suspicionIndicatorId).join(', '),
            title: (
                <ResizableColumn colIndex={17} storageKey={OTHER_RNK_TABLE}>
                    Індикатори підозрідості
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[17] || 150,
        },
        {
            key: ['suspicionIndicators', 'suspicionIndicatorsDate'].join('/'),
            dataIndex: 'suspicionIndicators',
            render: (value) =>
                value
                    ?.map(
                        (item: any) =>
                            item.suspicionIndicatorDate && moment(item.suspicionIndicatorDate).format(DATE_FORMAT),
                    )
                    .join(', '),
            title: (
                <ResizableColumn colIndex={18} storageKey={OTHER_RNK_TABLE}>
                    Дата встановлення індикаторів підозрілості
                </ResizableColumn>
            ),
            ellipsis: true,
            width: size?.[18] || 150,
        },
    ];
};

export const blackListColumn = (): ColumnsType<BlackList> => {
    return [
        {
            key: 'reftNumber',
            dataIndex: 'reftNumber',
            title: '№',
            ellipsis: true,
            width: 20,
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Найменування/ПІБ',
            render: (_value, record) => getUserName(record),
            ellipsis: true,
            width: 150,
        },
        {
            key: 'identityCode',
            dataIndex: 'identityCode',
            title: 'ЄДРПОУ/РНОКПП',
            ellipsis: true,
            width: 50,
        },
        {
            key: 'dateIn',
            dataIndex: 'dateIn',
            title: 'Дата внесення',
            render: (value) => `${getDateView(value, true)}`,
            ellipsis: true,
            width: 50,
        },
        {
            key: 'reftNumber',
            dataIndex: 'reftNumber',
            title: 'Назва списку',
            render: (value) => {
                if (value > 0 && value <= 10000) return 'Чорний список';
                if (value > 600000) return 'Сірий список';
            },
            ellipsis: true,
            width: 75,
        },
        {
            key: 'additional',
            dataIndex: 'additional',
            title: 'Додаткова інформація',
            ellipsis: true,
            width: 300,
        },
    ];
};
