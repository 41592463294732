import React, { FC, useRef } from 'react';
import { Form, Input, DatePicker, Row, Col } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import CustomSelect from './CustomSelect';
import { DATE_FORMAT } from '../../../../constants/Alert';
import { DISABLED_WHITESPACE } from '../../../../constants/common';
import { AlertTabRowData } from '../../../../helpers/getAlertData';

const { TextArea } = Input;
interface IProps {
    items: AlertTabRowData[];
    disabled: boolean;
    onBlur: () => void;
}

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const today = dayjs().startOf('day');
    return !(
        current &&
        current.isSame(today, 'day') &&
        current.isSame(today, 'month') &&
        current.isSame(today, 'year')
    );
};

const EditableTab: FC<IProps> = ({ items, disabled, onBlur }) => {
    const root = useRef<HTMLDivElement>(null);

    return (
        // Враппер поверх Row з відʼємним margin щоб не було горизонального скролу
        <div className="row-margin-reset" ref={root}>
            {items?.map((item) =>
                !item.hide ? (
                    <Row gutter={24} className="alert-info" key={item.key}>
                        <Col span={8}>
                            {item.name}
                            {item.required && <span className="alert-info__required">*</span>}
                        </Col>
                        <Col span={16}>
                            {item.editable ? (
                                <Form.Item
                                    className="editable-form-item"
                                    initialValue={item.value}
                                    hasFeedback
                                    name={item.fieldName}
                                    rules={[
                                        { required: item.required },
                                        ...(item.type !== 'date' && item.type !== 'select' && item.required
                                            ? [
                                                  {
                                                      whitespace: true,
                                                      message: DISABLED_WHITESPACE,
                                                  },
                                              ]
                                            : []),
                                    ]}>
                                    {item.type === 'date' ? (
                                        <DatePicker
                                            className="shortInput"
                                            disabled={disabled}
                                            format={DATE_FORMAT}
                                            onBlur={(e) =>
                                                e.target.value !== dayjs(item.value as Dayjs).format(DATE_FORMAT) &&
                                                onBlur()
                                            }
                                            disabledDate={item.disabledPastPeriod ? disabledDate : undefined}
                                            placeholder={item.placeholder || 'Оберіть дату'}
                                        />
                                    ) : item.type === 'select' ? (
                                        <CustomSelect
                                            options={item.options}
                                            mode={item.multiply}
                                            disabled={disabled || item.disabled || false}
                                            onBlur={onBlur}
                                            selectMenuContainer={root.current as HTMLDivElement}
                                            placeholder={item.placeholder}
                                        />
                                    ) : item.type === 'textArea' ? (
                                        <TextArea
                                            placeholder={item.placeholder || 'Введіть значення'}
                                            className="wWide"
                                            disabled={disabled}
                                            rows={item.rows}
                                            onBlur={(e) => e.target.value !== item.value && onBlur()}
                                        />
                                    ) : (
                                        <Input
                                            placeholder={item.placeholder || 'Введіть значення'}
                                            type={item.type === 'numberInput' ? 'number' : undefined}
                                            className="wWide"
                                            disabled={disabled}
                                            style={item.style}
                                            onBlur={(e) => e.target.value !== item.value && onBlur()}
                                        />
                                    )}
                                </Form.Item>
                            ) : (
                                (item.value as string | number | null)
                            )}
                        </Col>
                    </Row>
                ) : null,
            )}
        </div>
    );
};

export default EditableTab;
