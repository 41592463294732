import React, { FC } from 'react';

import { Button, DatePicker } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';

import { DATE_FORMAT, DATE_FORMAT_FOR_QUERY } from '../../../constants/Alert';

const CustomFilter: FC<FilterDropdownProps> = ({ setSelectedKeys, clearFilters, confirm }) => (
    <div className="filter" onClick={(e) => e.stopPropagation()}>
        <div className="filter__wrapper">
            <DatePicker
                className="filter__field"
                placeholder="Оберіть дату"
                format={DATE_FORMAT}
                onChange={(e) => setSelectedKeys(e ? [e?.format(DATE_FORMAT_FOR_QUERY)] : [])}
            />
        </div>
        <div className="filter__buttons">
            <Button
                onClick={() => {
                    clearFilters && clearFilters();
                    confirm();
                }}
                type="link"
                size="small">
                Скинути
            </Button>
            <Button type="primary" onClick={() => confirm()} size="small">
                Ок
            </Button>
        </div>
    </div>
);

export default CustomFilter;
