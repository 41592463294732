import { useState } from 'react';
import dayjs from 'dayjs';
import { DATE_FORMAT_FOR_QUERY } from '../constants';

export const useHandleDatePickerWithArrows = () => {
    const [date, setDate] = useState<string>(dayjs(new Date()).format(DATE_FORMAT_FOR_QUERY));

    const handlePrevDay = () => {
        const prevDay = dayjs(date).subtract(1, 'day').format(DATE_FORMAT_FOR_QUERY);
        setDate(prevDay);
    };

    const handleNextDay = () => {
        const nextDay = dayjs(date).add(1, 'day').format(DATE_FORMAT_FOR_QUERY);
        setDate(nextDay);
    };

    return { date, setDate, handlePrevDay, handleNextDay };
};
