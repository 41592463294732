import { FormInstance } from 'antd';
import { FormValuesType } from '../../../../models/DictionaryModel';

const getParam18 = (values: FormValuesType) => {
    const keyForParam18 = [
        'param6',
        'param7',
        'param8',
        'param9',
        'param10',
        'param11',
        'param12',
        'param13',
        'param14',
        'param15',
        'param16',
        'param17',
        'param20',
        'param21',
    ];
    return Object.keys(values).reduce((acc, key) => {
        if (keyForParam18.includes(key)) {
            acc += +(values[key] ?? 0);
        }
        return acc;
    }, 0);
};

export const checkParam18 = (form: FormInstance<FormValuesType>) => {
    form.setFieldsValue({
        param18: getParam18(form.getFieldsValue()),
    });
};
