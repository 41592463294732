import { InternalNamePath } from 'rc-field-form/lib/interface';

interface ErrorField {
    errors: string[];
    name: InternalNamePath;
}
export const getErrorFields = (errorFields: ErrorField[]) => {
    let result: (string | number)[] = [];
    errorFields.forEach((field) => {
        result.push(...field.name);
    });
    return result;
};
