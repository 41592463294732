import { useBoolean } from './useBoolean';

type UseModal = (
    initialMode?: boolean,
) => [
    isModalOpen: boolean,
    openModal: () => void,
    closeModal: () => void,
    toggleModal: () => void,
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
];

export const useModal: UseModal = useBoolean;
