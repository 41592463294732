import { RefObject } from 'react';
import { Col, InputRef, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import moment from 'moment/moment';
import CustomFilter from './CustomFilter';
import StatusRender from './StatusRender';
import FilterDateRange from '../../../components/common/FilterDateRange';
import ResizableColumn from '../../../components/common/ResizebleColumn';
import { TECH_USER_NAME } from '../../../constants';
import { DATE_FORMAT, TIME_WITH_SECONDS_FORMAT } from '../../../constants/Alert';
import { intervalFilter, SESSION_TABLE_SIZE, sessionStatusFilter } from '../../../constants/Session';
import { getUniqueUserByRole } from '../../../helpers';
import { getSessionColor } from '../../../helpers/calendar';
import { getColumnSearchProps } from '../../../helpers/getColumnSearchProps';
import { RoleModel } from '../../../models/RoleModel';
import { Session, SessionIntervals } from '../../../models/Session';

export const getColumns = (
    rules: ColumnFilterItem[],
    indicators: ColumnFilterItem[],
    roles: RoleModel[] | undefined,
    sizes: { [key: string]: number },
    getRoles: () => void,
    ref: RefObject<InputRef>,
): ColumnsType<Session> => {
    return [
        {
            title: (
                <ResizableColumn colIndex={1} storageKey={SESSION_TABLE_SIZE}>
                    ID сесії
                </ResizableColumn>
            ),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: sizes?.[1] || 25,
        },
        {
            title: (
                <ResizableColumn colIndex={2} storageKey={SESSION_TABLE_SIZE}>
                    Номер сесії
                </ResizableColumn>
            ),
            dataIndex: 'number',
            key: 'number',
            sorter: true,
            ...getColumnSearchProps<Session>(ref, 'string'),
            ellipsis: true,
            width: sizes?.[2] || 110,
        },
        {
            title: (
                <ResizableColumn colIndex={3} storageKey={SESSION_TABLE_SIZE}>
                    Періодичність
                </ResizableColumn>
            ),
            dataIndex: 'intervals',
            key: 'intervals',
            render: (values: SessionIntervals[]) => (
                <>
                    {[...values]
                        .sort((a, b) =>
                            !a?.interval?.ord ? 1 : !b?.interval?.ord ? -1 : b.interval.ord - a.interval.ord,
                        )
                        .map((item) => (
                            <Col key={item.intervalCode}>
                                <Tooltip
                                    color="grey"
                                    title={item?.interval?.name}
                                    arrow={false}
                                    placement={'bottomLeft'}>
                                    <Tag color={getSessionColor(item.intervalCode as string)}>
                                        {item.interval?.name}
                                    </Tag>
                                </Tooltip>
                            </Col>
                        ))}
                </>
            ),
            filters: intervalFilter,
            sorter: true,
            ellipsis: true,
            width: sizes?.[3] || 110,
        },
        {
            title: (
                <ResizableColumn colIndex={4} storageKey={SESSION_TABLE_SIZE}>
                    Дата запуску
                </ResizableColumn>
            ),
            dataIndex: 'startDate',
            key: 'startDate',
            render: (value) => (value ? moment(value).format(DATE_FORMAT) : ''),
            filterDropdown: (props) => <CustomFilter {...props} />,
            sorter: true,
            ellipsis: true,
            width: sizes?.[4] || 70,
        },
        {
            title: (
                <ResizableColumn colIndex={5} storageKey={SESSION_TABLE_SIZE}>
                    Час запуску
                </ResizableColumn>
            ),
            dataIndex: 'startDate',
            key: 'startDateHour',
            render: (value) => (value ? moment(value).format(TIME_WITH_SECONDS_FORMAT) : ''),
            ellipsis: true,
            width: sizes?.[5] || 60,
        },
        {
            title: (
                <ResizableColumn colIndex={6} storageKey={SESSION_TABLE_SIZE}>
                    Дата закінчення
                </ResizableColumn>
            ),
            dataIndex: 'endDate',
            key: 'endDate',
            render: (value) => (value ? moment(value).format(DATE_FORMAT) : ''),
            filterDropdown: (props) => <CustomFilter {...props} />,
            sorter: true,
            ellipsis: true,
            width: sizes?.[6] || 70,
        },
        {
            title: (
                <ResizableColumn colIndex={7} storageKey={SESSION_TABLE_SIZE}>
                    Час закінчення
                </ResizableColumn>
            ),
            dataIndex: 'endDate',
            key: 'endDateHour',
            render: (value) => (value ? moment(value).format(TIME_WITH_SECONDS_FORMAT) : ''),
            ellipsis: true,
            width: sizes?.[7] || 60,
        },
        {
            title: (
                <ResizableColumn colIndex={8} storageKey={SESSION_TABLE_SIZE}>
                    Критерії ризику: план
                </ResizableColumn>
            ),
            dataIndex: 'criterionPlan',
            key: 'criterionPlan',
            filters: rules,
            sorter: true,
            ellipsis: true,
            width: sizes?.[8] || 110,
        },
        {
            title: (
                <ResizableColumn colIndex={9} storageKey={SESSION_TABLE_SIZE}>
                    Критерії ризику: факт
                </ResizableColumn>
            ),
            dataIndex: 'criterionFact',
            key: 'criterionFact',
            filters: rules,
            sorter: true,
            ellipsis: true,
            width: sizes?.[9] || 110,
        },
        {
            title: (
                <ResizableColumn colIndex={10} storageKey={SESSION_TABLE_SIZE}>
                    Індикатори підозрілості: план
                </ResizableColumn>
            ),
            dataIndex: 'indicatorPlan',
            key: 'indicatorPlan',
            filters: indicators,
            sorter: true,
            ellipsis: true,
            width: sizes?.[10] || 110,
        },
        {
            title: (
                <ResizableColumn colIndex={11} storageKey={SESSION_TABLE_SIZE}>
                    Індикатори підозрілості: факт
                </ResizableColumn>
            ),
            dataIndex: 'indicatorFact',
            key: 'indicatorFact',
            filters: indicators,
            sorter: true,
            ellipsis: true,
            width: sizes?.[11] || 110,
        },
        {
            title: (
                <ResizableColumn colIndex={12} storageKey={SESSION_TABLE_SIZE}>
                    Статус
                </ResizableColumn>
            ),
            dataIndex: 'status',
            key: 'status',
            filters: sessionStatusFilter,
            render: (value: Session['status'], record) =>
                value?.description ? <StatusRender value={value} record={record} /> : null,
            sorter: true,
            ellipsis: true,
            width: sizes?.[12] || 110,
        },
        {
            title: (
                <ResizableColumn colIndex={13} storageKey={SESSION_TABLE_SIZE}>
                    Користувач
                </ResizableColumn>
            ),
            dataIndex: 'createdUser',
            key: 'createdUser',
            onFilterDropdownOpenChange: (visible) => visible && !roles && getRoles(),
            filters: [{ text: TECH_USER_NAME, value: '' }, ...getUniqueUserByRole(roles, 'admin')],
            render: (value: Session['createdUser']) =>
                !!value ? `${value.lastName || ''} ${value.firstName} ${value.middleName || ''}` : TECH_USER_NAME,
            sorter: true,
            ellipsis: true,
            width: sizes?.[13] || 110,
        },
        {
            title: (
                <ResizableColumn colIndex={14} storageKey={SESSION_TABLE_SIZE}>
                    Період сесії
                </ResizableColumn>
            ),
            key: 'periodToDate',
            render: ({ periodFromDate, periodToDate }: Session) =>
                `${!!periodFromDate ? moment(periodFromDate).format(DATE_FORMAT) : 'ЩНВ'}-${
                    !!periodToDate ? moment(periodToDate).format(DATE_FORMAT) : 'ЩНВ'
                }`,
            filterDropdown: (props) => <FilterDateRange {...props} />,
            sorter: true,
            ellipsis: true,
            width: sizes?.[14] || 160,
        },
    ];
};
