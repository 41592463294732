import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Dropdown, notification, Row, Space, Spin, Table, TablePaginationConfig, TableProps } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { QueryOptions } from 'odata-query';
import { CaretDownOutlined, PrinterOutlined, ReloadOutlined } from '@ant-design/icons';
import {
    dateColumns,
    getSystemLogColumns,
    selectColumns,
    stringContainsColumns,
    stringExistColumns,
    nameColumns,
} from './components/getSystemLogColumns';
import Modal from '../../components/common/Modal';
import ReportModal from '../../components/modal/ReportModal';
import { SYSTEM_LOG_TABLE } from '../../constants';
import { ReportTypes } from '../../constants/enums';
import { getFilters } from '../../helpers/getFilters';
import getTopSkip from '../../helpers/getTopSkip';
import { useModal } from '../../hooks';
import { SystemLog as SystemLogModel } from '../../models/SystemLog';
import { useLazyGetRolesQuery } from '../../redux/api/rolesApi';
import { useGetSystemLogQuery } from '../../redux/api/systemApi';

const defaultOrder = 'ID desc';

const SystemLog = () => {
    const inputRef = useRef(null);
    const [reportModal, openReportModal, closeReportModal] = useModal();
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
    const [filter, setFilter] = useState<Record<string, FilterValue | null>>({});
    const [order, setOrder] = useState<QueryOptions<SystemLogModel>['orderBy']>(defaultOrder);
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 20,
        showSizeChanger: true,
    });
    const sizes = useMemo(() => {
        const tableSizeJson = window.localStorage.getItem(SYSTEM_LOG_TABLE);
        return tableSizeJson ? JSON.parse(tableSizeJson) : null;
    }, []);
    const queryData = useMemo<Partial<QueryOptions<SystemLogModel>>>(() => {
        const allFilter = getFilters({
            filter,
            stringContainsColumns,
            stringExistColumns,
            dateColumns,
            selectColumns,
            nameColumns,
        });

        return {
            count: true,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: allFilter,
            orderBy: order,
        };
    }, [filter, pagination, order]);

    const { data, error, isLoading, isFetching, refetch } = useGetSystemLogQuery(queryData);
    const [getRoles, { data: roles }] = useLazyGetRolesQuery();

    const handleTableChange: TableProps<SystemLogModel>['onChange'] = (newPagination, filters, sorter: any) => {
        setPagination(newPagination);
        if (sorter.order) setOrder(`${sorter?.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`);
        setFilter(filters);
    };

    const items = data?.items;
    const count = data?.count;

    useEffect(() => setPagination((currentPagination) => ({ ...currentPagination, total: count })), [count]);

    useEffect(() => {
        if (error) notification.error((error as any)?.data?.message);
    }, [error]);

    const handleExpandErrorStack = (recordId: number) => {
        setExpandedRowKeys((prevKeys) =>
            prevKeys.includes(recordId) ? prevKeys.filter((key) => key !== recordId) : [...prevKeys, recordId],
        );
    };

    return (
        <Spin spinning={isLoading || isFetching}>
            <Modal
                onCancel={closeReportModal}
                destroyOnClose
                title={`Друк звіту "Головний системний лог"`}
                open={reportModal}>
                <ReportModal
                    onSuccess={closeReportModal}
                    reportType={ReportTypes.systemLog}
                    filters={filter}
                    processedFilters={getFilters({
                        filter,
                        stringContainsColumns,
                        stringExistColumns,
                        dateColumns,
                        selectColumns,
                    })}
                />
            </Modal>
            <Row gutter={8} justify="end" className="marginBottom2 alignItemsCenter">
                <Col>
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: `Друк звіту "Головний системний лог"`,
                                    onClick: openReportModal,
                                },
                            ],
                        }}>
                        <Button>
                            <Space>
                                <PrinterOutlined />
                                <CaretDownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                </Col>
                <Col>
                    <Button icon={<ReloadOutlined />} onClick={refetch} loading={isFetching} />
                </Col>
            </Row>
            <Table<SystemLogModel>
                rowKey="ID"
                size="small"
                scroll={{ x: 600 }}
                columns={getSystemLogColumns(sizes, inputRef, expandedRowKeys, handleExpandErrorStack, getRoles, roles)}
                dataSource={items}
                pagination={pagination}
                onChange={handleTableChange}
                bordered
                expandedRowKeys={expandedRowKeys}
                onExpand={(expanded, record) => handleExpandErrorStack(record.ID)}
            />
        </Spin>
    );
};

export default SystemLog;
