import { getUserName } from './getUserName';
import { RoleModel } from '../models/RoleModel';
import { EmployeeModel } from '../models/UserModel';

interface IRole {
    controller_1st: EmployeeModel;
    controller_2nd: EmployeeModel;
    analyst: EmployeeModel;
    admin: EmployeeModel;
}

export const getUniqueUserByRole = (roles: RoleModel[] | undefined, role?: keyof IRole | (keyof IRole)[]) => {
    const filteredByRoles = roles?.filter((item) => {
        if (!role) {
            return true;
        }
        if (Array.isArray(role)) {
            return role.includes(item.name as keyof IRole);
        }
        return item.name === role;
    });

    const allUsers =
        filteredByRoles?.flatMap(
            (role) =>
                role.users?.map((user) => ({
                    text: getUserName(user),
                    value: user.id,
                })) || [],
        ) || [];

    const uniqueUsers = Array.from(new Map(allUsers.map((user) => [user.value, user])).values());

    return uniqueUsers;
};
