import { ChangeEvent, useEffect, useState } from 'react';
import { Filter } from 'odata-query';
import { filterForSearch } from '../../../helpers/filterDictionary';
import { useDebounce } from '../../../hooks';
import { ColumnModel } from '../../../models/DictionaryModel';

export const useSearch = (columns: ColumnModel[]) => {
    const [searchFilter, setSearchFilter] = useState<Filter[]>([]);
    const [search, setSearch] = useState<string>('');
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const debouncedSearch = useDebounce(search, 500);

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        setIsEdited(true);
    };

    useEffect(() => {
        if (!debouncedSearch) setSearchFilter([]);
        else if (isEdited) setSearchFilter(filterForSearch(columns, debouncedSearch));
    }, [debouncedSearch, columns, isEdited]);

    return { searchFilter, handleSearchChange };
};
