import { FC } from 'react';
import { Button, Row, Col, Divider } from 'antd';
import { getUserName } from '../../helpers/getUserName';
import { useModal } from '../../hooks';
import { EmployeeModel } from '../../models/UserModel';
import Modal from '../common/Modal';

interface UserModalProps {
    user?: EmployeeModel;
}

const UserModal: FC<UserModalProps> = ({ user }) => {
    const [isModalOpen, openModal, closeModal] = useModal();
    return (
        <>
            <Modal destroyOnClose open={isModalOpen} onCancel={closeModal} title={'Користувач'} style={{ padding: 0 }}>
                <Row gutter={24} className="alert-info" key={'123'}>
                    <Col span={12}>ПІБ:</Col>
                    <Col span={12}>{user && getUserName(user)}</Col>

                    <Divider style={{ margin: 0 }} />

                    <Col span={12}>Адреса ел. скриньки:</Col>
                    <Col span={12}>{user?.email}</Col>

                    <Divider style={{ margin: 0 }} />

                    <Col span={12}>Номер телефону:</Col>
                    <Col span={12}>{user?.phone}</Col>

                    <Divider style={{ margin: 0 }} />

                    <Col span={12}>Ролі користувача:</Col>
                    <Col span={12}>
                        {user?.roles?.map((item) => typeof item === 'object' && item.description).join(', ')}
                    </Col>
                </Row>
            </Modal>
            <Button type="link" className="padding_0 height_auto" onClick={openModal}>
                {user && getUserName(user)}
            </Button>
        </>
    );
};

export default UserModal;
