import { FC, useState } from 'react';
import { Button, DatePicker, Form, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT } from '../../../constants/Alert';
import { DATE_FORMAT_FOR_QUERY } from '../../../constants/Alert';
import { reportFetch } from '../../../helpers/reportFetch';
import { getToken, getUser } from '../../../redux/features/authSlice';
import { useAppSelector } from '../../../redux/hooks';

interface SessionReportModalProps {
    onSuccess: () => void;
    reportType: string;
}

interface SessionReportForm {
    date: Dayjs;
}

const SessionReportModal: FC<SessionReportModalProps> = ({ onSuccess, reportType }) => {
    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const user = useAppSelector(getUser);
    const token = useAppSelector(getToken);

    const handleSubmit = async (values: SessionReportForm) => {
        const date = values.date.format(DATE_FORMAT_FOR_QUERY);
        const query = 'date=' + date;
        setIsLoadingReport(true);
        await reportFetch(reportType, null, query, onSuccess, null, token, user?.id);
        setIsLoadingReport(false);
    };

    return (
        <Spin spinning={isLoadingReport}>
            <Form<SessionReportForm>
                onFinish={handleSubmit}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}
                layout="vertical">
                <Form.Item name="date" rules={[{ required: true }]} initialValue={dayjs(new Date())}>
                    <DatePicker className={'wWide'} format={DATE_FORMAT} />
                </Form.Item>
                <Form.Item>
                    <Button className="alerts-register-button" type="primary" htmlType="submit">
                        Завантажити
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default SessionReportModal;
